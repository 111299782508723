import { collection, addDoc, doc, updateDoc, Timestamp } from 'firebase/firestore';
import { db } from '../firebase-config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare, faPlus, faSpinner, faCheck, faToggleOff, faToggleOn, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { useState, useRef } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { useEffect } from 'react';

const AdminNationNextStep = ({ step, selectedNation}) => {
    const { currentUser } = useAuth();

    const [ show, setShow ] = useState(false);
    const [ loading, setLoading ] = useState(false);
    const [ success, setSuccess ] = useState(false);
    const [ visible, setVisible ] = useState(false);
    const [ ukrNameErr, setUkrNameErr ] = useState(false);
    const [ frNameErr, setFrNameErr ] = useState(false);
    const [ enNameErr, setEnNameErr ] = useState(false);
    const [ ukrContentErr, setUkrContentErr ] = useState(false);
    const [ frContentErr, setFrContentErr ] = useState(false);
    const [ enContentErr, setEnContentErr ] = useState(false);

    const ukrNameRef = useRef();
    const frNameRef = useRef();
    const enNameRef = useRef();
    const ukrContentRef = useRef();
    const frContentRef = useRef();
    const enContentRef = useRef();

    const toggleOpenStep = () => {
        if (show) {
            // Reset errors
            setUkrNameErr(false);
            setFrNameErr(false);
            setEnNameErr(false);
            setUkrContentErr(false);
            setFrContentErr(false);
            setEnContentErr(false);
        }
        setShow(!show);
    }

    const handleVisibleToggle = () => {
        setVisible(!visible);
    }

    const handleStepSubmit = async (e) => {
        e.preventDefault();
        // Check if loading already 
        if (loading) {
            return false;
        }
        // Reset errors
        setUkrNameErr(false);
        setFrNameErr(false);
        setEnNameErr(false);
        setUkrContentErr(false);
        setFrContentErr(false);
        setEnContentErr(false);
    let oldData, newData, errors = false;
        // Validate
        if (ukrNameRef.current.value === '') {
            errors = true;
            setUkrNameErr(true);
        }
        if (frNameRef.current.value === '') {
            errors = true;
            setFrNameErr(true);
        }
        if (enNameRef.current.value === '') {
            errors = true;
            setEnNameErr(true);
        }
        if (ukrContentRef.current.value === '') {
            errors = true;
            setUkrContentErr(true);
        }
        if (frContentRef.current.value === '') {
            errors = true;
            setFrContentErr(true);
        }
        if (enContentRef.current.value === '') {
            errors = true;
            setEnContentErr(true);
        }
        if (errors) {
            return false;
        }
        // Set data
        if (!step) {
            oldData = null;
            newData = {
                name: [ukrNameRef.current.value, frNameRef.current.value, enNameRef.current.value],
                content: [ukrContentRef.current.value, frContentRef.current.value, enContentRef.current.value],
                visible: visible,
                timestamp: Timestamp.now()
            }
        } else {
            oldData = step;
            newData = {
                name: [ukrNameRef.current.value, frNameRef.current.value, enNameRef.current.value],
                content: [ukrContentRef.current.value, frContentRef.current.value, enContentRef.current.value],
                visible: visible,
                timestamp: Timestamp.now()
            };
        }
        setLoading(true);
        // Submit to firestore
        if (!step) {
            // Adding doc
            try {
                await addDoc(collection(db, 'countryList', selectedNation.id, 'next-steps'), newData);
                setLoading(false);
                setSuccess(true);
                setTimeout(() => {
                    // Hide and reset new form
                    setSuccess(false);
                    setShow(false);
                }, 1500);
                addDoc(collection(db, 'userInteraction'), {
                    userName: currentUser.displayName ? currentUser.displayName : '',
                    userEmail: currentUser.email,
                    type: 'Adding to next steps',
                    document: 'Next Steps',
                    nation: selectedNation.name[2],
                    nationID: selectedNation.id,
                    timestamp: Timestamp.now(),
                    newData: newData
                });
            } catch (err) {
                setLoading(false);
            }
        } else {
            // Updating doc
            try {
                const docRef = doc(db, 'countryList', selectedNation.id, 'next-steps', step.id);
                await updateDoc(docRef, newData);
                setLoading(false);
                setSuccess(true);
                setTimeout(() => {
                    setSuccess(false);
                }, 1500);
                addDoc(collection(db, 'userInteraction'), {
                    userName: currentUser.displayName ? currentUser.displayName : '',
                    userEmail: currentUser.email,
                    type: 'Updating next steps',
                    document: 'Next Steps',
                    nation: selectedNation.name[2],
                    nationID: selectedNation.id,
                    stepID: step.id,
                    timestamp: Timestamp.now(),
                    oldData: oldData,
                    newData: newData
                });
            } catch (err) {
                setLoading(false);
            }
        }
    }

    useEffect(() => {
        setVisible(current => {
            return (step ? step.visible ?? false : false)
        })
    }, [step])

    return (
        <div className={`cursor-pointer p-3 flex flex-wrap justify-start items-center gap-6 border-b-[1px] border-rgba(0,0,0,0.1) last:border-b-0`} onClick={toggleOpenStep}>
            {!step && (
                <>
                    <FontAwesomeIcon className='' icon={faPlus} />
                    <span className='flex-1'>Add Step</span>
                </>
            )}
            {step && (
                <>
                    <FontAwesomeIcon className='' icon={faPenToSquare} />
                    <span className='flex-1 capitalize'>{step.name[2]}</span>
                    {!step.visible && (
                        <FontAwesomeIcon icon={faEyeSlash} />
                    )}
                </>
            )}
            {show && (
                <form action='' className='admin-form basis-full p-3 rounded-lg g-shadow bg-[rgba(0,0,0,0.05)] cursor-default' autoComplete='off' onSubmit={handleStepSubmit} onClick={(e) => e.stopPropagation()}> 
                    <section>
                        <h3>Title</h3>
                        <div>
                            <div>
                                <label htmlFor='ukrName'>Ukrainian</label>
                                <input className={`${ukrNameErr ? 'errors' : ''}`} name='ukrName' id='ukrName' type='text' ref={ukrNameRef} defaultValue={step ? step.name[0] : ''} />
                            </div>
                            <div>
                                <label htmlFor='frName'>French</label>
                                <input className={`${frNameErr ? 'errors' : ''}`} name='frName' id='frName' type='text' ref={frNameRef} defaultValue={step ? step.name[1] : ''} />
                            </div>
                            <div>
                                <label htmlFor='enName'>English</label>
                                <input className={`${enNameErr ? 'errors' : ''}`} name='enName' id='enName' type='text' ref={enNameRef} defaultValue={step ? step.name[2] : ''} />
                            </div>
                        </div>
                    </section>
                    <section className='embassy-contact'>
                        <h3>Content</h3>
                        <div>
                            <div className='full'>
                                <label htmlFor='ukrContent'>Ukrainian</label>
                                <textarea className={`${ukrContentErr ? 'errors' : ''}`} name='ukrContent' id='ukrContent' ref={ukrContentRef} rows='5' defaultValue={step ? step.content[0] : ''}></textarea>
                            </div>
                            <div className='full'>
                                <label htmlFor='frContent'>French</label>
                                <textarea className={`${frContentErr ? 'errors' : ''}`} name='frContent' id='frContent' ref={frContentRef} rows='5' defaultValue={step ? step.content[1] : ''}></textarea>
                            </div>
                            <div className='full'>
                                <label htmlFor='enContent'>English</label>
                                <textarea className={`${enContentErr ? 'errors' : ''}`} name='enContent' id='enContent' ref={enContentRef} rows='5' defaultValue={step ? step.content[2] : ''}></textarea>
                            </div>
                        </div>
                    </section>
                    <section className='admin-visible'>
                            <div className='justify-end gap-6'>
                                <span className='flex items-center justify-center gap-3 cursor-pointer' onClick={handleVisibleToggle}><span>Visible: </span><FontAwesomeIcon className=' text-[20px]' icon={visible ? faToggleOn : faToggleOff}/></span>
                            </div>
                        </section>
                    <section className='admin-submit'>
                            <div className='h-[48px]'>
                                {!loading && !success && (<button className='g-shadow rounded-lg p-3 bg-blue-400 !text-white flex justify-center items-center'>{step ? 'Update Step' : 'Add Step'}</button>)}
                                {loading && (<span className='basis-[80px] flex items-center justify-center'><FontAwesomeIcon className=' text-[20px] animate-spin' icon={faSpinner}/></span>)}
                                {success && (<span className='basis-[80px] flex items-center justify-center'><FontAwesomeIcon className=' !text-green-600 text-[20px]' icon={faCheck}/></span>)}
                            </div>
                    </section>
                </form>
            )}
        </div>
    );
}
 
export default AdminNationNextStep;
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../contexts/AppContext";
import {
  collection,
  onSnapshot,
  query,
  where,
  orderBy,
} from "firebase/firestore";
import { db } from "../../firebase-config";
import CountryNextStepsCard from "./CountryNextStepsCard";
import Loading from "../Loading";

const NextSteps = ({ nation }) => {
  const { language } = useContext(AppContext);
  const sectionTitle = ["Наступні кроки", "prochaines étapes", "Next Steps"];
  const [stepsDetails, setStepsDetails] = useState([]);
  const [loading, setLoading] = useState(true);

  // Firestore Query
  const colRef = collection(db, "countryList", nation.id, "next-steps");
  const q = query(
    colRef,
    where("visible", "==", true),
    orderBy("timestamp", "desc")
  );
  // Fetch data from firestore
  useEffect(() => {
    const cancelStepsSubscriber = onSnapshot(
      q,
      (snapshot) => {
        setStepsDetails(
          snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
        );
        setLoading(false);
      },
      (error) => {
        console.log(error);
        setLoading(false);
      }
    );
    return cancelStepsSubscriber;
  }, [nation]);

  // Return next steps if next steps found
  if (!loading && stepsDetails.length > 0) {
    return (
      <div className="">
        <h3 className="country-section-title">{sectionTitle[language]}</h3>
        {stepsDetails.length < 1 && <Loading bg="dark" />}
        <div className="country-next-steps-list grid grid-cols-1 break900:grid-cols-2 gap-[15px] items-start">
          {stepsDetails.map((item, index) => (
            <CountryNextStepsCard key={index} step={item} />
          ))}
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default NextSteps;

import { useContext } from 'react';
import { AppContext } from '../../contexts/AppContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhone, faDisplay } from '@fortawesome/free-solid-svg-icons';

const OrganisationContact = ({ organisation }) => {
    const { language } = useContext(AppContext);
    const call = ['виклик', 'téléphoner', 'Call'];
    const email = ['Електронна пошта', 'E-mail', 'Email'];
    const website = ['веб-сайт', 'Site Internet', 'Website'];

    return (
        <div className='organisation-contact-container'>
            {organisation.email && organisation.email !== '' && (
                <a href={'mailto:' + organisation.email} >
                    <FontAwesomeIcon className='organisation-contact-icon' icon={faEnvelope} />
                    <p>{email[language]}</p>
                </a>
            )}
            {organisation.link && organisation.link !== '' && (
                <a href={organisation.link} target="_blank" rel='noreferrer' >
                    <FontAwesomeIcon className='organisation-contact-icon' icon={faDisplay} />
                    <p>{website[language]}</p>
                </a>
            )}
            {organisation.tel && organisation.tel !== '' && (
                <a href={'tel:' + organisation.tel} >
                    <FontAwesomeIcon className='organisation-contact-icon' icon={faPhone} />
                    <p>{call[language]}</p>
                </a>
            )}
        </div>
    );
}

export default OrganisationContact;
import { useContext } from "react";
import { AppContext } from '../../contexts/AppContext';

const CountryTravel = ({ nation }) => {
    const { language } = useContext(AppContext);

    const title = ['Перевезення', 'Transport', 'Transportation'];
    const notes = [
        `Подорожі в ${nation.name[language]}`,
        `Voyage en ${nation.name[language]}`,
        `Travel in ${nation.name[language]}`
    ];

    return (
        <>
            {nation.travel && nation.travel[language] !== '' && (
                <div className="">
                    <h3 className="country-section-title">{title[language]}</h3>
                    <div className="country-travel-list">
                        {nation.travel && nation.travel[language] !== ''  && (
                            <div>
                                <h3 className="country-inner-title">{notes[language]}</h3>
                                <p className="country-text allow_select" dangerouslySetInnerHTML={{ __html: nation.travel[language].replace(/(?:\r\n|\r|\n)/g, '<br>') }}></p>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </>
    );
}
 
export default CountryTravel;
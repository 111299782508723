import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram, faFacebookF } from '@fortawesome/free-brands-svg-icons';
import { Link } from "react-router-dom";

const Footer = () => {

    return (
        <div className="footer-container">
            <div>
                 {/* Support us */}
                 <div className="support-us">
                    <div className="support-us-title">
                        <h1>Support Us</h1>
                        <span></span>
                    </div>
                    <p>Our team is working hard to gather a world of information and make it available to those who need. In an ever evolving situation, keeping this information up-to-date takes a lot of time and resources. If you would like to support our team so that we can continue this work it will be greatly appreciated.</p>
                    <div className="support-us-buttons">
                        <a
                            href="https://www.paypal.me/strongertogetherukr" 
                            target="_blank"
                            rel="noreferrer"    
                        >Donate</a>
                        <Link to="/contact">Join Our Team</Link>
                    </div>
                </div>  
                {/* Social Links */}
                <div className="footer-social-media">
                    <div>
                        <a 
                            href="https://www.facebook.com/strongertogetherworld"
                            rel="noreferrer"
                            target="_blank"
                        >
                            <FontAwesomeIcon icon={faFacebookF} />
                        </a>
                        <a 
                            href="https://www.instagram.com/strongertogetherworld/"
                            rel="noreferrer"
                            target="_blank"
                        >
                            <FontAwesomeIcon icon={faInstagram} />
                        </a>
                    </div>
                    <h1>@StrongerTogetherWorld</h1>
                </div>      
            </div>
        </div>
    );
}

 
export default Footer;
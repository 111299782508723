import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AdminToDoList from './AdminToDoList';

const AdminDashHomeTool = ({ icon, toolName, toolType, toDo, toDoLoading }) => {
    return (
        <>
            {toolType === 0 && (
                <div className="bg-white g-shadow rounded-lg flex-1 flex flex-col justify-start items-center  p-6 basis-[400px]">
                    {/* Dash Tool Header */}
                    <div className="flex gap-3 items-center justify-start w-full text-lg font-bold mb-3">
                        <FontAwesomeIcon className="font-light opacity-50" icon={icon} />
                        {toolName}
                    </div>
                    {/* Dash Tool Content */}
                    {toolType === 0 && <AdminToDoList toDo={toDo} loading={toDoLoading} />}
                </div>
            )}
        </>
    );
}
 
export default AdminDashHomeTool;
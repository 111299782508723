import TipModal from "../../aComponents/TipModal";

const ContactFormField = ({ title, formId, formRef, error, isInput, hasTip, tipText }) => {
    return (
        <div className={`${isInput ? 'contact-form-input' : 'contact-form-textarea' }`}>
            <div>
                <label htmlFor={formId} className="uppercase font-bold tracking-widest text-[12px]">{ title }</label>
                {hasTip && <TipModal content={tipText} front={true} />}
                
            </div>
            {isInput && (
                <input className={`${error ? 'errors' : ''}`} name={formId} id={formId} type='text' ref={formRef} />
            )}
            {!isInput && (
                <textarea className={`${error ? 'errors' : ''}`} name={formId} id={formId} ref={formRef} rows="6" ></textarea>
            )}
        </div>
    );
}
 
export default ContactFormField;
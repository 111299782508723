import { useAdmin } from "../contexts/AdminContext";
import { useState, useEffect } from "react";
import Loading from "../components/Loading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faPenToSquare,
  faEyeSlash,
} from "@fortawesome/free-solid-svg-icons";
import AdminOrganisation from "./AdminOrganisation";

const AdminOrganisations = () => {
  const { orgs, orgsLoading, selectedOrg, setSelectedOrg } = useAdmin();
  const [sortedOrganisations, setSortedOrganisations] = useState([]);

  const scrollToTop = (type) => {
    window.scrollTo({
      top: 0,
      behavior: type,
    });
  };

  // Sort organisations AZ
  useEffect(() => {
    let sortData = [];
    orgs.forEach((item) => {
      sortData.push(item);
    });
    // Sort data
    sortData.sort(function (x, y) {
      let a = x.title.toUpperCase(),
        b = y.title.toUpperCase();
      return a === b ? 0 : a > b ? 1 : -1;
    });
    setSortedOrganisations([...sortData]);
  }, [orgs]);

  const handleOrgSelect = (organisation) => {
    setSelectedOrg(organisation);
  };

  useEffect(() => {
    scrollToTop("auto");
  }, [selectedOrg]);

  return (
    <div className="rounded-lg g-shadow bg-white overflow-hidden">
      {orgsLoading && (
        <div className="pb-3">
          <Loading bg="light" />
        </div>
      )}
      {!orgsLoading && !selectedOrg && (
        <>
          <div
            className={`cursor-pointer relative p-3 flex flex-wrap justify-start items-center gap-6 border-b-[1px] border-rgba(0,0,0,0.1) last:border-b-0`}
            onClick={() => {
              handleOrgSelect({ id: "new" });
            }}
          >
            <FontAwesomeIcon className="" icon={faPlus} />
            <span className="flex-1">Add Organisation</span>
          </div>
          {sortedOrganisations.map((item) => (
            <div
              key={item.id}
              className={`cursor-pointer relative p-3 flex flex-wrap justify-start items-center gap-6 border-b-[1px] border-rgba(0,0,0,0.1) last:border-b-0`}
              onClick={() => {
                handleOrgSelect(item);
              }}
            >
              <FontAwesomeIcon className="" icon={faPenToSquare} />
              <span className="flex-1 capitalize">{item.title}</span>
              {!item.visible && (
                <FontAwesomeIcon
                  className="text-[rgba(0,0,0,0.2)]"
                  icon={faEyeSlash}
                />
              )}
            </div>
          ))}
        </>
      )}
      {!orgsLoading && selectedOrg && <AdminOrganisation />}
    </div>
  );
};

export default AdminOrganisations;

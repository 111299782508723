import { useContext, useState, useEffect } from 'react';
import { AppContext } from '../../contexts/AppContext';
import { motion, AnimatePresence } from 'framer-motion';
import CountryCard from '../countries/CountryCard';
import OrganisationCard from '../organisations/OrganisationCard';

const resultsVariants = {
    hidden: {height:0, margin:0, padding:0},
    visible: {height:'auto', margin: '30px 0px 0px 0px', transition: {staggerChildren: 0.2}},
    exit:{height:0, margin: 0, padding:0}
};

const SearchResults = ({ searchActive, searchResults, resetSearch, searchValue }) => {
    const { language } = useContext(AppContext);
    const [ showOrganisations, setShowOrganisations ] = useState(false);

    const countriesTitle = ["країни", "Pays", "Countries"];
    const orgsTitle = ["організації", "Organisations", "Organisations"];
    const noResults = ['Нічого не знайдено', 'Aucun résultat', 'No results found'];
    const noResultsInfo = ['Наша команда постійно оновлює нашу базу даних найновішою інформацією та додає нові країни. Незабаром перевірте, чи була додана країна, про яку вам потрібна інформація. Найновіша інформація міститься в цьому документі Google Диска. Не соромтеся перевіряти та поділіться посиланням нижче.', 'Notre équipe met constamment à jour notre base de données avec les dernières informations et ajoute plus de pays. Revenez bientôt pour voir si le pays pour lequel vous souhaitez obtenir des informations a été ajouté. Les informations les plus récentes se trouvent dans ce document Google Drive, n\'hésitez pas à vérifier et à partager le lien ci-dessous.', 'Our team is constantly updating our database with the latest information and adding more countries. Check back soon to see if the country, you want information for, has been added. The most up to date information is in this Google Drive document, feel free to check and share the link below.'];
    const reset = ['Скинути пошук', 'Retour', 'Go Back'];

    const handleResultView = (x) => {
        setShowOrganisations(x);
    }

    useEffect(() => {
        if (searchResults.nations && searchResults.organisations && searchResults.nations.length > 0 && searchResults.organisations.length > 0 ) {
            // Both sets of results present - no action required
        } else if (searchResults.nations && searchResults.organisations && searchResults.nations.length > 0 && searchResults.organisations.length < 1 ) {
            // Only Nation results found - ensure countries selected
            setShowOrganisations(false);
        } else if (searchResults.nations && searchResults.organisations && searchResults.nations.length < 1 && searchResults.organisations.length > 0) {
            // Only organisation results found - ensure organisations selected
            setShowOrganisations(true);
        } else {
            // No results - reset to countries and show no results message
            setShowOrganisations(false);
        }
    }, [searchResults]);

    return (
        <AnimatePresence>
            {searchActive && searchValue !== '' && (
                <motion.div
                    variants={resultsVariants}
                    initial="hidden"
                    animate="visible"
                    exit="exit"
                    className="search-results-container"
                >
                    <div>
                        <div className="search-bar-nav">
                            <span 
                                className={`${showOrganisations && 'bg-lightVTransparent rounded-lg cursor-pointer'}`}
                                onClick={() => {handleResultView(false)}}
                            >
                                { countriesTitle[language] } ({searchResults.nations && searchResults.nations.length})
                                {!showOrganisations && (
                                    <span></span>
                                )}
                            </span>
                            <span
                                className={`${!showOrganisations && 'bg-lightVTransparent rounded-lg cursor-pointer'}`}
                                onClick={() => {handleResultView(true)}}
                            >
                                { orgsTitle[language] } ({searchResults.organisations && searchResults.organisations.length})
                                {showOrganisations && (
                                    <span></span>
                                )}
                            </span>
                        </div>
                     
                        {searchValue !== '' && ((searchResults.nations && searchResults.nations.length < 1 && !showOrganisations ) || (searchResults.organisations  && searchResults.organisations < 1 && showOrganisations)) && (
                            <>
                                <div className='no-results-container'>
                                    <h3>{ noResults[language] }</h3>
                                    <p>{ noResultsInfo[language] }</p>
                                    <a 
                                        href='https://docs.google.com/spreadsheets/d/17xzc2mNoUW1uLYN_ex5F-tqaJjy143sd/edit#gid=563296685'
                                        target='_blank'
                                        rel="noreferrer"
                                    >Google Drive</a>
                                    <span onClick={resetSearch}>{ reset[language] }</span>
                                </div>
                            </>
                        )}
                        {searchValue !== '' && searchResults.nations && searchResults.nations.length > 0 && !showOrganisations && (
                            <div className='basis-full grid gap-[20px] break560:gap-[30px] grid-cols-2 break460:grid-cols-3 break700:grid-cols-4 break1070:grid-cols-6'>
                                {searchResults.nations.map(item => (
                                    <CountryCard item={item} key={item.id} />
                                ))}
                            </div>
                        )}
                        {searchValue !== '' && searchResults.organisations && searchResults.organisations.length > 0 && showOrganisations && (
                            <div className='basis-full grid gap-[20px] break650:gap-[30px] grid-cols-1 break650:grid-cols-2 break1000:grid-cols-3'>
                                {searchResults.organisations.map(item => (
                                    <OrganisationCard organisation={item} key={item.id} />
                                ))}
                            </div>
                        )}

                    </div>
                </motion.div>
            )}
        </AnimatePresence>


    );
}
 
export default SearchResults;
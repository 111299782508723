import AdminChangesModalItem from './AdminChangesModalItem';
import { useAdmin } from '../contexts/AdminContext';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

const AdminChangesModal = ({ setChangesOpen }) => {
    const { changes, submitAllChanges, submittingAll, setSelectedNation, setSelectedOrg } = useAdmin();
    const [ submitAllFail, setSubmitAllFail ] = useState(false);

    const handleClose = () => {
        setChangesOpen(false);
    }

    const handlePropogation = (e) => {
        e.stopPropagation();
    }

    const publishAllChanges = () => {
        if (submittingAll) {
            return false;
        }
        setSelectedNation(null);
        setSelectedOrg(null);
        submitAllChanges()
            .catch(err => {
                setSubmitAllFail(current => {
                    return err;
                });
            })
    }

    return (
        <div className="blurred-modal-outer z-50" onClick={handleClose}>
            <div className="flex flex-col !shrink !grow-0 max-h-full !basis-[600px] bg-white g-shadow rounded-lg" onClick={handlePropogation}>
                {/* Changes found */}
                {(changes.nations && changes.organisations) && (changes.nations.length > 0 || changes.organisations.length > 0) && (
                    <>
                        <p className="text-2xl text-center uppercase p-3">Unpublished Changes</p>
                        <div className="flex-1 overflow-y-auto flex flex-wrap gap-3 p-3">
                            {changes.nations && changes.nations.length > 0 && changes.nations.map(item => (
                                <AdminChangesModalItem key={item.id} type={0} item={item} setChangesOpen={setChangesOpen} submitAllFail={submitAllFail} />
                            ))}
                              {changes.organisations && changes.organisations.length > 0 && changes.organisations.map(item => (
                                <AdminChangesModalItem key={item.id} type={1} item={item} setChangesOpen={setChangesOpen} submitAllFail={submitAllFail} />
                            ))}
                        </div>
                        <div className='p-3 flex justify-center items-center gap-3'>
                            {!submittingAll && (
                                <>
                                   <span
                                        className='px-3 py-2 font-bold tracking-widest uppercase cursor-pointer'
                                        onClick={handleClose}
                                    >Close</span>
                                    <span 
                                        className='px-3 py-2 rounded-lg bg-[#52B3F5] g-shadow !text-white font-bold tracking-widest uppercase cursor-pointer'
                                        onClick={publishAllChanges}
                                    >Publish</span>
                                </>
                            )}
                            {submittingAll && (
                                <>
                                    <div className="basis-full h-[40px] flex gap-3 items-center justify-center cursor-default">
                                        <span className='basis-[80px] flex items-center justify-center'><FontAwesomeIcon className=' text-[20px] animate-spin' icon={faSpinner}/></span>
                                    </div> 

                                </>
                            )}
                         
                        </div>
                        <p className="text text-center opacity-75 p-3">{ changes.nations.length } nations changed. { changes.organisations.length } organisations changed.</p>
                    </>
                
                )}
                {/* No changes */}
                {(changes.nations && changes.organisations) && (changes.nations.length < 1 && changes.organisations.length < 1) && (
                    <>
                        <p className="text-2xl text-center uppercase px-3 pt-3">All Good</p>
                        <p className="text text-center opacity-75 px-3 pb-3">Local data matches data on the servers</p>
                    </>
                )}
            </div>
        </div>
    );
}
 
export default AdminChangesModal;
import { useAuth } from '../contexts/AuthContext';

const AdminMessage = ({ message, setSelectedMessage }) => {
    const { currentUser } = useAuth();

    const handleSelectMessage = () => {
        setSelectedMessage(message);
    }
    
    return (
        <>
            {message && (
                <div 
                    className="flex border-b-[1px] border-b-[rgba(0,0,0,0.1)] last:border-b-0 cursor-pointer" 
                    onClick={handleSelectMessage}
                >
                    <span className={`border-l-[5px] ${(message.viewedBy && message.viewedBy.includes(currentUser.uid)) ? 'border-transparent' : 'border-l-red-500'} p-3 border-r-[1px] border-r-[rgba(0,0,0,0.1)] basis-[120px]`}>{ new Date(message.timestamp.toMillis()).toLocaleDateString() }</span>
                    <span className="flex-1 truncate p-3">{(message.type === 0 || message.type === 1) ? message.name : message.request}</span>
                </div>
            )}
            {!message && (
                <p className="p-3 text-center uppercase tracking-widest">No messages</p>
            )}
        </>
    );
}
 
export default AdminMessage;
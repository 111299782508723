import { Link } from 'react-router-dom';
import { useEffect, useState, useContext } from 'react';
import { AppContext } from '../../contexts/AppContext';
import flagData from '../../utils/flagData';


const OrganisationNationCard = ({ id, nations }) => {
    const { language } = useContext(AppContext);
    const [ countryNames, setCountryNames ] = useState(['', '', '']);
    const [ noCountry, setNoCountry ] = useState(['', '', '']);

    useEffect(() => {
        if (nations.length < 1) {
            // No nations loaded yet - will rerun when nations found
            return;
        } else {
            // Nations loaded fetch data from nations
            const foundNation = nations.find(item => (item.id === id || item.name[2].toLowerCase() === id));
            if (!foundNation) {
                // No nation found - redirect to countries page
                if (id === 'ukraine') {
                    setCountryNames(['Україна', 'Ukraine', 'Ukraine']);
                    setNoCountry(false);
                } else {
                    setNoCountry(true);
                }
            } else {
                // Nation found - set data
                setNoCountry(false);
                setCountryNames([...foundNation.name]);
            }
        }
    }, [nations]);

    return (
        <>
            {!noCountry && (
                <>
                    {id === 'ukraine' && (
                        <div>
                            <div>
                                <div>
                                    <img src={`/images/flags/${ flagData[countryNames[2].toLowerCase()] }.png`} alt={countryNames[2] + ' flag'} />
                                </div>
                                <p>{countryNames[language]}</p>
                            </div>
                        </div>
                    )}
                    {id !== 'ukraine' && (
                        <Link to={`/countries/${countryNames[2].toLowerCase().replaceAll(' ', '_')}`} >
                            <div>
                                <div>
                                    <img src={`/images/flags/${ flagData[countryNames[2].toLowerCase()] }.png`} alt={countryNames[2] + ' flag'} />
                                </div>
                                <p>{countryNames[language]}</p>
                            </div>
                        </Link>
                    )}
                </>                
            )}
        </>
    );
}
 
export default OrganisationNationCard;


import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { useEffect } from 'react';
import AdminMessagesTypeCard from './AdminMessagesTypeCard';

const AdminMessagesContainer = ({ setDashPage, messages, messagesLoading }) => {
    const [ generalCount, setGeneralCount ] = useState(0);
    const [ volunteerCount, setVolunteerCount ] = useState(0);
    const [ requestCount, setRequestCount ] = useState(0);

    useEffect(() => {
        setGeneralCount(messages.filter(item => (item.type === 0)).length);
        setVolunteerCount(messages.filter(item => (item.type === 1)).length);
        setRequestCount(messages.filter(item => (item.type === 2)).length);
    }, [messages]);

    return (
        <div className='px-6 mb-6'>
            <div className='flex gap-3 items-start'>
                <div className="bg-white g-shadow rounded-lg flex-1 flex flex-col justify-start items-center  p-6 basis-[400px]">
                    {/* Messages Header */}
                    <div className="flex gap-3 items-center justify-start w-full text-lg font-bold mb-3">
                        <FontAwesomeIcon className="font-light opacity-50" icon={faEnvelope} />
                        Messages
                    </div>
                    {/* Message Type Selector */}
                    <div className='flex gap-3 w-full flex-wrap my-3'>
                        <AdminMessagesTypeCard setDashPage={setDashPage} messageType={5} count={generalCount} />
                        <AdminMessagesTypeCard setDashPage={setDashPage} messageType={6} count={volunteerCount} />
                        <AdminMessagesTypeCard setDashPage={setDashPage} messageType={7} count={requestCount} />
                    </div>
                </div>
            </div>
        </div>
    );
}
 
export default AdminMessagesContainer;
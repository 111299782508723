import { faPenNib } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext } from "react";
import { AppContext } from "../../contexts/AppContext";

const ActionPetition = () => {
  const { language } = useContext(AppContext);

  const title = [
    "Підписуйте петиції",
    "Signez des pétitions",
    "Sign petitions",
  ];

  const text = [
    "Підписання петицій допомагає кількісно визначити рівень підтримки будь-якого руху. Є кілька петицій, пов’язаних із вторгненням в Україну, від скорочення візових вимог для тимчасово переміщених українців до прохання НАТО очистити небо над Україною.",
    "La signature de pétitions aide à quantifier le niveau de soutien à tout mouvement. Il existe plusieurs pétitions liées à l'invasion de l'Ukraine, allant de la réduction des exigences de visa pour le Ukrainien.ne.s temporairement déplacé.e.s à la demande pour que l'OTAN dégage le ciel de l'Ukraine.",
    "Signing petitions helps to quantify the level of support for any movement. There are several petitions related to the invasion of Ukraine, ranging from the reduction of visa requirements for temporarily displaced Ukrainians to the request for NATO to clear the skies over Ukraine.",
  ];

  const petitions = [
    {
      image: "petition_uk.ico",
      url: "https://petition.parliament.uk/petitions?state=open&q=ukraine",
      title: "Petitions | UK Government and Parliament",
    },
    {
      image: "change.webp",
      url: "https://www.change.org/p/ukraine-la-guerre-est-une-folie",
      title: "Change.org | Ukraine. La guerre est une folie !",
    },
    {
      image: "petitionnet.jpg",
      url: "https://www.petitions.net/ua/browse.php?order_by=petition_created&page=1",
      title: "Petition.net | Most recent petitions in Ukraine",
    },
  ];
  return (
    <div className="py-[30px] radial-backer">
      <div className="relative width-container">
        <div className="flex gap-[40px] break950:gap-[80px] flex-wrap justify-between">
          <div className="hidden break700:flex basis-0 grow shrink break1070:grow-0 break1070:basis-[450px] justify-center items-center">
            <img
              className="rounded-lg w-full max-w-[400px]"
              alt="petitions"
              src="images/getinvolved/yukon-unsplash.jpg"
            />
          </div>
          <div className="flex-1 flex flex-col items-start justify-center gap-6">
            <div className="get-inv-icon bg-backgroundLight">
              <FontAwesomeIcon
                className=" text-darkColor text-[30px]"
                icon={faPenNib}
              />
            </div>
            <h3 className="text-[28px] break650:text-3xl uppercase font-medium leading-none">
              {title[language]}
            </h3>
            <p className="text-lg leading-relaxed">{text[language]}</p>
            <ul className="mt-3">
              {petitions.map((item, index) => (
                <li className="py-3" key={index}>
                  <div className="flex justify-center items-center gap-3">
                    <img
                      alt="petition-list"
                      src={`images/getinvolved/${item.image}`}
                      className="w-[32px]"
                    />
                    <h4 className="flex-1">
                      <a href={item.url}>{item.title}</a>
                    </h4>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActionPetition;

import { useState } from 'react';
import Loading from '../components/Loading';
import AdminNote from './AdminNote';

const AdminNotes = ({ notes, loading }) => {
    const [ selectedNote, setSelectedNote ] = useState(null);

    return (
        <div className='rounded-lg g-shadow bg-white'>
            <AdminNote note={{ id:'new' }} selectedNote={selectedNote} setSelectedNote={setSelectedNote} />
            {loading && (
                <div className='pb-3'>
                    <Loading bg='light' />
                </div>
            )}
            {!loading && (
                <>
                    {notes.map(item => (
                        <AdminNote note={item} selectedNote={selectedNote} setSelectedNote={setSelectedNote} key={item.id} />
                    ))}
                </>
            )}
        </div>
    );
}
 
export default AdminNotes;
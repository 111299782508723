import { motion, AnimatePresence } from "framer-motion";

import SearchContainer from './SearchContainer';
import Hero from "./Hero";

const HomePage = ({ searchActive, setSearchActive, nations, organisations, services }) => {
    const homePageVariants = {hidden: {x:1000},visible: {x:0},exit: {x:'-100vw'}}

    return (
        <motion.div
            variants={homePageVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
            className="home-page"
        >
            {/* Search */}
            <SearchContainer searchActive={searchActive} setSearchActive={setSearchActive} nations={nations} organisations={organisations} services={services} />
            <AnimatePresence>
                {!searchActive && (
                    <Hero countryCount={nations.length} servicesCount={services.length} orgsCount={organisations.length} />
                )}
            </AnimatePresence>
        </motion.div>
    );
}
 
export default HomePage;
import { useContext } from 'react';
import { AppContext } from '../../contexts/AppContext';
import ContactFormGeneral from './ContactFormGeneral';
import ContactFormJoin from './ContactFormJoin';
import ContactFormRequest from './ContactFormRequest';

const ContactForm = ({ contactType, setContactType, title }) => {
    const { language } = useContext(AppContext);

    return (
        <div className="contact-form-outer" >
            <div>
                <div>
                    <div className='contact-form'>
                        <h1 className="contact-section-title break400:hidden">{ title[contactType][language].top + " " + title[contactType][language].bottom }</h1>
                        {contactType === 0 && <ContactFormGeneral setContactType={setContactType} />}
                        {contactType === 1 && <ContactFormJoin setContactType={setContactType} />}
                        {contactType === 2 && <ContactFormRequest setContactType={setContactType} />}
                    </div>
                </div>
            </div>
        </div>
    );
}
 
export default ContactForm;
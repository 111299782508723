import { motion } from "framer-motion";
import NewsList from "./NewsList";
import Actions from "./Actions";
import ActionVolonter from "./ActionVolonter";
import ActionPetition from "./ActionPetition";

const GetInvolvedPage = () => {
  const variants = {
    hidden: { x: 1000 },
    visible: { x: 0 },
    exit: { x: "-100vw" },
  };

  return (
    <motion.div
      variants={variants}
      initial="hidden"
      animate="visible"
      exit="exit"
      className="getinv-page-container getinv"
    >
      <NewsList />
      <ActionPetition />
      <Actions />
      <ActionVolonter />
    </motion.div>
  );
};

export default GetInvolvedPage;

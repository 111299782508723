import { useContext } from "react";
import { AppContext } from '../../contexts/AppContext';

const CountryExtraInformation = ({ nation }) => {
    const { language } = useContext(AppContext);
    const extraInfoTitle = ["Додаткова інформація", "plus d'informations", "Additional Information"];
    const emergencyServices  = ["Аварійні служби", "Services d'urgence", "Emergency Services"];
    const police = ['поліція', 'police', 'police'];
    const ambulance = ['швидка допомога', 'ambulance', 'ambulance'];
    const fire = ['вогонь', 'Feu', 'fire'];

    return (
        <>
            {(nation.ambulance || nation.fire || nation.police) && (
                <div>
                    <h3 className="country-section-title">{ extraInfoTitle[language] }</h3>
                    <div className="country-extra-info">
                        {(nation.ambulance || nation.fire || nation.police) && (
                            <div>
                                <div className="country-extra-info-header">
                                    <h4>{ emergencyServices[language] }</h4>
                                </div>
                                <div className="emergency-services">
                                    {(nation.police !== '') && (
                                        <>
                                            {nation.police.includes('/') && (
                                                <div>
                                                    <p>{ police[language] }</p>
                                                    <span href={`tel:${nation.police}`}>{nation.police}</span>
                                                </div>
                                            )}
                                            {!nation.police.includes('/') && (
                                                <a href={`tel:${nation.police}`}>
                                                    <p>{ police[language] }</p>
                                                    <span href={`tel:${nation.police}`}>{nation.police}</span>
                                                </a>
                                            )}
                                        </>
                                    )}
                                    {(nation.ambulance !== '') && (
                                        <>
                                            {nation.ambulance.includes('/') && (
                                                <div>
                                                    <p>{ ambulance[language] }</p>
                                                    <span href={`tel:${nation.ambulance}`}>{nation.ambulance}</span>
                                                </div>
                                            )}
                                            {!nation.ambulance.includes('/') && (
                                                <a href={`tel:${nation.ambulance}`}>
                                                    <p>{ ambulance[language] }</p>
                                                    <span href={`tel:${nation.ambulance}`}>{nation.ambulance}</span>
                                                </a>
                                            )}
                                        </>
                                    )}
                                    {(nation.fire !== '') && (
                                        <>
                                            {nation.fire.includes('/') && (
                                                <div>
                                                    <p>{ fire[language] }</p>
                                                    <span href={`tel:${nation.fire}`}>{nation.fire}</span>
                                                </div>
                                            )}
                                            {!nation.fire.includes('/') && (
                                                <a href={`tel:${nation.fire}`}>
                                                    <p>{ fire[language] }</p>
                                                    <span href={`tel:${nation.fire}`}>{nation.fire}</span>
                                                </a>
                                            )}
                                        </>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>     
                </div>
            )}
        </>
    );
}
 
export default CountryExtraInformation;
import { useState, useEffect } from 'react';
import { collection, onSnapshot, query, orderBy } from 'firebase/firestore';
import { db } from '../firebase-config';
import Loading from '../components/Loading';
import AdminNationNextStep from './AdminNationNextStep';

const AdminNextSteps = ({ selectedNation }) => {
    const [ steps, setSteps ] = useState([]);
    const [ stepsLoading, setStepsLoading ] = useState(true);

    // Firestore Query
    const colRef = collection(db, 'countryList', selectedNation.id, 'next-steps');
    const q = query(colRef, orderBy("timestamp", "desc"));
    // Fetch data from firestore
    useEffect(() => {  
        const cancelEmbSubscriber = onSnapshot(q, (snapshot) => {
            setSteps(snapshot.docs.map((doc) => ({...doc.data(), id: doc.id})));
            setStepsLoading(false);
        })
        return cancelEmbSubscriber;
    }, []);
    
    return (
        <div className='rounded-lg g-shadow bg-backgroundLight'>
            <AdminNationNextStep step={null}  selectedNation={selectedNation} />
            {stepsLoading && (
                <div className='pb-3'>
                    <Loading bg='light' />
                </div>
            )}
            {steps && (
                <>
                    {steps.map(item => (
                        <AdminNationNextStep step={item} selectedNation={selectedNation} key={item.id} />
                    ))}
                </>
            )}
        </div>
    );
}
 
export default AdminNextSteps;

import { useEffect } from "react";
import { useAuth } from '../contexts/AuthContext';
import { doc, updateDoc, deleteDoc } from "firebase/firestore";
import { db } from "../firebase-config";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";

const MessageModal = ({ selectedMessage, setSelectedMessage }) => {
    const { currentUser } = useAuth();

    const [ loading, setLoading ] = useState(false);
    const [ views, setViews ] = useState(selectedMessage.viewedBy ? selectedMessage.viewedBy.length : 0);

    const closeMessage = () => {
        setSelectedMessage(null);
    }

    const handlePropogation = (e) => {
        e.stopPropagation();
    }

    const markRead = async () => {
        let newData;
        newData = {
            ...selectedMessage
        }
        newData.viewedBy = selectedMessage.viewedBy ? [...selectedMessage.viewedBy, currentUser.uid] : [currentUser.uid];
        const docRef = doc(db, 'messages', selectedMessage.id)
        try {
            await updateDoc(docRef, newData);
        } catch (err) {

        }
    }

    const handleDelete = async () => {
        // Check if loading already 
        if (loading) {
            return false;
        }
        setLoading(true);
        // Submit to firestore
        try {
            const docRef = doc(db, "messages", selectedMessage.id);
            await deleteDoc(docRef);
            setSelectedMessage(null);
            setLoading(false);
        } catch (err) {
            setLoading(false);
        }  
    }

    useEffect(() => {
        let needUpdate = false;
        // Check if message has viewedBy
        if (selectedMessage.viewedBy) {
            // Check if viewedBy array contains user's uid
            if (!selectedMessage.viewedBy.includes(currentUser.uid)) {
                needUpdate = true;
            }
        } else {
            needUpdate = true;
        }
        // Add uid to array if not included.
        if (needUpdate) {
            markRead();
            setViews(views + 1);
        }
    }, []);

    useEffect(() => {
        let  adminMessage = document.getElementById("adminMessage");
        if (adminMessage) {
            if (((selectedMessage.type === 0 || selectedMessage.type === 1) && selectedMessage.message.includes('<script>')) || (selectedMessage.type === 2 && selectedMessage.request.includes('<script>')) ) {
                // Message contains script tags - abort
                adminMessage.innerHTML = "<i>This message contains a hacking attempt! Aborted displaying message!</i>";
            } else {
                adminMessage.innerHTML = (selectedMessage.type === 0 || selectedMessage.type === 1) ? selectedMessage.message.replace(/(?:\r\n|\r|\n)/g, '<br />') : selectedMessage.request.replace(/(?:\r\n|\r|\n)/g, '<br />');
            }
        }
    }, [])

    return (  
        <div className="blurred-modal-outer z-50" onClick={closeMessage}>
            <div className="tip-modal gap-1" onClick={handlePropogation}>
                <h1 className="font-bold uppercase border-b-[1px] border-b-[rgba(0,0,0,0.7)] mb-3 tracking-widest basis-full flex justify-between">
                    {selectedMessage.type === 0 ? 'Message' : selectedMessage.type === 1 ? 'Application' : 'Request' }
                    <span><FontAwesomeIcon icon={faEye} /> {views}</span>
                </h1>
                <p className="opacity-75 basis-full"><b className="uppercase">Date: </b>{ new Date(selectedMessage.timestamp.toMillis()).toLocaleString() }</p>
                {(selectedMessage.type === 0 || selectedMessage.type === 1) && (
                    <>
                        <p className="opacity-75 basis-full allow_select"><b className="uppercase no_select">From: </b>{ selectedMessage.name }</p>
                        <p className="opacity-75 basis-full allow_select"><b className="uppercase no_select">Contact: </b>{ selectedMessage.email }</p>
                        <p id="adminMessage" className="opacity-75 basis-full allow_select"></p>
                    </>
                )}
                {selectedMessage.type === 2 && (
                    <>
                        <p id="adminMessage" className="opacity-75 basis-full allow_select"></p>
                    </>
                )}
                <div className="mt-3 basis-full flex flex-wrap-reverse justify-center items-center p-3 gap-3">
                    <span
                        onClick={closeMessage}
                        className="p-3 grow shrink basis-[100px] text-center uppercase font-bold tracking-widest cursor-pointer"
                    >Close</span>
                    <span
                        onClick={handleDelete}
                        className="bg-red-500 rounded-lg p-3 grow shrink basis-[100px] text-center !text-white uppercase font-bold tracking-widest g-shadow cursor-pointer"
                    >Delete</span>
                    
                </div>
            </div>
        </div>
    );
}
 
export default MessageModal;
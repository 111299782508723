import { useParams } from 'react-router-dom';
import { useState, useEffect, useContext } from 'react';
import { AppContext } from '../../contexts/AppContext';
import { motion } from "framer-motion";
import Loading from '../Loading';
import CountryVisa from './CountryVisa';
import CountryEmbassyList from './CountryEmbassyList';
import CountryNextSteps from './CountryNextSteps';
import CountryTravel from './CountryTravel';
import CountryOrganisations from './CountryOrganisations';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import CountryExtraInformation from './CountryExtraInformation';

const CountryPage = ({ nations, organisations }) => {
    const variants = {hidden: {x:1000},visible: {x:0},exit: {x:"-100vw"}};
    const { language } = useContext(AppContext);
    const { countryName } = useParams();
    const [ nation, setNation ] = useState(null);
    const [ noCountry, setNoCountry ] = useState(false);
    const warning = ['Ця інформація може бути застарілою або помилковою, оскільки вона походить не з офіційного джерела.', 'Cette information peut être désuète ou erronée car elle ne provient pas d’une source officielle.', 'This information may be outdated or erroneous as it does not come from an official source.']
    const warningTitle = ['УВАГА', 'Attention', 'WARNING'];

    useEffect(() => {
        const setName = countryName.replaceAll('_', ' ');
        if (nations.length < 1) {
            // No nations loaded yet - will rerun when nations found
            return;
        } else {
            // Nations loaded fetch data from nations
            const foundNation = nations.find(item => (item.name[2].toLowerCase() === setName));
            if (!foundNation) {
                // No nation found - redirect to countries page
                setNoCountry(true);
            } else {
                // Nation found - set data
                setNoCountry(false);
                setNation(foundNation);
            }
        }
    }, [nations, countryName, organisations]);

    return (
        <motion.div
            variants={variants}
            initial="hidden"
            animate="visible"
            exit="exit"
            className="page-container"
        >
            <div>
                {noCountry && (
                    // No country found - show error message
                    <>No nation found.</>
                )}
                {!noCountry && nation && (
                    // Country found and data filled - show data
                    <>
                        <div className="country-header">
                            <h4>{ nation ? nation.name[language] : "" }</h4>
                            {nation.warning && (
                                <div className='country-warning'>
                                    <FontAwesomeIcon className='warning-icon' icon={faTriangleExclamation} />
                                    <div>
                                        <div>
                                            <FontAwesomeIcon className='warning-icon' icon={faTriangleExclamation} />
                                            <p>{warningTitle[language]}</p>
                                        </div>
                                        <p>{warning[language]}</p>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="country-content">
                            {/* Visa requirements */}
                            <CountryVisa nation={nation} />
                            {/* Embassy List */}
                            <CountryEmbassyList nation={nation} />
                            {/* Next Steps */}
                            <CountryNextSteps nation={nation} />
                            {/* Organisations */}
                            <CountryOrganisations nation={nation} organisations={organisations} />
                            {/* Travel Info */}
                            <CountryTravel nation={nation} />
                            {/* Extra Info */}
                            <CountryExtraInformation nation={nation} />
                        </div>
                    </>
                )}
                {!noCountry && !nation && (
                    <Loading bg="dark" />
                )}
            </div>  
        </motion.div>
    );
}
 
export default CountryPage;
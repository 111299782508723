import { useEffect, useState } from 'react';
import { useContext } from 'react';
import { AppContext } from '../../contexts/AppContext';

const OrganisationServicesItem = ({ id, services }) => {
    const { language } = useContext(AppContext);

    const [ selectionItem, setSelectionItem ] = useState();
    const [ notFound, setNotFound ] = useState(true);

    // Get service name
    useEffect(() => {
        if (services.length < 1) {
            // Services array still empty - wait until data received
            return;
        } else {
            // !! accepting name and id for now to be back compatible - remove once data restored
            const foundItem = services.find(item => (item.id === id || item.name[2].toLowerCase() === id));
            if (foundItem) {
                setSelectionItem({...foundItem});
                setNotFound(false);
            } else {
                setNotFound(true);  
            }
        }
    }, [services]);

    return (
        <>
            {!notFound && (
                <span className={`${selectionItem.name[2] === '2slgbtqia+' ? 'uppercase' : 'capitalize' }`}>{ selectionItem.name[language] }</span>
            )}
        </>
    );
}
 
export default OrganisationServicesItem;
import { useState, useEffect, useContext } from "react";
import { AppContext } from "../../contexts/AppContext";
import { motion } from "framer-motion";
import CountryCard from "./CountryCard";
import Loading from "../Loading";

const CountriesPage = ({ nations }) => {
  const variants = {
    hidden: { x: 1000 },
    visible: { x: 0 },
    exit: { x: "-100vw" },
  };
  const { language } = useContext(AppContext);
  const [sortedNations, setSortedNation] = useState([]);

  // Sort nations AZ
  useEffect(() => {
    let sortData = [];
    nations.forEach((item) => {
      sortData.push(item);
    });
    // Sort data
    sortData.sort(function (x, y) {
      let a = x.name[language === 1 ? 1 : 2].toUpperCase(),
        b = y.name[language === 1 ? 1 : 2].toUpperCase();
      return a === b ? 0 : a > b ? 1 : -1;
    });
    setSortedNation(sortData);
  }, [language, nations]);

  return (
    <motion.div
      variants={variants}
      initial="hidden"
      animate="visible"
      exit="exit"
      className="page-container"
    >
      <div>
        <div className="page-list">
          {nations.length < 1 && <Loading bg="dark" />}
          {nations.length > 0 && (
            <>
              <div className="grid gap-[20px] break560:gap-[30px] grid-cols-2 break560:grid-cols-3 break650:grid-cols-4 break1070:grid-cols-6">
                {sortedNations.map((item) => (
                  <CountryCard item={item} key={item.id} />
                ))}
              </div>
            </>
          )}
        </div>
      </div>
    </motion.div>
  );
};

export default CountriesPage;

import { useState, useContext } from "react";
import { AppContext } from '../contexts/AppContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';

const TipModal = ({ content, front }) => {
    const [tipOpen, setTipOpen] = useState(false);
    const { language } = useContext(AppContext);
    const importantText = ['Інформація', 'Informations', 'Information'];

    const toggleTipModal = () => {
        setTipOpen(!tipOpen);
    }

    const handlePropogation = (e) => {
        e.stopPropagation();
    }

    return (
        <>
            {/* Icon */}
            <div className={`absolute right-0 top-0 bottom-0 h-full w-[20px] cursor-pointer flex justify-center items-center hover:opacity-100 ${!front ? 'opacity-30' : 'opacity-75'}`} onClick={toggleTipModal}>
                <FontAwesomeIcon className={`text-[16px] ${front ? 'text-lightColor' : 'text-darkColor'}`} icon={faCircleInfo}/>
            </div>
            {/* Modal */}
            {tipOpen && (
                <div className="blurred-modal-outer z-50" onClick={toggleTipModal}>
                    <div className="tip-modal" onClick={handlePropogation}>
                        <h1 className="font-bold uppercase border-b-[1px] border-b-[rgba(0,0,0,0.7)] mb-3 tracking-widest basis-full text-darkColor">{ importantText[language] }</h1>
                        <p className="opacity-75 basis-full text-darkColor" dangerouslySetInnerHTML={{ __html: content }}></p>
                    </div>
                </div>
            )}
        </>
    
    );
}
 
export default TipModal;
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ContactPageTypeItem = ({ contactType, handleContactType, icon, title, type }) => {

    return (
        <div
            className={`contact-type ${contactType === type ? '' : 'bg-lightVTransparent'}`}
            onClick={() => handleContactType(type)}    
        >
            <div className="contact-type-image">
                <div className={`${contactType === type && 'scale-[2]'}  ${contactType !== type ? type === 0 ? '!bg-[rgba(255,30,0,0.4)] ' : type === 1 ? '!bg-[rgba(0,30,215,0.4)] ' : '!bg-[rgba(30,235,20,0.4)] ' : ''}`}>
                    <FontAwesomeIcon className="contact-type-icon" icon={icon} />
                </div>
            </div>
            <div className='contact-type-text'>
                <p>{ title.top }</p>
                <p>{ title.bottom }</p>
            </div>
            {contactType === type && (
                <span></span>
            )}
        </div>
    );
}
 
export default ContactPageTypeItem;
import { useState, useContext } from "react";
import { AppContext } from '../../contexts/AppContext';
import { motion } from "framer-motion";

const CountryNextStepsCard = ({ step }) => {
    const { language } = useContext(AppContext);
    const [ expanded, setExpanded ] = useState(false);
    const variants = {hidden: {height:0},visible: {height:'auto'}};
    const toggleVariants = {hidden: {rotate:90},visible: {rotate:0}};

    const toggleExpanded = () => {
        setExpanded(current => (!current));
    };
    if (step.name[language] === '' || step.content[language] === '' ) {
        return null;
    } else {
        return (
            <div className={`rounded-lg overflow-hidden group ${expanded ? 'bg-white' : 'bg-lightVTransparent hover:bg-white'}`}>
                {/* Step Title and Toggle */}
                <div 
                    onClick={toggleExpanded}    
                    className="flex justify-between items-center px-[15px] cursor-pointer gap-[15px]"
                >
                    <p className={`flex-1 py-[15px]  ${expanded ? '!text-darkColor' : 'group-hover:text-darkColor'} font-bold uppercase tracking-wide`}>{ step.name[language] }</p>
                    <div className="w-[24px] flex justify-center items-center">
                        <div className={`relative w-full h-[3px] rounded-md  ${expanded ? 'bg-darkColor' : 'bg-lightColor group-hover:bg-lightBlue'}`}>
                            <motion.div
                                variants={toggleVariants}
                                initial="hidden"
                                animate={expanded ? 'visible' : 'hidden'}            
                                className={`absolute w-full h-full rounded-md ${expanded ? 'bg-darkColor' : 'bg-lightColor group-hover:bg-lightBlue'}`}
                            ></motion.div>
                        </div>
                    </div>
                </div>
                {/* Step Content */}
                <motion.div
                    variants={variants}
                    initial="hidden"
                    animate={expanded ? 'visible' : 'hidden'}
                    className={`overflow-hidden`}
                >
                    <p
                        className="p-[15px] text-darkColor allow_select"
                        dangerouslySetInnerHTML={{ __html: step.content[language].replace(/(?:\r\n|\r|\n)/g, '<br>') }}
                    ></p>
                </motion.div>
            </div>
        );
    }
}
 
export default CountryNextStepsCard;
import { useAuth } from '../contexts/AuthContext';
import { useAdmin } from '../contexts/AdminContext';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRightFromBracket, faArrowUpFromBracket } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import AdminChangesModal from './AdminChangesModal';

const AdminHeader = () => {
    const { currentUser, logout } = useAuth();
    const { chooseNewDashPage, changes } = useAdmin();
    const [ changesOpen, setChangesOpen ] = useState(false);

    // Open Changes Modal 
    const handleShowChanges = () => {
        setChangesOpen(true);
    }

    // Logout
    const handleLogout = async () => {
        try {
            await logout();
        } catch (err) {

        }
    }

    return (
        <>
            <div className='p-3 bg-white g-shadow flex justify-between items-center'>
                <Link to='/' ><h1 className='font-bold uppercase pl-3'>Stronger Together</h1></Link>
                <div className='flex items-center gap-3'>
                    <span className='cursor-pointer hidden break460:block' onClick={() => {chooseNewDashPage(1)}}>{ currentUser.displayName ? currentUser.displayName : currentUser.email }</span>
                    <span className='p-3 cursor-pointer h-shadow rounded-lg relative' onClick={handleShowChanges}>
                        <FontAwesomeIcon icon={faArrowUpFromBracket} />
                        <span className={`absolute top-[8px] right-[8px] h-[8px] w-[8px] rounded-full ${(changes.nations || changes.organisations) && (changes.nations.length > 0 || changes.organisations.length > 0) ? 'bg-red-400' : 'bg-green-400'}`}></span>
                    </span>
                    <span className='p-3 cursor-pointer h-shadow rounded-lg' onClick={handleLogout}><FontAwesomeIcon icon={faRightFromBracket} /></span>
                </div>
            </div>
            {changesOpen && <AdminChangesModal setChangesOpen={setChangesOpen} />}
        </>
    );
}
 
export default AdminHeader;
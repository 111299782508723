import { useState, useEffect } from 'react';
import { collection, onSnapshot, query } from 'firebase/firestore';
import { db } from '../firebase-config';
import Loading from '../components/Loading';
import AdminNationEmbassy from './AdminNationEmbassy';

const AdminNationEmbassies = ({selectedNation}) => {
    const [ embassies, setEmbassies ] = useState([]);
    const [ embassiesLoading, setEmbassiesLoading ] = useState(true);

    // Firestore Query
    const colRef = collection(db, 'countryList', selectedNation.id, 'embassies-en');
    const q = query(colRef);
    // Fetch data from firestore
    useEffect(() => {  
        const cancelEmbSubscriber = onSnapshot(q, (snapshot) => {
            setEmbassies(snapshot.docs.map((doc) => ({...doc.data(), id: doc.id})));
            setEmbassiesLoading(false);
        })
        return cancelEmbSubscriber;
    }, []);
    
    return (
        <div className='rounded-lg g-shadow bg-backgroundLight'>
            <AdminNationEmbassy embassy={null}  selectedNation={selectedNation} />
            {embassiesLoading && (
                <div className='pb-3'>
                    <Loading bg='light' />
                </div>
            )}
            {embassies && (
                <>
                    {embassies.map(item => (
                        <AdminNationEmbassy embassy={item} selectedNation={selectedNation} key={item.id} />
                    ))}
                </>
            )}
          
        </div>
    );
}
 
export default AdminNationEmbassies;
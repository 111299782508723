import { useContext } from "react";
import { AppContext } from '../../contexts/AppContext';
import OrganisationContact from "./OrganisationContact";

const OrganisationIntro = ({ organisation }) => {
    const { language } = useContext(AppContext);

    const title = ['вступ', 'Introduction', 'Introduction'];

    return (
        <>
            <div className="organisation-intro">
                <h3 className="organisation-section-title">{title[language]}</h3>
                <div className="organisation-intro-text allow_select" dangerouslySetInnerHTML={{ __html: organisation.body[language].replace(/(?:\r\n|\r|\n)/g, '<br>') }}></div>
                <OrganisationContact organisation={organisation} />
            </div>        
        </>


    );
}
 
export default OrganisationIntro;
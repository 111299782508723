import { collection, addDoc, Timestamp } from 'firebase/firestore'
import { db } from '../firebase-config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faCheck } from '@fortawesome/free-solid-svg-icons';
import { useState, useRef } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { useEffect } from 'react';

const AdminToDoNew = ({ showNewTask, setShowNewTask }) => {
    const { currentUser } = useAuth();

    const [ loading, setLoading ] = useState(false);
    const [ success, setSuccess ] = useState(false);

    const taskRef = useRef();

    const toggleTaskModal = () => {
        setShowNewTask(!showNewTask);
    }

    const handlePropogation = (e) => {
        e.stopPropagation();
    }

    const handleTaskSubmit = async (e) => {
        e.preventDefault();
        // Check if loading already 
        if (loading) {
            return false;
        }
        let newData, errors = false;
        // Validate
        if (taskRef.current.value.trim() === '') {
            errors = true;
        }
        if (errors) {
            return false;
        }
        // Set data
        newData = {
            title: taskRef.current.value.trim(),
            complete: false,
            timestamp: Timestamp.now(),
            userEmail: currentUser.email,
            userName: currentUser.displayName ?? ""
        }
        setLoading(true);
        // Submit to firestore
        try {
            await addDoc(collection(db, 'toDo'), newData);
            setLoading(false);
            setSuccess(true);
            setTimeout(() => {
                setSuccess(false);
            }, 1500);
            await addDoc(collection(db, 'userInteraction'), {
                userName: currentUser.displayName ?? '',
                userEmail: currentUser.email,
                type: 'Adding task',
                document: 'toDo',
                timestamp: Timestamp.now(),
                newData: newData,
                visible: true
            });
            setShowNewTask(false);
        } catch (err) {
            setLoading(false);
        }
    }

    // Focus on input on open
    useEffect(()=> {
        document.getElementsByClassName('task-modal-input')[0].focus();
    }, [])

    return (
        <div className="blurred-modal-outer z-50" onClick={toggleTaskModal}>
            <div className="flex flex-col !shrink !grow-0 flex-wrap max-h-full !basis-[600px] bg-white g-shadow rounded-lg overflow-hidden p-3" onClick={handlePropogation}>
                <h1 className="font-bold uppercase border-b-[1px] border-b-[rgba(0,0,0,0.7)] mb-3 tracking-widest basis-full">New Task</h1>
                <form autoComplete="off" action="" onSubmit={handleTaskSubmit} className="flex gap-3 items-center justify-center basis-full" >
                    <input className="task-modal-input" name='task' id='task' type='text' ref={taskRef} />
                    {!loading && !success && (<button className='basis-[60px]' onClick={handleTaskSubmit}>Add</button>)}
                    {loading && (<span className='basis-[60px] flex items-center justify-center'><FontAwesomeIcon className=' text-[20px] animate-spin' icon={faSpinner}/></span>)}
                    {success && (<span className='basis-[60px] flex items-center justify-center'><FontAwesomeIcon className=' text-green-600 text-[20px]' icon={faCheck}/></span>)}
                </form>
            </div>
        </div>
    );
}
 
export default AdminToDoNew;
import { onAuthStateChanged, signInWithEmailAndPassword, signOut, updateProfile, updateEmail, updatePassword, setPersistence, browserSessionPersistence } from "firebase/auth";
import { createContext, useContext, useEffect, useState } from "react"
import { auth } from "../firebase-config";

const AuthContext = createContext()

export function useAuth() {
    return useContext(AuthContext)
}
 
const AuthContextProvider = ( props ) => {
    const [currentUser, setCurrentUser] = useState()
    const [ loading, setLoading ] = useState(true)
    setPersistence(auth, browserSessionPersistence);

    const login = (email, password) => {
        return signInWithEmailAndPassword(auth, email, password)
    }

    const updateName = (name) => {
        return updateProfile(currentUser, {displayName: name})
    }

    const updateUserEmail = (email) => {
        return updateEmail(currentUser, email)
    }

    const updateUserPass = (pass) => {
        return updatePassword(currentUser, pass)
    }

    const logout = () => {
        return signOut(auth)
    }

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            setCurrentUser(current => {
                return user
            });
            setLoading(false);
        })
        return unsubscribe
    }, [])

    const value = {
        currentUser,
        setCurrentUser,
        login,
        updateName,
        updateUserEmail,
        updateUserPass,
        logout
    }

    return (
        <AuthContext.Provider value={value}>
            {!loading && props.children}
        </AuthContext.Provider>   
    )
}

export default AuthContextProvider
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare, faPlus } from '@fortawesome/free-solid-svg-icons';
import AdminNoteForm from './AdminNoteForm';

const AdminNote = ({ note, selectedNote, setSelectedNote  }) => {

    const toggleOpenNote = () => {
        setSelectedNote(selectedNote === note.id ? null : note.id);
    }
  
    return (
        <div className={`cursor-pointer p-3 flex flex-wrap justify-start items-center gap-6 border-b-[1px] border-rgba(0,0,0,0.1) last:border-b-0`} onClick={toggleOpenNote}>
            {note.id === 'new' && (
                <>
                    <FontAwesomeIcon className='' icon={faPlus} />
                    <span className='flex-1'>Add Note</span>
                </>
            )}
            {note.id !== 'new' && (
                <>
                    <FontAwesomeIcon className='' icon={faPenToSquare} />
                    <span className='flex-1 capitalize'>{note.title}</span>
                </>
            )}
            {selectedNote === note.id && (
                <AdminNoteForm note={note} setSelectedNote={setSelectedNote} />
            )}
        </div>
    );
}
 
export default AdminNote;
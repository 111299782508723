import { useEffect } from 'react';
import { useState } from 'react';
import Loading from '../components/Loading';
import AdminMessage from './AdminMessage';
import MessageModal from './MessageModal';

const AdminMessages = ({messages, loading, type }) => {
    const [ messagesByType, setMessagesByType ] = useState([]);
    const [ selectedMessage, setSelectedMessage ] = useState(null);

    useEffect(() => {
        const filteredMessages = messages.filter(item => (item.type === type));
        setMessagesByType([...filteredMessages]);
    }, [messages]);

    return (
        <>
            <div className='rounded-lg g-shadow bg-white overflow-hidden'>
                {loading && (
                    <div className='pb-3'>
                        <Loading bg='light' />
                    </div>
                )}
                {!loading && messagesByType.length > 0 && (
                    <>
                        <div className="flex border-b-[1px] border-b-[rgba(0,0,0,0.3)] last:border-b-0">
                            <span className={`border-l-[5px] border-l-transparent p-3 border-r-[1px] border-r-[rgba(0,0,0,0.1)] basis-[120px] font-bold uppercase bg-[rgba(0,0,0,0.1)]`}>Date</span>
                            <span className="flex-1 truncate p-3 font-bold uppercase bg-[rgba(0,0,0,0.1)]">{(type === 0 || type === 1) ? "From" : "Message"}</span>    
                        </div>
                        {messagesByType.map(item => (
                            <AdminMessage key={item.id} message={item} setSelectedMessage={setSelectedMessage} />
                        ))}
                    </>
                )}
                {!loading && messagesByType.length === 0 && (
                    <AdminMessage message={null} />
                )}
            </div>
            {selectedMessage && (
                <MessageModal selectedMessage={selectedMessage} setSelectedMessage={setSelectedMessage} />
            )}
        </>
    );
}
 
export default AdminMessages;
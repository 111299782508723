import { motion } from 'framer-motion';

const containerVariants =  {hidden: {},visible: {transition:{when:'beforeChildren',staggerChildren:0.15}}};
const barVariants =  {hidden: {scale:0.2},visible: { scale:1, transition: {repeat: Infinity, repeatType: 'mirror',  duration:1}}};

const Loading = ({ bg }) => {

    return (
        <motion.div className='loading-container' variants={containerVariants} initial='hidden' animate='visible'>
            <motion.div className={`${bg === 'light' ? 'bg-backgroundGray' : 'bg-lightColor'}`} variants={barVariants}></motion.div>
            <motion.div className={`${bg === 'light' ? 'bg-backgroundGray' : 'bg-lightColor'}`} variants={barVariants}></motion.div>
            <motion.div className={`${bg === 'light' ? 'bg-backgroundGray' : 'bg-lightColor'}`} variants={barVariants}></motion.div>
            <motion.div className={`${bg === 'light' ? 'bg-backgroundGray' : 'bg-lightColor'}`} variants={barVariants}></motion.div>
            <motion.div className={`${bg === 'light' ? 'bg-backgroundGray' : 'bg-lightColor'}`} variants={barVariants}></motion.div>
        </motion.div>
    );
}
 
export default Loading;
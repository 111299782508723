import { useState, useContext, useEffect } from "react";
import Loading from "../Loading";
import { AppContext } from '../../contexts/AppContext';
import { collection, onSnapshot, query, where } from 'firebase/firestore';
import { db } from '../../firebase-config';
import NationEmbassyCard from './CountryEmbassyCard';

const CountryEmbassyList = ({ nation }) => {
    const [ nationDetails, setNationDetails ] = useState([]);
    const [ loading, setLoading ] = useState(true);
    const { language } = useContext(AppContext);
    // Multilanguage Text
    const embassyList = ['список посольств', 'Liste des ambassades', 'Embassy List'];
    const fullList = [`Повний список посольств і консульств ${nation.name[language]}`, `Pour une liste complète des ambassades et consulats`, `For a full list of the embassies and consulates of ${nation.name[language]}`];
    const click = ['натисніть тут', 'cliquez ici', 'click here'];
    // Firestore Query
    const colRef = collection(db, 'countryList', nation.id, 'embassies-en');
    const q = query(colRef, where('visible', '==', true ));
    // Fetch data from firestore
    useEffect(() => {  
        const cancelEmbSubscriber = onSnapshot(q, (snapshot) => {
            setNationDetails(snapshot.docs.map((doc) => ({...doc.data(), id: doc.id})));
            setLoading(false);
        }, error => {
            setLoading(false);
        })
        return cancelEmbSubscriber;
    },[nation]);

    return (
        <>
            {(loading || nationDetails.length > 0) && (
                <div className="">
                    <h3 className="country-section-title">{embassyList[language]}</h3>
                    {nationDetails.length < 1 && (
                        <Loading bg="dark" />
                    )}
                    {nationDetails.length > 0 && (
                        <>
                            <div className="country-embassy-list">
                                {/* Show Ukrainian embassy first */}
                                {nationDetails.filter(filterItem => (filterItem.name[2].toLowerCase().includes('ukrainian'))).map(item => (
                                    <NationEmbassyCard key={item.id} item={item} />
                                ))}
                                {/* Show other embassies */}
                                {nationDetails.filter(filterItem => (!filterItem.name[2].toLowerCase().includes('ukrainian'))).map(item => (                            
                                    <NationEmbassyCard key={item.id} item={item} />
                                ))}
                        
                            </div>
                            {/* Show official embassy list */}
                            {nation.list && (
                                <div className="mt-[15px]">
                                    <span className="country-text">{fullList[language]} <a href={nation.list} target='_blank' rel="noreferrer"> {click[language]}</a></span>
                                </div>
                            )}
                        </>
           
                    )}
                </div>
            )}
        </>
    );
}
 
export default CountryEmbassyList;

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClipboardQuestion, faPeopleGroup, faInfo } from '@fortawesome/free-solid-svg-icons';
const AdminMessagesTypeCard = ({ setDashPage, messageType, count }) => {
    
    return (
        <div className='h-[44px] grow shrink basis-full break900:basis-[260px] rounded-lg g-shadow flex justify-start items-center overflow-hidden cursor-pointer' onClick={() => setDashPage(messageType)}>
            <div className={`basis-[40px] bg-red-400 h-full flex items-center justify-center ${messageType === 5 ? '!bg-[rgba(255,30,0,0.4)] textured' : messageType === 6 ? '!bg-[rgba(0,30,215,0.4)] textured' : messageType === 7 ? '!bg-[rgba(30,235,20,0.4)] textured' : ''}`}>
                <FontAwesomeIcon className="text-[22px]" icon={messageType === 5 ? faClipboardQuestion : messageType === 6 ? faPeopleGroup : faInfo} />
            </div>
            <div className="p-3 flex-1">
                { messageType === 5 ? 'Enquiries' : messageType === 6 ? 'Applications' : 'Requests' } ({ count })
            </div>
        </div>
    );
}
 
export default AdminMessagesTypeCard;
import { useContext } from 'react';
import { AppContext } from '../../contexts/AppContext';
import OrganisationServicesItem from './OrganisationServicesItem';

const OrganisationServices = ({ organisation, services }) => {
    const { language } = useContext(AppContext);
    const title = ['Послуги', 'Prestations de service', 'Services'];

    return (
        <div className="organsation-services">
            <h3 className="organisation-inner-title">{title[language]}</h3>
            <div>
                {organisation.services.map(item => (
                    <OrganisationServicesItem key={item} id={item} services={services} />
                ))}
            </div>        
        </div>
    );
}
 
export default OrganisationServices;
import { useEffect, useState } from 'react';
import { motion } from "framer-motion";
import Loading from '../Loading';
import OrganisationCard from './OrganisationCard';


const OrganisationsPage = ({ organisations }) => {
    const variants = {hidden: {x:1000},visible: {x:0},exit: {x:"-100vw"}};
    const [ sortedOrganisations, setSortedOrganisations ] = useState([]);

    // Sort organisations AZ 
    useEffect(() => {
        let sortData = [];
        organisations.forEach((item) => {
            sortData.push(item);
        })
        // Sort data
        sortData.sort(function (x, y) {
            let a = x.title.toUpperCase(),
                b = y.title.toUpperCase();
            return a === b ? 0 : a > b ? 1 : -1;
        })
        setSortedOrganisations([...sortData]);
    }, [organisations]);

    return (
        <motion.div
            variants={variants}
            initial="hidden"
            animate="visible"
            exit="exit"
            className="page-container"
        >
            <div>
                {/* <h1 className="page-title">{ sectionTitle[language] }</h1> */}
                <div className='page-list'>
                    {sortedOrganisations.length < 1 && (
                        <Loading bg="dark" />
                    )}
                    {sortedOrganisations.length > 0 && (
                        <div className='grid gap-[20px] break650:gap-[30px] grid-cols-1 break650:grid-cols-2 break1000:grid-cols-3'>
                            {sortedOrganisations.map(item => (
                                <OrganisationCard key={item.id} organisation={item} />
                            ))}
                        </div>
                    )}
                </div>
            </div>
        </motion.div>
    );
}
 
export default OrganisationsPage;
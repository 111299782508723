import { useAdmin } from '../contexts/AdminContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

const AdminDashHomeCard = ({ bgColor, icon, loading, count, title, targetDashPage }) => {
    const { chooseNewDashPage } = useAdmin();

    return (
        <div className='bg-white g-shadow rounded-lg cursor-pointer flex-1 flex flex-col justify-center items-center p-6 basis-[200px]' onClick={() => {chooseNewDashPage(targetDashPage)}}>
            <div className={`h-[100px] w-[100px] rounded-full ${bgColor} mb-3 flex items-center justify-center`}>
                <FontAwesomeIcon className='text-[55px]' icon={icon} />
            </div>
            <span className='font-bold text-[30px] leading-[100%]'>
                {!loading && count}
                {loading && <FontAwesomeIcon className='text-[18px] mb-[2px] animate-spin' icon={faSpinner}/>}
            </span>
            <h3 className='text-[14px] opacity-50 uppercase'>{title}</h3>
        </div>
    );
}
 
export default AdminDashHomeCard;
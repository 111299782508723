import { useContext } from "react";
import { AppContext } from "../contexts/AppContext";

import { AnimatePresence, motion } from "framer-motion";
import { Link } from "react-router-dom";

const variants = {
  hidden: { x: 0 },
  visible: { x: 0, transition: { staggerChildren: 0.2 } },
  exit: { x: -10000, margin: 0 },
};
const logoVariants = {
  hidden: { x: -100 },
  visible: { x: 0 },
  exit: { height: 0 },
};
const textVariants = {
  hidden: { x: "-50vw", opacity: 0 },
  visible: { x: 0, opacity: 1 },
  exit: { height: 0, opacity: 0 },
};

const Logo = () => {
  const { language } = useContext(AppContext);
  const header1 = ["сильніші", "plus forts", "Stronger"];
  const header2 = ["разом", "ensemble", "Together"];

  return (
    <>
      <AnimatePresence>
        <motion.div
          variants={variants}
          initial="hidden"
          animate="visible"
          exit="exit"
        >
          <Link to={"/"}>
            <div className="logo-container">
              <motion.img
                variants={logoVariants}
                src="/images/logo-white-yellow.png"
                alt="Ukraine Logo"
              />
              <div className="pt-[4px]">
                <motion.span variants={textVariants}>
                  {header1[language]}
                </motion.span>
                <motion.span variants={textVariants}>
                  {header2[language]}
                </motion.span>
              </div>
            </div>
          </Link>
        </motion.div>
      </AnimatePresence>
    </>
  );
};

export default Logo;

import { useContext } from 'react';
import { AppContext } from '../contexts/AppContext';
import { motion, AnimatePresence } from "framer-motion";
import { useState } from 'react';

const langsVariants = {
    hidden: {x:100, height: "44px"},
    visible: {x:0, height: "44px"},
    active: {x:0, height:"116px", transition: {when: 'beforeChildren'}}
};

const langVariants = {
    hidden: {y:0, height: 0},
    visible: {y:0, height: "24px", transition: {duration: .2}},
    active: {y:0, height: "24px", transition: {duration: .2}}
};

const Language = () => {
    const { language, chooseLanguage } = useContext(AppContext);
    const [ active, setActive ] = useState(false);
    const languageOptions = [
        {option: 0, flag: 'ua'},
        {option: 1, flag: 'fr'},
        {option: 2, flag: 'gb'}
    ];

    const handleActive = (e) => {
        e.stopPropagation();
        let nowState = !active;
        setActive(nowState);
        if (nowState) {
            window.addEventListener("click", ()=>setActive(false));
        }
    }

    const handleLanguageSelection = (x) => {
        // Check local storage
        const localLanguage = localStorage.getItem("language");
        // Change and set if set languange or localstorage do not match desired language
        if (language !== x || localLanguage !== x ||  localLanguage === null) {
            chooseLanguage(x);
            localStorage.setItem("language", x);
        }
    }

    return (
        <motion.div
            variants={langsVariants}
            initial='hidden'
            animate={active ? 'active' : 'visible'}
            onClick={handleActive}
            className="language-selector"
        >
            <AnimatePresence>
                {languageOptions.map(item => (
                    (language === item.option || active) && (
                        <motion.div 
                            key={item.option}
                            variants={langVariants}
                            onClick={()=> handleLanguageSelection(item.option)}
                        >
                            <div
                                style={{
                                    backgroundImage: `url(/images/flags/${item.flag}.png)`
                                }}
                            ></div>
                        </motion.div>
                    )
                ))}
            </AnimatePresence>
        </motion.div>
    );
}
 
export default Language;
import { useState, useContext } from 'react';
import { AppContext } from '../../contexts/AppContext';
import { useRef } from 'react';
import ContactFormField from './ContactFormField';
import ContactFormLoading from './ContactFormLoading';
import ContactFormSuccess from './ContactFormSuccess';
import { validateEmail } from '../../utils/validation';
import { collection, addDoc, Timestamp } from 'firebase/firestore'
import { db } from '../../firebase-config';


const ContactFormJoin = ({ setContactType }) => {
    const { language } = useContext(AppContext);

    const nameRef = useRef();
    const emailRef = useRef();
    const messageRef = useRef();

    const [ loading, setLoading ] = useState(false);
    const [ success, setSuccess ] = useState(false);
    const [ nameErr, setNameErr ] = useState(false);
    const [ emailErr, setEmailErr ] = useState(false);
    const [ messageErr, setMessageErr ] = useState(false);

    const message = [
        'Бажаєте приєднатися до нашої команди волонтерів? Кожен має навички, які можуть бути корисними. Розкажіть нам про свій минулий досвід, мотивацію та те, чим, на вашу думку, ви зможете допомогти.',
        'Intéressé à rejoindre notre équipe de bénévoles? Tout le monde a des compétences qui peuvent être utiles. Faites-nous part de votre expérience passée, de vos motivations et de la manière dont vous pensez pouvoir nous aider.',
        'Interested in joining our team of volunteers? Everyone has skills that can be of help. Let us know about your past experience, motivations and how you think you\'ll be able to help.'
    ];

    const message2 = [
        ', щоб отримати додаткову інформацію про деякі позиції, які зараз доступні.',
        ' pour plus d\'informations sur certains des postes actuellement disponibles',
        ' for more information on some of the positions currently available.'
    ];

    const clickHere = [
        'Клацніть тут',
        'Cliquez ici',
        'Click here'
    ]

    const emailTip = [
        'Укажіть дійсну електронну адресу, щоб ми могли відповісти на вашу заявку.',
        'Indiquez une adresse e-mail valide afin que nous puissions répondre à votre candidature.',
        'Provide a valid email address so we can reply to your application.'
    ];

    const nameTitle = ['Ваше ім\'я', 'votre nom', 'Your Name'];
    const emailTitle = ['Ваш емейл', 'Votre e-mail', 'Your Email'];
    const messageTitle = ['повідомлення', 'Message', 'Message'];
    const cancelTitle = ['Скасувати', 'Annuler', 'Cancel'];
    const submitTitle = ['відправити', 'envoyer', 'Submit'];

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        // Cancel if message sending
        if (loading) {
            return false
        }
        // Reset errors
        setNameErr(false);
        setEmailErr(false);
        setMessageErr(false);
        let errors = false;
        // Validate fields
        if (nameRef.current.value === '') {
            errors = true;
            setNameErr(true);
        }
        if (emailRef.current.value === '' || !validateEmail(emailRef.current.value)) {
            errors = true;
            setEmailErr(true);
        }
        if (messageRef.current.value === '') {
            errors = true;
            setMessageErr(true);
        }
        if (errors) {
            return false;
        }
        setLoading(true);
        // Loading and submit
        const data = {
            name: nameRef.current.value.trim(),
            email: emailRef.current.value.toLowerCase(),
            message: messageRef.current.value,
            type: 1,
            timestamp: Timestamp.now()
        }
        try {
            await addDoc(collection(db, 'messages'), data);
            setSuccess(true);
            setLoading(false);
         } catch (err) {
            setLoading(false);
            setSuccess(false);
         }
    }

    const handleFormCancel = (e) => {
        e.preventDefault();
        setContactType(null);
    }

    return (
        <div>
            {!loading && success && <ContactFormSuccess />}
            {loading && !success && <ContactFormLoading />}
            {!success && !loading && (
                <>
                    <p>{ message[language] }</p>
                    <p><a href='https://drive.google.com/drive/folders/12NBHlE41XNGCrkCwI15mABlb4C2tB-f9?usp=sharing&fbclid=IwAR1CqZXJLy-8INiacPfMVniw5CN9tDod8vbo6XyVpT8d764v-2tylMpQ7aM&pli=1' target='_blank' rel='noreferrer'>{ clickHere[language] }</a>{ message2[language] }</p>
                    <form action='' onSubmit={handleFormSubmit} autoComplete='off' >
                        <div>
                            <ContactFormField title={nameTitle[language]} formId={'name'} formRef={nameRef} error={nameErr} isInput={true} hasTip={false} />
                            <ContactFormField title={emailTitle[language]} formId={'email'} formRef={emailRef} error={emailErr} isInput={true} hasTip={true} tipText={emailTip[language]} />
                            <ContactFormField title={messageTitle[language]} formId={'message'} formRef={messageRef} error={messageErr} isInput={false} hasTip={false} />
                            <div className='contact-submit-container'>
                                <span onClick={handleFormCancel} >{ cancelTitle[language] }</span>
                                <button type='submit' >{ submitTitle[language] }</button>
                            </div>
                        </div>
                    </form>
                </>
            )}
        </div>
    );
}
 
export default ContactFormJoin;
import { useContext } from "react";
import { AppContext } from '../../contexts/AppContext';

const CountryEmbassyCard = ({ item }) => {
    const { language } = useContext(AppContext)

    const open = ['ВІДЧИНЕНО', 'OUVERT', 'OPEN']
    const closed = ['ЗАЧИНЕНО', 'FERMÉ', 'CLOSED']
    const address = ['Адреса', 'Adresse', 'Address']
    const telephone = ['Телефон', 'Téléphone', 'Telephone']
    const website = ['вебсайт', 'site Internet', 'Website']

    return (
        <div key={item.id}>
            <div className="country-embassy-header">
                <h4>{item.name[language]}</h4>
                <p><b className={item.open ? ' text-green-500' : 'text-red-500'}>{item.open ? open[language] : closed[language]}</b></p>
            </div>
            <div className="country-embassy-info">
                {/* Address */}
                <div>
                    <p><b>{address[language]}: </b></p>
                    <p className="allow_select">{item.address ? item.address : '-' }</p>
                </div>
                {/* Telephone */}
                <div>
                    <p><b>{telephone[language]}: </b></p>
                    {item.tel && item.tel !== "" && item.tel !== "-" && !item.tel.includes('/') && (
                        <a href={`tel:${item.tel}`} className="allow_select" >{item.tel}</a>
                    )}
                    {item.tel && item.tel.includes('/') && (
                        <p className="allow_select">{item.open ? item.tel : '-'}</p>
                    )}
                    {(!item.tel || item.tel === "" || item.tel === "-") && (
                        <p>{item.tel ? item.tel : '-'}</p>
                    )}
                </div>
                {/* Website */}
                <div>
                    <p><b>{website[language]}: </b></p>
                    {item.website && item.website !== "" && item.website !== "-" && (
                        <a href={item.website} target='_blank' rel="noreferrer" className="allow_select">{item.website}</a>
                    )}
                    {(!item.website || item.website === "" || item.website === "-") && (
                        <p>-</p>
                    )}
                </div>
            </div>
        </div>
    );
}
 
export default CountryEmbassyCard;
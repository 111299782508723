import { useState, useContext, useEffect } from "react";
import { AppContext } from '../../contexts/AppContext';
import CountryOrganisationCard from "./CountryOrganisationCard";

const CountryOrganisations = ({ nation, organisations }) => {
    const { language } = useContext(AppContext);
    const [ countryOrganisations, setCountryOrganisations ] = useState([]);    

    const organisationsList = ["організацій", "Organizations", "Organisations"];

    // Firestore Query    
    useEffect(() => {
        const relatedOrgs = organisations.filter(filterItem => (filterItem.nations && (filterItem.nations.includes(nation.id) || filterItem.nations.includes(nation.name[2].toLowerCase()))));
        setCountryOrganisations([...relatedOrgs]);
    },[nation, organisations])

    return (
        <>
            {countryOrganisations.length > 0 && (
                <div>
                    <h3 className="country-section-title">{organisationsList[language]}</h3>
                    <div className="country-organisation-list">
                        {countryOrganisations.map(item => (
                            <CountryOrganisationCard organisation={item} key={item.id} />
                        ))}
                    </div>     
                </div>
            )}
        </>
    );
}
 
export default CountryOrganisations;
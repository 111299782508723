const OrganisationLogo = ({ organisation }) => {
    return (
        <div className="organisation-logo">
            <div>
                <div>
                    <img src={organisation.logoUrl} alt={organisation.title + ' Logo'} />
                </div>
            </div>
        </div>
    );
}
 
export default OrganisationLogo;
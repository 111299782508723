import AdminContextProvider from "./contexts/AdminContext";
import Login from "./aComponents/Login";
import AdminDashboard from "./aComponents/AdminDashboard";
import { useAuth } from "./contexts/AuthContext";
import { useEffect, useContext } from "react";
import { AppContext } from "./contexts/AppContext";

const Admin = () => {
  const { currentUser } = useAuth();
  const { chooseLanguage } = useContext(AppContext);

  // Set languange to English
  useEffect(() => {
    chooseLanguage(2);
  }, []);

  return (
    <div className="min-h-screen bg-slate-200 admin-page">
      {!currentUser && <Login />}
      {currentUser && (
        <AdminContextProvider>
          <AdminDashboard />
        </AdminContextProvider>
      )}
    </div>
  );
};

export default Admin;

import { collection, doc, addDoc, updateDoc, Timestamp } from 'firebase/firestore'
import { db } from '../firebase-config';

import { useState, useRef } from "react";

const AdminAddSelection = ({ selectionCollection, toggleClose, editSelectionItem, setEditSelectionItem }) => {
    const ukrRef = useRef();
    const frRef = useRef();
    const enRef = useRef();

    const [ ukrErr, setUkrErr ] = useState(false);
    const [ frErr, setFrErr ] = useState(false);
    const [ enErr, setEnErr ] = useState(false);
    const [ duplicateErr, setDuplicateErr ] = useState(false);
    const [ loading, setLoading ] = useState(false);

    const validate = () => {
        setUkrErr(false);
        setFrErr(false);
        setEnErr(false);
        let errors = false;
        // Validate
        if (ukrRef.current.value.trim() === '') {
            errors = true;
            setUkrErr(true);
        }
        if (frRef.current.value.trim() === '') {
            errors = true;
            setFrErr(true);
        }
        if (enRef.current.value.trim() === '') {
            errors = true;
            setEnErr(true);
        }
        if(errors) {
            return false;
        } else {
            return true;
        }
    }

    const checkUnique = () => {
        setDuplicateErr(false);
        // Check if in collection already
        const foundItem = selectionCollection.find(item => (
                item.name[0].toLowerCase().trim() === ukrRef.current.value.toLowerCase().trim() || item.name[1].toLowerCase().trim() === frRef.current.value.toLowerCase().trim() || item.name[2].toLowerCase().trim() === enRef.current.value.toLowerCase().trim()
            ));
        if (foundItem) {
            // Item found already - allow if duplicate is item being edited
            if (editSelectionItem !== null) {
                if (foundItem.id !== editSelectionItem.id) {
                    setDuplicateErr(true);
                    return false;       
                }
            } else {
                setDuplicateErr(true);
                return false;
            }
        }
        return true
    }

    const handleAddSelection = async (e) => {
        e.preventDefault();
        if (loading) {
            return false;
        }
        const valid = validate();
        const isUnique = checkUnique();
        if (!valid || !isUnique) {
            return false;
        }
        // Submit to firestore
        setLoading(true);
        try {
            await addDoc(collection(db, 'services'), {
                name: [ukrRef.current.value.toLowerCase().trim(), frRef.current.value.toLowerCase().trim(), enRef.current.value.toLowerCase().trim()],
                timestamp: Timestamp.now()
            });
            setLoading(false);
            setEditSelectionItem(null);
            toggleClose();
        } catch (err) {
            setLoading(false);
        }
    }

    const handleEditItem = async (e) => {
        e.preventDefault();
        if (loading) {
            return false;
        }
        const valid = validate();
        const isUnique = checkUnique();
        if (!valid || !isUnique) {
            return false;
        }
        // Submit to firestore
        setLoading(true);
        try {
            const docRef = doc(db, 'services', editSelectionItem.id)
            await updateDoc(docRef, {
                name: [ukrRef.current.value.toLowerCase().trim(), frRef.current.value.toLowerCase().trim(), enRef.current.value.toLowerCase().trim()],
                timestamp: Timestamp.now()
            });
            setLoading(false);
            toggleClose();

         } catch (err) {
             setLoading(false);
         }



    }


    return (
        <form action='' className='add-service-form' autoComplete='off' onSubmit={editSelectionItem ? handleEditItem : handleAddSelection}> 
            <div className="flex-1 basis-full flex flex-wrap gap-3 rounded-lg g-shadow bg-[rgba(0,0,0,0.05)] p-3">
                <h1>{editSelectionItem !== null ? 'Edit Service' : 'Add Service'}</h1>
                <input type="text" ref={ukrRef} placeholder="Ukrainian" className={`${ukrErr || duplicateErr ? 'errors' : ''}`} defaultValue={editSelectionItem && editSelectionItem.name && editSelectionItem.name[0] } />
                <input type="text" ref={frRef} placeholder="French" className={`${frErr || duplicateErr ? 'errors' : ''}`} defaultValue={editSelectionItem && editSelectionItem.name && editSelectionItem.name[1] } />
                <input type="text" ref={enRef} placeholder="English" className={`${enErr || duplicateErr ? 'errors' : ''}`} defaultValue={editSelectionItem && editSelectionItem.name && editSelectionItem.name[2] } />
                {duplicateErr && (
                    <p className="text-red-600 my-2">Service already exists</p>
                )}
                <div className="basis-full flex justify-center items-center mb-2 gap-2">
                    <span className='flex-1 g-shadow rounded-lg p-3 bg-blue-400 !text-white flex justify-center items-center cursor-pointer' onClick={toggleClose}>Cancel</span>
                    <button type="submit" className='flex-1 g-shadow rounded-lg p-3 bg-blue-400 !text-white flex justify-center items-center cursor-pointer'>Submit</button>
                </div>
            </div>
        </form>
    );
}
 
export default AdminAddSelection;
import {Routes, Route, Navigate} from 'react-router-dom';
import Public from './Public';
import Admin from './Admin';

const Routing = () => {
    return (
        <>
            <Routes>
                {/* Admin site */}
                <Route path='/admin' element={<Admin />} />
                {/* Public site */}
                <Route path='/*' element={<Public />} />
                {/* Unrecoginesed route - redirect to home */}
                <Route path="*" element={<Navigate replace to="/" />} />
            </Routes>
        </>
    );
}
 
export default Routing;
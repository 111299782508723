import { useState, useEffect, useContext } from 'react';
import { AppContext } from '../contexts/AppContext';

const SelectionItem = ({ id, type, collection, admin }) => {
    const { language } = useContext(AppContext);

    const [ selectionItem, setSelectionItem ] = useState(false);
    const [ notFound, setNotFound ] = useState(false);

    // Get item name
    useEffect(() => {
        if (collection.length < 1) {
            // Collection still empty - wait until data received
            return;
        } else {
            // !! accepting name and id for now to be back compatible - remove once data restored
            const foundItem = collection.find(item => (item.id === id || item.name[2].toLowerCase() === id));
            if (foundItem) {
                setNotFound(false);
                setSelectionItem({...foundItem});
            } else {
                if (id === 'ukraine') {
                    setNotFound(false);
                    setSelectionItem({name: ['Україна', 'Ukraine', 'Ukraine']});
                } else {
                    setNotFound(true);  
                }
            }
        }
    }, [collection]);

    return (
        <>
            {!notFound && selectionItem && (
                <span className={`g-shadow bg-white rounded-lg p-2 ${selectionItem.name[2] === '2slgbtqia+' ? 'uppercase' : 'capitalize' }`}>{(type === 'services' || type === 'nations') ? selectionItem.name[admin ? 2 : language] : '' }</span>
            )}
        </>
    );
}
 
export default SelectionItem;
const SandwichMenu = ({ setNavOpen }) => {
    
    const handleOpenNav = () => {
        setNavOpen(true);
    }

    return (
        <div
            onClick={handleOpenNav}
            className="sandwich-container"
        >
            <div>
                <span></span>
                <span></span>
                <span></span>
            </div>
        </div>
    );
}
 
export default SandwichMenu;
import { useContext } from 'react';
import { AppContext } from '../../contexts/AppContext';

const AboutText = () => {
    const { language } = useContext(AppContext);
    const title1 = ['наша', 'Notre', 'Our'];
    const title2 = ['історія', 'histoire', 'History'];
    const missionText = [
        {
            id: 0,
            text: [
                'Stronger Together це ініціатива громадян, яка полегшує доступ до інформації про міграційний процес для людей, які хочуть втекти від війни в Україні. На сайті є інформація про посольства та візи кожної країни, транспортні знижки, специфічні для біженців з України, а також спеціалізовані імміграційні ресурси в різних приймаючих країнах.',
                'Stronger Together est une initiative citoyenne facilitant l’accès à l’information entourant le processus de migration pour les personnes qui désirent fuir la guerre en Ukraine. Le site comprend des informations sur les ambassades et les visas de chaque pays, des rabais de transport spécifiques aux personnes ukrainiennes déplacées temporairement ainsi que des ressources spécialisées en immigration dans les différents pays d’accueil.',
                'Stronger Together is a citizens\' initiative to facilitate access to information about the migration process for people fleeing the war in Ukraine. The site contains information about embassies and visas of each country, transport discounts, specific for temporarily displaced Ukrainians, as well as specialized immigration resources in different host countries.'
            ]
        },
        {
            id: 1,
            text: [
                'Stronger Together народився у Квебеку Québec (Квебек Québec, Канада Canada), після російського вторгнення в Україну, яке почалося у лютому 2022 року. Julien Paradis, робітник-сусід та студент бакалаврату з орієнтації на Université Laval, бажає полегшити тривалий та болючий пошук варіантів, які доступні біженцям, які незабаром покинуть Україну. Тому він починає створювати веб-сайт для цієї мети зі своїм другом James Martin.',
                'Stronger Together est né à Québec (Québec, Canada) à la suite de l\'invasion russe de l’Ukraine ayant débuté en février 2022. Julien Paradis, intervenant de quartier et étudiant au baccalauréat en orientation à l’Université Laval, souhaite faciliter la recherche longue et pénible des options qui s’offrent aux personnes ukrainiennes déplacées temporairement. Il se lance donc dans la création d’un site web à cette fin avec son ami James Martin.',
                'Stronger Together was born in Quebec, after the Russian invasion of Ukraine, which began in February 2022. Julien Paradis, a neighborhood worker and a student at Laval University, wanted to facilitate an otherwise long and hard search for options available to temporarily displaced Ukrainians. He created a website for this purpose with his friend James Martin.'
            ]
        },
        {
            id: 2,
            text: [
                'У випробуваннях, як і в моменти солідарності, в несправедливостях, як і в мирі, ми завжди сильніші разом.',
                'Dans les épreuves comme dans les moments de solidarité, dans les injustices comme dans la paix, on est toujours plus fort.e.s ensemble.',
                'In hardships as in moments of solidarity, in injustice as in peace, we are always Stronger Together.'
            ]
        }
    ]
    const visionText = [
        {
            id: 0,
            text: [
                'Команда Stronger Together мріє про велике! Дійсно, інформація, зібрана для біженців з України, є лише початком. Ми б хотіли не проводити цю науково-дослідну та програмну роботу в такому складному і невтішному контексті виживання.',
                'L’équipe de Stronger Together rêve en grand! Effectivement, les informations recueillies pour les personnes ukrainiennes déplacées temporairement ne sont que le début. Nous aurions préféré ne pas effectuer ce travail de recherche et de programmation dans un contexte de survie aussi complexe et désolant.',
                'Our dreams are big! Indeed, the information collected for temporarily displaced Ukrainians is just the beginning. We would rather not do this research and programming in such a complex and desolating context of survival.'
            ]
        },
        {
            id: 1,
            text: [
                'Stronger Together хоче допомогти всім людям, які мають переїхати в країну свого проживання або в нову тимчасову або постійну країну, незалежно від країни або причини.',
                'Stronger Together souhaite aider toutes les personnes ayant besoin de se reloger à l’intérieur de leur pays de résidence ou dans un nouveau pays d’accueil temporaire ou permanent, peu importe le pays ou la raison.',
                'Stronger Together wants to help all people who need to move within their country of residence or into a new temporary or permanent host country, regardless of country or reason.'
            ]
        },
        {
            id: 2,
            text: [
                'Біженці особливо вразливі до порушення своїх основних прав. Тому робота Stronger Together настільки важлива: Ніхто не застрахований від війни чи катастрофи. Кожен може опинитися в ситуації вимушеної міграції. Ми гарантуємо, що ці рухи більш ефективні, безпечніші та поважніші за конкретні потреби та бажання кожної групи біженців.',
                'Les personnes ukrainiennes déplacées temporairement forment des groupes particulièrement vulnérables au bafouement de leurs droits fondamentaux. C’est pourquoi le travail de Stronger Together est si important: personne n’est à l’abri d’une guerre ou d’une catastrophe. Tout le monde peut se retrouver en situation de migration forcée. Nous faisons en sorte que ces déplacements soient plus efficaces, plus sécuritaires et plus respectueux des besoins et des désirs spécifiques de chaque groupe de personnes ukrainiennes déplacées temporairement.',
                'Temporarily displaced Ukrainians are particularly vulnerable to violations of their fundamental rights. That is why the work of the Stronger Together is so important: We never know who will have to leave or for how long, but we will do everything in our power to make these migrations safer and more effective and respectful of the specific needs and desires of each group of temporarily displaced Ukrainians.'
            ]
        }
    ]
    return (
        <>
            <div className="about-text">
                <div className="about-text-header">
                    <h1>{ title1[language] }</h1>
                    <h1>{ title2[language] }</h1>
                    <span></span>
                </div>
                <div className='about-desc'>
                    <div>
                        {missionText.map(item => (<p key={item.id}>{item.text[language]}</p>))}
                    </div>
                    <div>
                        {visionText.map(item => (<p key={item.id}>{item.text[language]}</p>))}
                    </div>
                </div>
            </div>
        </>

    );
}
 
export default AboutText;
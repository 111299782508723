import { useContext } from "react";
import { AppContext } from "../../contexts/AppContext";
import AboutTeamCard from "./AboutTeamCard";

const AboutTeam = () => {
  const { language } = useContext(AppContext);
  const teamTitle1 = ["наша", "Notre", "Our"];
  const teamTitle2 = ["команда", "équipe", "Team"];
  const teamMembers = [
    {
      id: "1",
      name: "Julien Paradis",
      position: ["Співзасновник", "Co Fondateur", "Co-Founder"],
      imgUrl: "julien.jpeg",
    },
    {
      id: "2",
      name: "James Martin",
      position: [
        "Програміст, Співзасновник",
        "Développeur, Co Fondateur",
        "Developer, Co-Founder",
      ],
      imgUrl: "james.jpg",
    },
    {
      id: "3",
      name: "Adélie Audet",
      position: [
        "Дослідник і Перекладач",
        "Recherchiste et Traductrice",
        "Researcher and Translator",
      ],
      imgUrl: "adelie.jpg",
    },
    {
      id: "4",
      name: "Bélinda Bélice",
      position: ["Дослідник", "Recherchiste", "Researcher"],
      imgUrl: "belinda.jpg",
    },
    {
      id: "5",
      name: "Émie Duquet",
      position: ["Спілкування", "Communication", "Communication"],
      imgUrl: "emie.jpg",
      linkedin: "https://www.linkedin.com/in/emie-duquet/",
    },
  ];

  return (
    <div className="about-team">
      <div></div>
      <div>
        <div className="about-text-header">
          <h1>{teamTitle1[language]}</h1>
          <h1>{teamTitle2[language]}</h1>
          <span></span>
        </div>
        <div className="about-team-list">
          {teamMembers.map((item, index) => (
            <AboutTeamCard key={item.id} teamMember={item} index={index} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default AboutTeam;

import { useContext } from "react";
import { AppContext } from "../contexts/AppContext";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { logEvent, getAnalytics } from "firebase/analytics";

const NavReset = ({ setSearchActive }) => {
  const { pathname } = useLocation();
  const analytics = getAnalytics();
  const { language } = useContext(AppContext);

  useEffect(() => {
    // Send analytics
    let pageName;
    let chosenLanguage =
      language === 0 ? "Ukrainian" : language === 1 ? "French" : "English";
    switch (pathname) {
      case "/":
        pageName = "Home";
        break;
      case "/about":
        pageName = "About Us";
        break;
      case "/getinvolved":
        pageName = "Get Involved";
        break;
      case "/countries":
        pageName = "Countries";
        break;
      case "/organisations":
        pageName = "Organisations";
        break;
      case "/contact":
        pageName = "Contact";
        break;

      default:
        if (pathname.includes("/countries/")) {
          pageName = pathname
            .replace("/countries/", "")
            .replace("_", " ")
            .toUpperCase();
        } else if (pathname.includes("/organisations/")) {
          pageName = pathname
            .replace("/organisations/", "")
            .replace("_", " ")
            .toUpperCase();
        } else {
          pageName = pathname;
        }
        break;
    }
    logEvent(analytics, "page_view", {
      page_title: pageName,
      language_selection: chosenLanguage,
    });
    // Ensure search closed
    setSearchActive(false);
    // Scroll to top
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
  }, [pathname]);

  return null;
};

export default NavReset;

import { useContext } from "react";
import { AppContext } from "../../contexts/AppContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEarthEurope, faHotel } from "@fortawesome/free-solid-svg-icons";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";

const Hero = ({ countryCount, orgsCount }) => {
  const { language } = useContext(AppContext);
  const [animationPosition, setAnimationPosition] = useState(0);
  const [countryCounter, setCountryCounter] = useState(0);
  const navigate = useNavigate();

  const countriesTitle = ["країни", "Pays", "Countries"];
  const countriesText = [
    "Інформація про певну країну про вимоги та процедури в’їзду для українців, які шукають безпеки, контактні дані посольства, допомогу у подорожах тощо.",
    "Informations spécifiques à chaque pays sur les conditions d'entrée et les procédures pour les Ukrainiennes et Ukrainiens en quête de sécurité, les coordonnées de l'ambassade, l'assistance voyage et plus encore.",
    "Country specific information about entry requirements and procedures for Ukrainians seeking safety, embassy contact details, travel assistance and more.",
  ];
  const orgsTitle = ["організації", "Organisations", "Organisations"];
  const orgsText = [
    "Знайдіть контактну інформацію, надані послуги та місцезнаходження організацій, які допомагають людям, які постраждали від вторгнення Росії в Україну.",
    "Trouvez les coordonnées, les services fournis et les lieux d'opération des organisations qui aident les personnes touchées par l'invasion de l'Ukraine par la Russie.",
    "Find contact information, services provided and operation locations for organisations helping people who have been affected by Russia's invasion of Ukraine.",
  ];

  const containerVariants = {
    hidden: { z: 0, x: 0, y: 0 },
    pos1: { z: 0, x: 0, y: 0, transition: { delayChildren: 0.2 } },
    pos2: { z: 0, x: 0, y: 0, transition: { delayChildren: 0.2 } },
  };

  const ring1Variants = {
    hidden: { rotate: "0deg", x: "-50%", y: "-50%", z: 0 },
    animate: {
      rotate: "360deg",
      x: "-50%",
      y: "-50%",
      z: 0,
      transition: { repeat: Infinity, duration: 120 },
    },
  };

  const ring2Variants = {
    hidden: { rotate: "200deg", x: "-50%", y: "-50%", z: 0 },
    pos1: {
      rotate: "0deg",
      x: "-50%",
      y: "-50%",
      z: 0,
      transition: { type: "tween", duration: 0.6 },
    },
    pos2: {
      rotate: "200deg",
      x: "-50%",
      y: "-50%",
      z: 0,
      transition: { type: "tween", duration: 0.6 },
    },
  };

  const countryContainerVariants = {
    hidden: { opacity: 1, rotate: "120deg", originY: 1, originX: 0.5 },
    pos1: {
      opacity: 1,
      rotate: "-40deg",
      originY: 1,
      originX: 0.5,
      transition: { type: "tween", duration: 0.3 },
    },
    pos2: {
      opacity: 1,
      rotate: "120deg",
      originY: 1,
      originX: 0.5,
      transition: { type: "tween", duration: 0.3, delay: 0.3 },
    },
  };

  const countryInnerVariants = {
    hidden: { backgroundColor: "#52B3F5", rotate: "-120deg", scale: 1 },
    pos1: {
      backgroundColor: "#52B3F5",
      rotate: "40deg",
      scale: 1,
      transition: { type: "tween", duration: 0.3 },
    },
    pos2: {
      backgroundColor: "#E4AE3C",
      rotate: "-120deg",
      scale: 0.6,
      transition: { type: "tween", duration: 0.3, delay: 0.3 },
    },
  };

  const countryTextVariants = {
    hidden: { opacity: 1 },
    pos1: { opacity: 1, transition: { type: "tween", duration: 0.3 } },
    pos2: {
      opacity: 0,
      transition: { type: "tween", duration: 0.3, delay: 0.3 },
    },
  };

  const countryIconVariants = {
    hidden: { opacity: 0 },
    pos1: { opacity: 0, transition: { type: "tween", duration: 0.3 } },
    pos2: {
      opacity: 1,
      transition: { type: "tween", duration: 0.3, delay: 0.3 },
    },
  };

  const orgsContainerVariants = {
    hidden: { opacity: 1, rotate: "90deg", originY: 0, originX: 0.5 },
    pos1: {
      opacity: 1,
      rotate: "-40deg",
      originY: 0,
      originX: 0.5,
      transition: { type: "tween", duration: 0.3 },
    },
    pos2: {
      opacity: 1,
      rotate: "90deg",
      originY: 0,
      originX: 0.5,
      transition: { type: "tween", duration: 0.3, delay: 0.35 },
    },
  };

  const orgsInnerVariants = {
    hidden: { backgroundColor: "#E4AE3C", rotate: "-90deg", z: 0, scale: 0.6 },
    pos1: {
      backgroundColor: "#E4AE3C",
      rotate: "40deg",
      z: 0,
      scale: 0.6,
      transition: { type: "tween", duration: 0.3 },
    },
    pos2: {
      backgroundColor: "#52B3F5",
      rotate: "-90deg",
      z: 0,
      scale: 1,
      transition: { type: "tween", duration: 0.3, delay: 0.35 },
    },
  };

  const orgsTextVariants = {
    hidden: { opacity: 0, z: 0 },
    pos1: { opacity: 0, z: 0, transition: { type: "tween", duration: 0.3 } },
    pos2: {
      opacity: 1,
      z: 0,
      transition: { type: "tween", duration: 0.3, delay: 0.3 },
    },
  };

  const orgsIconVariants = {
    hidden: { opacity: 1, z: 0 },
    pos1: { opacity: 1, z: 0, transition: { type: "tween", duration: 0.3 } },
    pos2: {
      opacity: 0,
      z: 0,
      transition: { type: "tween", duration: 0.3, delay: 0.3 },
    },
  };

  const handleChangePosition = (x) => {
    if (animationPosition === x) {
      // Redirect to page
      navigate(x === 0 ? "/countries" : "/organisations");
      return false;
    } else {
      setAnimationPosition(x);
    }
  };

  // Countries Countup
  const requestRef = useRef();
  const previousTimeRef = useRef();

  const animate = (time) => {
    if (previousTimeRef.current !== undefined) {
      setCountryCounter((count) => {
        if (count < countryCount) {
          return count + 1;
        } else {
          cancelAnimationFrame(requestRef.current);
          return count;
        }
      });
    }
    previousTimeRef.current = time;
    if (countryCounter < countryCount) {
      requestRef.current = requestAnimationFrame(animate);
    }
  };

  useEffect(() => {
    let timeout = setTimeout(() => {
      requestRef.current = requestAnimationFrame(animate);
    }, 600);
    return () => timeout;
  }, [countryCount]);

  // Animate graphic
  useEffect(() => {
    const intervalTime = 10000;
    let interval = setInterval(() => {
      handleChangePosition(animationPosition === 0 ? 1 : 0);
    }, intervalTime);
    return () => clearInterval(interval);
  }, [animationPosition]);

  return (
    <div className="relative landscape:min-h-[630px] landscape:h-[calc(100vh-133px)] landscape:break360:h-[calc(100vh-144px)] landscape:break400:h-[calc(100vh-151px)] landscape:break650:h-[calc(100vh-159px)] landscape:break850:h-[calc(100vh-189px)] w-full flex flex-row-reverse items-start landscape:items-center justify-center flex-wrap overflow-hidden">
      {/* Graphic Side */}
      <div className="basis-full landscape:grow landscape:shrink landscape:basis-0 landscape:h-full flex justify-center items-center content-center pt-[30px] landscape:pt-0">
        {/* Sizing element */}
        <motion.div
          className="relative h-0 w-full pt-[100%] portrait:break650:w-[500px] portrait:break650:pt-[500px] landscape:w-full landscape:pt-[100%]"
          variants={containerVariants}
          initial="hidden"
          animate={animationPosition === 0 ? "pos1" : "pos2"}
        >
          {/* Circles */}
          <motion.div
            className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 h-[80%] w-[80%] rounded-full border-2 border-dashed border-lightBlue"
            variants={ring1Variants}
            initial="hidden"
            animate="animate"
          ></motion.div>
          <motion.div
            variants={ring2Variants}
            initial="hidden"
            animate={animationPosition === 0 ? "pos1" : "pos2"}
            className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 h-[calc(80%-15px)] w-[calc(80%-15px)] rounded-full border-[7px] border-x-[#1D4163] border-y-transparent opacity-75"
          ></motion.div>
          <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 h-[calc(80%-40px)] w-[calc(80%-40px)] rounded-full border-4 border-lightTransparent opacity-75"></div>
          <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 h-[calc(80%-80px)] w-[calc(80%-80px)] rounded-full border-[1px] border-dashed border-[#303A48] opacity-75 bg-gradient-radial from-lightVTransparent via-backgroundGray to-backgroundGray"></div>
          <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 h-1/3 w-1/3 rounded-full bg-backgroundGray z-[1]"></div>
          {/* Countries Contents */}
          <motion.div
            variants={countryContainerVariants}
            className={`absolute inset-x-0 top-0 h-1/2 w-full`}
          >
            <motion.div
              className="absolute h-[130px] w-[130px] portrait:break650:w-[140px] portrait:break650:h-[140px] landscape:h-[140px] landscape:w-[140px] top-0 left-1/2 -translate-x-1/2 bg-lightBlue border-[10px] border-backgroundGray rounded-full cursor-pointer"
              variants={countryInnerVariants}
              onClick={() => handleChangePosition(0)}
            >
              {/* Text */}
              <motion.div
                className="absolute h-full w-full flex justify-center items-center content-center flex-wrap"
                variants={countryTextVariants}
              >
                <p className="font-bold uppercase text-backgroundGray basis-full text-center text-[30px] portrait:break650:text-[44px] landscape:text-[44px] leading-none">
                  {countryCounter}
                </p>
                <p className="font-bold uppercase text-white basis-full text-center text-[12px] portrait:break650:text-[16px] landscape:text-[16px] pb-[7px] portrait:break650:pb-[15px] landscape:pb-[15px] leading-none">
                  {countriesTitle[language]}
                </p>
              </motion.div>
              {/* Icon */}
              <motion.div
                className="absolute h-full w-full flex justify-center items-center content-center flex-wrap"
                variants={countryIconVariants}
              >
                <FontAwesomeIcon
                  className="text-[54px] portrait:break650:text-[60px] landscape:text-[60px] text-backgroundGray"
                  icon={faEarthEurope}
                />
              </motion.div>
            </motion.div>
          </motion.div>
          {/* Organisations Contents */}
          <motion.div
            variants={orgsContainerVariants}
            className={`absolute inset-x-0 bottom-0 h-1/2 w-full`}
          >
            <motion.div
              className="absolute h-[130px] w-[130px] portrait:break650:h-[170px] portrait:break650:w-[170px] landscape:h-[170px] landscape:w-[170px] top-0 left-0 -translate-x-1/2 bg-lightBlue border-[10px] border-backgroundGray rounded-full flex justify-center items-center content-center flex-wrap cursor-pointer"
              variants={orgsInnerVariants}
              onClick={() => handleChangePosition(1)}
            >
              {/* Text */}
              <motion.div
                className="absolute h-full w-full flex justify-center items-center content-center flex-wrap"
                variants={orgsTextVariants}
              >
                <p className="font-bold uppercase text-backgroundGray basis-full text-center text-[30px] portrait:break650:text-[44px] landscape:text-[44px] leading-none">
                  {orgsCount}
                </p>
                <p className="font-bold uppercase text-white basis-full text-center text-[12px] portrait:break650:text-[16px] landscape:text-[16px] pb-[7px] portrait:break650:pb-[15px] landscape:pb-[15px] leading-none">
                  {orgsTitle[language]}
                </p>
              </motion.div>
              {/* Icon */}
              <motion.div
                className="absolute h-full w-full flex justify-center items-center content-center flex-wrap"
                variants={orgsIconVariants}
              >
                <FontAwesomeIcon
                  className="text-[50px] portrait:break650:text-[60px] landscape:text-[60px] text-backgroundGray"
                  icon={faHotel}
                />
              </motion.div>
            </motion.div>
          </motion.div>
        </motion.div>
      </div>
      {/* Text Side */}
      <div className="basis-full landscape:grow landscape:shrink landscape:basis-0 landscape:h-full flex justify-start items-start landscape:items-center content-center flex-wrap gap-[30px]">
        <div
          className={`relative basis-full break650:basis-0 grow shrink landscape:basis-full p-3 cursor-pointer ${
            animationPosition !== 0
              ? "rounded-lg hover:bg-lightVTransparent"
              : ""
          }`}
          onClick={() => handleChangePosition(0)}
        >
          <h1 className="text-lightColor text-[24px] break650:text-[32px] uppercase font-medium">
            {countriesTitle[language]}
          </h1>
          <p className="text-lightColor opacity-75">
            {countriesText[language]}
          </p>
          {animationPosition === 0 && (
            <motion.span
              layoutId="heroline"
              className="absolute left-0 top-[15px] bottom-0 h-[calc(100%-30px)] w-[5px] bg-lightBlue rounded-[3px]"
            ></motion.span>
          )}
        </div>
        <div
          className={`relative basis-full break650:basis-0 grow shrink landscape:basis-full p-3 cursor-pointer ${
            animationPosition !== 1
              ? "rounded-lg hover:bg-lightVTransparent"
              : ""
          }`}
          onClick={() => handleChangePosition(1)}
        >
          <h1 className="text-lightColor text-[24px] break650:text-[32px] uppercase font-medium">
            {orgsTitle[language]}
          </h1>
          <p className="text-lightColor opacity-75">{orgsText[language]}</p>
          {animationPosition === 1 && (
            <motion.span
              layoutId="heroline"
              className="absolute left-0 top-[15px] bottom-0 h-[calc(100%-30px)] w-[5px] bg-lightBlue rounded-[3px]"
            ></motion.span>
          )}
        </div>
      </div>
    </div>
  );
};

export default Hero;

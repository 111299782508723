import { useState, useContext } from "react";
import { AppContext } from '../../contexts/AppContext';
import { useRef } from "react";
import ContactFormField from "./ContactFormField";
import ContactFormLoading from "./ContactFormLoading";
import ContactFormSuccess from "./ContactFormSuccess";
import { collection, addDoc, Timestamp } from 'firebase/firestore'
import { db } from '../../firebase-config';

const ContactFormRequest = ({ setContactType }) => {
    const { language } = useContext(AppContext);

    const requestRef = useRef();

    const [ requestErr, setRequestErr ] = useState(false);
    const [ loading, setLoading ] = useState(false);
    const [ success, setSuccess ] = useState(false);

    const message = [
        'Повідомте нам, яка інформація вам потрібна, і наша дослідницька група збере необхідні дані та опублікує їх у мережі, щоб усі могли їх використовувати.',
        'Faites-nous savoir de quelles informations vous avez besoin et notre équipe de recherche rassemblera les données et les mettra en ligne pour que tout le monde puisse les utiliser.',
        'Let us know what information you need and our research team will gather the data and get it online for everyone to use.'
    ];

    const requestedTip = [
        'Включіть якомога більше деталей про предмет, який ви хочете, щоб ми дослідили.',
        'Incluez autant de détails que possible sur le sujet que vous souhaitez que nous recherchions.',
        'Include as many details as you can about the subject you want us to research.'
    ];

    const requestedTitle = ['Запит інформації', 'Information demandée', 'Requested Information'];
    const cancelTitle = ['Скасувати', 'Annuler', 'Cancel'];
    const submitTitle = ['відправити', 'envoyer', 'Submit'];

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        // Cancel if message sending
        if (loading) {
            return false
        }
        // Reset errors
        setRequestErr(false);
        let errors = false;
        // Validate fields
        if (requestRef.current.value === '') {
            errors = true;
            setRequestErr(true);
        }
        if (errors) {
            return false;
        }
        setLoading(true);
        // Loading and submit
        const data = {
            request: requestRef.current.value,
            type: 2,
            timestamp: Timestamp.now()
        }
        try {
            await addDoc(collection(db, 'messages'), data);
            setSuccess(true);
            setLoading(false);
         } catch (err) {
            setLoading(false);
            setSuccess(false);
         }
    }

    const handleFormCancel = (e) => {
        e.preventDefault();
        setContactType(null);
    }

    return (
        <div>
            {!loading && success && <ContactFormSuccess />}
            {loading && !success && <ContactFormLoading />}
            {!success && !loading && (
                <>
                    <p>{ message[language] }</p>
                    <form action="" onSubmit={handleFormSubmit} autoComplete='off' >
                        <div>
                            <ContactFormField title={requestedTitle[language]} formId={'requested'} formRef={requestRef} error={requestErr} isInput={false} hasTip={true} tipText={requestedTip[language]} />
                            <div className="contact-submit-container">
                                <span onClick={handleFormCancel} >{ cancelTitle[language] }</span>
                                <button type="submit" >{ submitTitle[language] }</button>
                            </div>
                        </div>
                    </form>
                </>
            )}
        </div>
    );
}
 
export default ContactFormRequest;
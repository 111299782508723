import { useState } from 'react';
import SearchBar from './SearchBar';
import SearchResults from './SearchResults';

const SearchContainer = ({ searchActive, setSearchActive, nations, organisations, services }) => {
    const [ searchResults, setSearchResults ] = useState([]);
    const [ searchValue, setSearchValue ] = useState('');

    const startSearch = (e) => {
        if (e.target.value !== '') {
            if (!searchActive) {
                setSearchActive(true);
            }
        }
        const searchVal = e.target.value.toLowerCase().trim();
        setSearchValue(searchVal);
        const filteredServices = services.filter(filterItem => (filterItem.name[2].toLowerCase().includes(searchVal) || filterItem.name[1].toLowerCase().includes(searchVal) || filterItem.name[0].toLowerCase().includes(searchVal)))
        const filteredNations = nations.filter(filterItem => (filterItem.name[2].toLowerCase().includes(searchVal) || filterItem.name[1].toLowerCase().includes(searchVal) || filterItem.name[0].toLowerCase().includes(searchVal)))
        const filteredOrganisations = organisations.filter(filterItem => (filterItem.title.toLowerCase().includes(searchVal)))
        // Handle Filtered Services - show all organisations providing that service
        if (filteredServices.length > 0) {
            filteredServices.forEach(item => {
                const filteredOrganisationsByService = organisations.filter(filterItem => (filterItem.services && filterItem.services.includes(item.id)));
                filteredOrganisations.push(...filteredOrganisationsByService);
            });
        }
        // Handle Filtered Nations - show all organisations operating in that nation
        if (filteredNations.length > 0) {
            filteredNations.forEach(item => {
                const filteredOrganisationsByNation = organisations.filter(filterItem => (filterItem.nations && (filterItem.nations.includes(item.id) || filterItem.nations.includes(item.name[2].toLowerCase()))));
                filteredOrganisations.push(...filteredOrganisationsByNation);
            });  
        }
        // Remove duplicates
        const uniqueNations = [...new Set(filteredNations)];
        const uniqueOrganisations = [...new Set(filteredOrganisations)];
        // Prepare and return results
        const results = {
            nations: [...uniqueNations],
            organisations: [...uniqueOrganisations]
        }
        setSearchResults({...results});
    }
    const handleEnter = (e) => {
        // Enter - closes onscreen keyboard and lose focus
        if (e.code === "Enter" || e.keyCode === 13) {
            e.preventDefault();
            document.getElementsByClassName('search-input')[0].blur();
        }
        // Escape - close and reset search
        if (e.code === "Escape" || e.keyCode === 27) {
            e.preventDefault();
            resetSearch();
        }
    }
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }

    const handleOpenSearch = () => {
        if (!searchActive) {
            setSearchActive(true);
            scrollToTop();
        }
    }

    const resetSearch = () => {
        setSearchValue('');
        setSearchActive(false);
        document.getElementsByClassName('search-input')[0].value = '';
        document.getElementsByClassName('search-input')[0].blur();
    }

    return (
        <div className="search-container" onClick={handleOpenSearch}>
            <SearchBar searchActive={searchActive} searchValue={searchValue} startSearch={startSearch} resetSearch={resetSearch} handleEnter={handleEnter} />
            <SearchResults  searchActive={searchActive} searchResults={searchResults} resetSearch={resetSearch} searchValue={searchValue}  />
        </div>
    );
}
 
export default SearchContainer;
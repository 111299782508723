import { motion } from "framer-motion";
import AboutTeam from "./AboutTeam";
import AboutText from "./AboutText";
import AboutPageGraphic from "./AboutPageGraphic";

const AboutPage = () => {
  const variants = {
    hidden: { x: 1000 },
    visible: { x: 0 },
    exit: { x: "-100vw" },
  };

  return (
    <motion.div
      variants={variants}
      initial="hidden"
      animate="visible"
      exit="exit"
      className="about-page-container"
    >
      <AboutPageGraphic />
      <AboutText />
      <AboutTeam />
    </motion.div>
  );
};

export default AboutPage;

import { useContext } from 'react';
import { AppContext } from '../../contexts/AppContext';
import OrganisationNationCard from './OrganisationNationCard';

const OrganisationNations = ({ organisation, nations }) => {
    const { language } = useContext(AppContext);
    const title = ['Місцезнаходження операцій', 'Emplacements', 'Operation Locations'];

    return (
        <div className="organisation-nations">
            <h3 className="organisation-inner-title">{title[language]}</h3>
            <div>
                {organisation.nations.map(id => (
                    <OrganisationNationCard key={id} id={id} nations={nations} />
                ))}
            </div>
        </div>
    );
}
 
export default OrganisationNations;
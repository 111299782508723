import { useContext } from "react";
import { AppContext } from "../contexts/AppContext";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { useEffect } from "react";

const variants = {
  hidden: { x: 300 },
  visible: { x: 0, transition: { type: "tween", duration: 0.1 } },
  exit: { x: 300 },
};

const SideNav = ({ setNavOpen }) => {
  const { language } = useContext(AppContext);
  const aboutTitle = ["про нас", "À propos", "About"];
  const getInvTitle = ["Get Involved", "Get Involved", "Get Involved"];
  const countriesTitle = ["країни", "Pays", "Countries"];
  const orgsTitle = ["організації", "Organisations", "Organisations"];
  const contactTitle = ["контакти", "Contact", "Contact"];

  const handleCloseNav = () => {
    setNavOpen(false);
  };
  // Locking Scroll Position
  useEffect(() => {
    document.body.classList.add("scroll-lock");
    return () => document.body.classList.remove("scroll-lock");
  }, [setNavOpen]);

  return (
    <div className="side-navigation-container" onClick={handleCloseNav}>
      <motion.div
        variants={variants}
        initial="hidden"
        animate="visible"
        exit="exit"
      >
        {/* <Link to="/">Home</Link> */}
        <Link to="/about">{aboutTitle[language]}</Link>
        {/* <Link to="/getinvolved">{getInvTitle[language]}</Link> */}
        <Link to="/countries">{countriesTitle[language]}</Link>
        <Link to="/organisations">{orgsTitle[language]}</Link>
        <Link to="/contact">{contactTitle[language]}</Link>
      </motion.div>
    </div>
  );
};

export default SideNav;

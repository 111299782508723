import { useContext } from "react";
import { AppContext } from '../../contexts/AppContext';

const ContactFormLoading = () => {
    const { language } = useContext(AppContext);
    const sendingText = ['Відправка', 'Envoi en cours', 'Sending'];

    return (
        <div className="contact-form-result">
            <h3>{ sendingText[language] }</h3>
        </div>
    );
}
 
export default ContactFormLoading;
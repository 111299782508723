import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import { useContext, useState, useEffect } from "react";
import { AppContext } from "./contexts/AppContext";
import { AnimatePresence } from "framer-motion";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { db } from "./firebase-config";

import NavReset from "./helpers/NavReset";

import SideNav from "./components/SideNav";
import TopBar from "./components/TopBar";
import Footer from "./components/Footer";

import HomePage from "./components/home/HomePage";
import AboutPage from "./components/about/AboutPage";
import GetInvolvedPage from "./components/getinvolved/GetInvolvedPage";
import CountriesPage from "./components/countries/CountriesPage";
import CountryPage from "./components/country/CountryPage";
import OrganisationsPage from "./components/organisations/OrganisationsPage";
import OrganisationPage from "./components/organisation/OrganisationPage";
import ContactPage from "./components/contact/ContactPage";

function Public() {
  const { language, chooseLanguage } = useContext(AppContext);
  const location = useLocation();
  const [nations, setNations] = useState([]);
  const [organisations, setOrganisaions] = useState([]);
  const [services, setServices] = useState([]);
  const [searchActive, setSearchActive] = useState(false);
  const [navOpen, setNavOpen] = useState(false);

  // Get localStorage languange
  useEffect(() => {
    const localLanguage = parseInt(localStorage.getItem("language"));
    if (
      localStorage.getItem("language") !== null &&
      localLanguage !== language
    ) {
      chooseLanguage(localLanguage);
    } else if (localStorage.getItem("language") === null) {
      chooseLanguage(0);
    } else {
      return;
    }
  }, []);

  // Fetch nations data from firestore
  const countriesColRef = collection(db, "countryList");
  const countriesQ = query(countriesColRef, where("visible", "==", true));
  useEffect(() => {
    let returnData = [];
    const nationsUnsubcriber = onSnapshot(
      countriesQ,
      { includeMetadataChanges: true },
      (snapshot) => {
        returnData = [];
        // Fetch data
        snapshot.docs.map((doc) => {
          return returnData.push({ ...doc.data(), id: doc.id });
        });
        // const source = snapshot.metadata.fromCache ? "local cache" : "server";
        // console.log("Data came from " + source);
        // console.log(snapshot.docs);
        setNations(returnData);
      }
    );
    return nationsUnsubcriber;
  }, []);

  // Fetch organisations data from firestore
  const orgsColRef = collection(db, "organisations");
  const orgsQ = query(orgsColRef, where("visible", "==", true));
  useEffect(() => {
    let returnData = [];
    const orgsQUnsubcriber = onSnapshot(
      orgsQ,
      { includeMetadataChanges: true },
      (snapshot) => {
        returnData = [];
        // Fetch data
        snapshot.docs.map((doc) => {
          return returnData.push({ ...doc.data(), id: doc.id });
        });
        // const source = snapshot.metadata.fromCache ? "local cache" : "server";
        // console.log("Data came from " + source);
        // console.log(snapshot.docs);
        setOrganisaions(returnData);
      }
    );
    return orgsQUnsubcriber;
  }, []);

  // Fetch all Services
  const servicesColRef = collection(db, "services");
  const servicesQ = query(servicesColRef);
  useEffect(() => {
    const servicesUnsubcriber = onSnapshot(servicesQ, (snapshot) => {
      setServices(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    });
    return servicesUnsubcriber;
  }, []);

  return (
    <div className="w-full overflow-x-hidden min-h-screen flex flex-col">
      {/* Scroll to top and ensure search closed */}
      <NavReset setSearchActive={setSearchActive} />
      {navOpen && <SideNav setNavOpen={setNavOpen} />}
      <div className={`pt-[15px] no_select flex-1 `}>
        <AnimatePresence>
          <TopBar setNavOpen={setNavOpen} searchActive={searchActive} />
        </AnimatePresence>
        <AnimatePresence exitBeforeEnter>
          <Routes location={location} key={location.pathname}>
            <Route
              path="/"
              element={
                <HomePage
                  searchActive={searchActive}
                  setSearchActive={setSearchActive}
                  nations={nations}
                  organisations={organisations}
                  services={services}
                />
              }
            />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/getinvolved" element={<GetInvolvedPage />} />
            <Route
              path="/countries"
              element={<CountriesPage nations={nations} />}
            />
            <Route
              path="/countries/:countryName"
              element={
                <CountryPage nations={nations} organisations={organisations} />
              }
            />
            <Route
              path="/organisations"
              element={<OrganisationsPage organisations={organisations} />}
            />
            <Route
              path="/organisations/:orgName"
              element={
                <OrganisationPage
                  nations={nations}
                  organisations={organisations}
                  services={services}
                />
              }
            />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/*" element={<Navigate replace to="/" />} />
          </Routes>
        </AnimatePresence>
      </div>
      <Footer />
    </div>
  );
}

export default Public;

const flagData = {
'afghanistan': 'af',
'algeria' : 'dz',
'albania' : 'al',
'andorra' : 'ad',
'angola' : 'ao',
'argentina' : 'ar',
'armenia' : 'am',
'australia' : 'au',
'austria' : 'at',
'azerbaijan' : 'az',
'bahamas' : 'bs',
'bahrain' : 'bh',
'bangladesh' : 'bd',
'barbados' : 'bb',
'belarus' : 'by',
'belgium' : 'be',
'belize' : 'bz',
'benin' : 'bj',
'bhutan' : 'bt',
'bolivia' : 'bo',
'botswana' : 'bw',
'brazil' : 'br',
'brunei' : 'bn',
'bulgaria' : 'bg',
'cambodia' : 'kh',
'cameroon' : 'cm',
'canada' : 'ca',	
'chad' : 'td',
'chile' : 'cl',
'china' : 'cn',
'colombia' : 'co',
'congo' : 'cd',
'costa rica' : 'cr',
'croatia' : 'hr',
'cuba' : 'cu',
'cyprus' : 'cy',
'czech republic' : 'cz',
'denmark' : 'dk',
'djibouti' : 'dj',
'dominica' : 'dm',
'dominican republic' : 'do',
'ecuador' : 'ec',
'egypt' : 'eg',
'el salvador' : 'sv',
'equatorial guinea' : 'gq',
'eritrea' : 'er',
'estonia' : 'ee',
'ethiopia' : 'et',
'fiji' : 'fj',
'finland' : 'fi',
'france' : 'fr',
'french polynesia' : 'pf',
'gabon' : 'ga',
'gambia' : 'gm',
'georgia' : 'ge',
'germany' : 'de',
'ghana' : 'gh',
'gibraltar' : 'gi',
'greece' : 'gr',
'greenland' : 'gl',
'grenada' : 'gd',
'guadeloupe' : 'gp',
'guatemala' : 'gt',
'guinea' : 'gn',
'guyana' : 'gy',
'haiti' : 'ht',
'honduras' : 'hn',
'hong kong' : 'hk',
'hungary' : 'hu',
'iceland' : 'is',
'india' : 'in',
'indonesia' : 'id',
'iran' : 'ir',
'iraq' : 'iq',
'ireland' : 'ie',
'israel' : 'il',
'italy' : 'it',
'jamaica' : 'jm',
'japan' : 'jp',
'jordan' : 'jo',
'kazakhstan' : 'kz',
'kenya' : 'ke',
'south korea' : 'kr',
'kuwait' : 'kw',
'kyrgyzstan' : 'kg',
'laos' : 'la',
'latvia' : 'lv',
'lebanon' : 'lb',
'lesotho' : 'ls',
'liberia' : 'lr',
'libya' : 'ly',
'liechtenstein' : 'li',
'lithuania' : 'lt',
'luxembourg' : 'lu',
'macedonia' : 'mk',
'madagascar' : 'mg',
'malawi' : 'mw',
'malaysia' : 'my',
'maldives' : 'mv',
'mali' : 'ml',
'malta' : 'mt',
'martinique' : 'mq',
'mauritania' : 'mr',
'mauritius' : 'mu',
'mexico' : 'mx',
'moldova' : 'md',
'monaco' : 'mc',
'mongolia' : 'mn',
'montenegro' : 'me',
'morocco' : 'ma',
'mozambique' : 'mz',
'myanmar' : 'mm',
'namibia' : 'na',
'nauru' : 'nr',
'nepal' : 'np',
'netherlands' : 'nl',
'new caledonia' : 'nc',
'new zealand' : 'nz',
'nicaragua' : 'ni',
'niger' : 'ne',	
'nigeria' : 'ng',
'norway' : 'no',
'oman' : 'om',	
'pakistan' : 'pk',
'palau' : 'pw',
'panama' : 'pa',
'papua new guinea' : 'pg',
'paraguay' : 'py',
'peru' : 'pe',
'philippines' : 'ph',
'poland' : 'pl',
'portugal' : 'pt',
'puerto rico' : 'pr',
'qatar' : 'qa',	
'romania' : 'ro',
'rwanda' : 'rw',
'saint lucia' : 'lc',
'samoa' : 'ws',
'san marino' : 'sm',
'saudi arabia' : 'sa',
'senegal' : 'sn',
'serbia' : 'rs',
'seychelles' : 'sc',
'sierra leone' : 'sl',
'singapore' : 'sg',
'slovakia' : 'sk',
'slovenia' : 'si',
'solomon islands' : 'sb',
'somalia' : 'so',
'south africa' : 'za',
'spain' : 'es',
'sri lanka' : 'lk',
'sudan' : 'sd',
'suriname' : 'sr',
'swaziland':'sz', 
'sweden':'se',
'switzerland':'ch',
'syria': 'sy', 
'taiwan': 'tw',
'tajikistan': 'tj',
'tanzania': 'tz', 
'thailand': 'th', 
'togo': 'tg',
'tonga': 'to',
'trinidad and tobago': 'tt',
'tunisia': 'tn',
'turkey': 'tr',
'turkmenistan': 'tm',
'tuvalu': 'tv',
'uganda': 'ug',
'ukraine' : 'ua',	
'united arab emirates' : 'ae',
'united kingdom': 'gb',
'united states': 'us',
'uruguay': 'uy',
'uzbekistan': 'uz',
'vanuatu' : 'vu',
'vatican city' : 'va',
'venezuela' : 've',	
'vietnam' : 'vn',
'virgin islands' : 'vi',
'western sahara' : 'eh',	
'yemen' : 'ye',
'zambia' : 'zm',
'zimbabwe' : 'zw'
}
  
export default flagData
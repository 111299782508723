import { motion } from 'framer-motion';

const AboutPageGraphic = () => {
    const topVariants = {hidden: {y:-100},visible: {y:0, transition: { delay:0.3 }}};
    const bottomVariants = {hidden: {y:100},visible: {y:0, transition: { delay:0.3 }}};
    const leftVariants = {hidden: {x:-500},visible: {x:0, transition: { delay:0.6 }}};
    const rightVariants = {hidden: {x:500},visible: {x:0, transition: { delay:0.6 }}};

    return (
        <div className='about-page-graphic'>
            <svg xmlns="http://www.w3.org/2000/svg" width="1000" viewBox="0 0 201 97">
                {/* From top */}
                <motion.g
                    variants={topVariants}
                    initial='hidden'
                    animate='visible'
                    fill="rgba(255,255,255,0.15)">
                    <circle cx="84" cy="2" r=".8"/>
                    <circle cx="82" cy="2" r=".8"/>
                    <circle cx="80" cy="2" r=".8"/>
                    <circle cx="78" cy="2" r=".8"/>
                    <circle cx="76" cy="2" r=".8"/>
                    <circle cx="74" cy="2" r=".8"/>
                    <circle cx="72" cy="2" r=".8"/>
                    <circle cx="70" cy="2" r=".8"/>
                    <circle cx="68" cy="2" r=".8"/>
                    <circle cx="66" cy="2" r=".8"/>
                    <circle cx="64" cy="2" r=".8"/>
                    <circle cx="62" cy="2" r=".8"/>
                    <circle cx="60" cy="2" r=".8"/>
                    <circle cx="58" cy="2" r=".8"/>
                    <circle cx="54" cy="2" r=".8"/>

                    <circle cx="82" cy="4" r=".8"/>
                    <circle cx="80" cy="4" r=".8"/>
                    <circle cx="78" cy="4" r=".8"/>
                    <circle cx="76" cy="4" r=".8"/>
                    <circle cx="74" cy="4" r=".8"/>
                    <circle cx="72" cy="4" r=".8"/>
                    <circle cx="70" cy="4" r=".8"/>
                    <circle cx="68" cy="4" r=".8"/>
                    <circle cx="66" cy="4" r=".8"/>
                    <circle cx="64" cy="4" r=".8"/>
                    <circle cx="62" cy="4" r=".8"/>
                    <circle cx="56" cy="4" r=".8"/>
                    <circle cx="54" cy="4" r=".8"/>

                    <circle cx="80" cy="6" r=".8"/>
                    <circle cx="78" cy="6" r=".8"/>
                    <circle cx="76" cy="6" r=".8"/>
                    <circle cx="74" cy="6" r=".8"/>
                    <circle cx="72" cy="6" r=".8"/>
                    <circle cx="70" cy="6" r=".8"/>
                    <circle cx="68" cy="6" r=".8"/>
                    <circle cx="66" cy="6" r=".8"/>

                    <circle cx="78" cy="8" r=".8"/>
                    <circle cx="76" cy="8" r=".8"/>
                    <circle cx="74" cy="8" r=".8"/>
                    <circle cx="72" cy="8" r=".8"/>
                    <circle cx="70" cy="8" r=".8"/>
                    <circle cx="68" cy="8" r=".8"/>

                    <circle cx="72" cy="10" r=".8"/>
                    <circle cx="70" cy="10" r=".8"/>
                    <circle cx="68" cy="10" r=".8"/>
                    <circle cx="66" cy="10" r=".8"/>

                    <circle cx="82" cy="12" r=".8"/>
                    <circle cx="80" cy="12" r=".8"/>
                    <circle cx="68" cy="12" r=".8"/>
                    <circle cx="66" cy="12" r=".8"/>

                    <circle cx="66" cy="14" r=".8"/>

                    <circle cx="88" cy="16" r=".8"/>

                    <circle cx="88" cy="18" r=".8"/>
                    <circle cx="86" cy="18" r=".8"/>

                    <circle cx="86" cy="20" r=".8"/>


                </motion.g>
                {/* From left */}
                <motion.g
                    variants={leftVariants}
                    initial='hidden'
                    animate='visible'
                    fill="rgba(255,255,255,0.15)">
                    <circle cx="54" cy="96" r=".8"/>
                    <circle cx="52" cy="96" r=".8"/>

                    <circle cx="50" cy="94" r=".8"/>
                    <circle cx="48" cy="94" r=".8"/>

                    <circle cx="50" cy="92" r=".8"/>
                    <circle cx="48" cy="92" r=".8"/>

                    <circle cx="50" cy="90" r=".8"/>
                    <circle cx="48" cy="90" r=".8"/>
                    <circle cx="46" cy="90" r=".8"/>

                    <circle cx="50" cy="88" r=".8"/>
                    <circle cx="48" cy="88" r=".8"/>
                    <circle cx="46" cy="88" r=".8"/>

                    <circle cx="50" cy="86" r=".8"/>
                    <circle cx="48" cy="86" r=".8"/>
                    <circle cx="46" cy="86" r=".8"/>

                    <circle cx="54" cy="84" r=".8"/>
                    <circle cx="52" cy="84" r=".8"/>
                    <circle cx="50" cy="84" r=".8"/>
                    <circle cx="48" cy="84" r=".8"/>
                    <circle cx="46" cy="84" r=".8"/>

                    <circle cx="52" cy="82" r=".8"/>
                    <circle cx="50" cy="82" r=".8"/>
                    <circle cx="48" cy="82" r=".8"/>
                    <circle cx="46" cy="82" r=".8"/>

                    <circle cx="56" cy="80" r=".8"/>
                    <circle cx="54" cy="80" r=".8"/>
                    <circle cx="52" cy="80" r=".8"/>
                    <circle cx="50" cy="80" r=".8"/>
                    <circle cx="48" cy="80" r=".8"/>
                    <circle cx="46" cy="80" r=".8"/>
                    <circle cx="44" cy="80" r=".8"/>

                    <circle cx="56" cy="78" r=".8"/>
                    <circle cx="54" cy="78" r=".8"/>
                    <circle cx="52" cy="78" r=".8"/>
                    <circle cx="50" cy="78" r=".8"/>
                    <circle cx="48" cy="78" r=".8"/>
                    <circle cx="46" cy="78" r=".8"/>
                    <circle cx="44" cy="78" r=".8"/>

                    <circle cx="58" cy="76" r=".8"/>
                    <circle cx="56" cy="76" r=".8"/>
                    <circle cx="54" cy="76" r=".8"/>
                    <circle cx="52" cy="76" r=".8"/>
                    <circle cx="50" cy="76" r=".8"/>
                    <circle cx="48" cy="76" r=".8"/>
                    <circle cx="46" cy="76" r=".8"/>
                    <circle cx="44" cy="76" r=".8"/>

                    <circle cx="58" cy="74" r=".8"/>
                    <circle cx="56" cy="74" r=".8"/>
                    <circle cx="54" cy="74" r=".8"/>
                    <circle cx="52" cy="74" r=".8"/>
                    <circle cx="50" cy="74" r=".8"/>
                    <circle cx="48" cy="74" r=".8"/>
                    <circle cx="46" cy="74" r=".8"/>
                    <circle cx="44" cy="74" r=".8"/>

                    <circle cx="62" cy="72" r=".8"/>
                    <circle cx="60" cy="72" r=".8"/>
                    <circle cx="58" cy="72" r=".8"/>
                    <circle cx="56" cy="72" r=".8"/>
                    <circle cx="54" cy="72" r=".8"/>
                    <circle cx="52" cy="72" r=".8"/>
                    <circle cx="50" cy="72" r=".8"/>
                    <circle cx="48" cy="72" r=".8"/>
                    <circle cx="46" cy="72" r=".8"/>
                    <circle cx="44" cy="72" r=".8"/>

                    <circle cx="62" cy="70" r=".8"/>
                    <circle cx="60" cy="70" r=".8"/>
                    <circle cx="58" cy="70" r=".8"/>
                    <circle cx="56" cy="70" r=".8"/>
                    <circle cx="54" cy="70" r=".8"/>
                    <circle cx="52" cy="70" r=".8"/>
                    <circle cx="50" cy="70" r=".8"/>
                    <circle cx="48" cy="70" r=".8"/>
                    <circle cx="46" cy="70" r=".8"/>
                    <circle cx="44" cy="70" r=".8"/>

                    <circle cx="62" cy="68" r=".8"/>
                    <circle cx="60" cy="68" r=".8"/>
                    <circle cx="58" cy="68" r=".8"/>
                    <circle cx="56" cy="68" r=".8"/>
                    <circle cx="54" cy="68" r=".8"/>
                    <circle cx="52" cy="68" r=".8"/>
                    <circle cx="50" cy="68" r=".8"/>
                    <circle cx="48" cy="68" r=".8"/>
                    <circle cx="46" cy="68" r=".8"/>
                    <circle cx="44" cy="68" r=".8"/>
                    <circle cx="42" cy="68" r=".8"/>
                    <circle cx="40" cy="68" r=".8"/>

                    <circle cx="64" cy="66" r=".8"/>
                    <circle cx="62" cy="66" r=".8"/>
                    <circle cx="60" cy="66" r=".8"/>
                    <circle cx="58" cy="66" r=".8"/>
                    <circle cx="56" cy="66" r=".8"/>
                    <circle cx="54" cy="66" r=".8"/>
                    <circle cx="52" cy="66" r=".8"/>
                    <circle cx="50" cy="66" r=".8"/>
                    <circle cx="48" cy="66" r=".8"/>
                    <circle cx="46" cy="66" r=".8"/>
                    <circle cx="44" cy="66" r=".8"/>
                    <circle cx="42" cy="66" r=".8"/>
                    <circle cx="40" cy="66" r=".8"/>

                    <circle cx="64" cy="64" r=".8"/>
                    <circle cx="62" cy="64" r=".8"/>
                    <circle cx="60" cy="64" r=".8"/>
                    <circle cx="58" cy="64" r=".8"/>
                    <circle cx="56" cy="64" r=".8"/>
                    <circle cx="54" cy="64" r=".8"/>
                    <circle cx="52" cy="64" r=".8"/>
                    <circle cx="50" cy="64" r=".8"/>
                    <circle cx="48" cy="64" r=".8"/>
                    <circle cx="46" cy="64" r=".8"/>
                    <circle cx="44" cy="64" r=".8"/>
                    <circle cx="42" cy="64" r=".8"/>
                    <circle cx="40" cy="64" r=".8"/>
                    <circle cx="38" cy="64" r=".8"/>

                    <circle cx="66" cy="62" r=".8"/>
                    <circle cx="64" cy="62" r=".8"/>
                    <circle cx="62" cy="62" r=".8"/>
                    <circle cx="60" cy="62" r=".8"/>
                    <circle cx="58" cy="62" r=".8"/>
                    <circle cx="56" cy="62" r=".8"/>
                    <circle cx="54" cy="62" r=".8"/>
                    <circle cx="52" cy="62" r=".8"/>
                    <circle cx="50" cy="62" r=".8"/>
                    <circle cx="48" cy="62" r=".8"/>
                    <circle cx="46" cy="62" r=".8"/>
                    <circle cx="44" cy="62" r=".8"/>
                    <circle cx="42" cy="62" r=".8"/>
                    <circle cx="40" cy="62" r=".8"/>
                    <circle cx="38" cy="62" r=".8"/>

                    <circle cx="62" cy="60" r=".8"/>
                    <circle cx="60" cy="60" r=".8"/>
                    <circle cx="58" cy="60" r=".8"/>
                    <circle cx="56" cy="60" r=".8"/>
                    <circle cx="54" cy="60" r=".8"/>
                    <circle cx="52" cy="60" r=".8"/>
                    <circle cx="50" cy="60" r=".8"/>
                    <circle cx="48" cy="60" r=".8"/>
                    <circle cx="46" cy="60" r=".8"/>
                    <circle cx="44" cy="60" r=".8"/>
                    <circle cx="42" cy="60" r=".8"/>
                    <circle cx="40" cy="60" r=".8"/>
                    <circle cx="38" cy="60" r=".8"/>
                    <circle cx="36" cy="60" r=".8"/>

                    <circle cx="58" cy="58" r=".8"/>
                    <circle cx="54" cy="58" r=".8"/>
                    <circle cx="52" cy="58" r=".8"/>
                    <circle cx="50" cy="58" r=".8"/>
                    <circle cx="48" cy="58" r=".8"/>
                    <circle cx="46" cy="58" r=".8"/>
                    <circle cx="44" cy="58" r=".8"/>
                    <circle cx="42" cy="58" r=".8"/>
                    <circle cx="40" cy="58" r=".8"/>
                    <circle cx="38" cy="58" r=".8"/>
                    <circle cx="36" cy="58" r=".8"/>

                    <circle cx="54" cy="56" r=".8"/>
                    <circle cx="52" cy="56" r=".8"/>
                    <circle cx="50" cy="56" r=".8"/>
                    <circle cx="48" cy="56" r=".8"/>
                    <circle cx="46" cy="56" r=".8"/>
                    <circle cx="44" cy="56" r=".8"/>
                    <circle cx="42" cy="56" r=".8"/>
                    <circle cx="40" cy="56" r=".8"/>
                    <circle cx="38" cy="56" r=".8"/>

                    <circle cx="54" cy="54" r=".8"/>
                    <circle cx="52" cy="54" r=".8"/>
                    <circle cx="50" cy="54" r=".8"/>
                    <circle cx="48" cy="54" r=".8"/>
                    <circle cx="46" cy="54" r=".8"/>
                    <circle cx="44" cy="54" r=".8"/>
                    <circle cx="42" cy="54" r=".8"/>
                    <circle cx="40" cy="54" r=".8"/>
                    <circle cx="38" cy="54" r=".8"/>

                    <circle cx="50" cy="52" r=".8"/>
                    <circle cx="48" cy="52" r=".8"/>
                    <circle cx="46" cy="52" r=".8"/>
                    <circle cx="44" cy="52" r=".8"/>
                    <circle cx="42" cy="52" r=".8"/>
                    <circle cx="40" cy="52" r=".8"/>
                    <circle cx="38" cy="52" r=".8"/>

                    <circle cx="48" cy="50" r=".8"/>
                    <circle cx="46" cy="50" r=".8"/>
                    <circle cx="44" cy="50" r=".8"/>
                    <circle cx="42" cy="50" r=".8"/>
                    <circle cx="40" cy="50" r=".8"/>
                    <circle cx="34" cy="50" r=".8"/>

                    <circle cx="34" cy="48" r=".8"/>
                    <circle cx="32" cy="48" r=".8"/>

                    <circle cx="34" cy="46" r=".8"/>
                    <circle cx="32" cy="46" r=".8"/>
                    <circle cx="30" cy="46" r=".8"/>

                    <circle cx="46" cy="44" r=".8"/>
                    <circle cx="42" cy="44" r=".8"/>
                    <circle cx="38" cy="44" r=".8"/>
                    <circle cx="30" cy="44" r=".8"/>
                    <circle cx="28" cy="44" r=".8"/>
                    <circle cx="26" cy="44" r=".8"/>
                    <circle cx="24" cy="44" r=".8"/>
                    <circle cx="22" cy="44" r=".8"/>

                    <circle cx="40" cy="42" r=".8"/>
                    <circle cx="32" cy="42" r=".8"/>
                    <circle cx="26" cy="42" r=".8"/>
                    <circle cx="24" cy="42" r=".8"/>
                    <circle cx="22" cy="42" r=".8"/>

                    <circle cx="24" cy="40" r=".8"/>
                    <circle cx="22" cy="40" r=".8"/>
                    <circle cx="20" cy="40" r=".8"/>
                    <circle cx="18" cy="40" r=".8"/>

                    <circle cx="38" cy="38" r=".8"/>
                    <circle cx="26" cy="38" r=".8"/>
                    <circle cx="24" cy="38" r=".8"/>
                    <circle cx="22" cy="38" r=".8"/>
                    <circle cx="20" cy="38" r=".8"/>

                    <circle cx="38" cy="36" r=".8"/>
                    <circle cx="28" cy="36" r=".8"/>
                    <circle cx="26" cy="36" r=".8"/>
                    <circle cx="24" cy="36" r=".8"/>
                    <circle cx="22" cy="36" r=".8"/>
                    <circle cx="20" cy="36" r=".8"/>
                    <circle cx="18" cy="36" r=".8"/>
                    <circle cx="16" cy="36" r=".8"/>

                    <circle cx="38" cy="34" r=".8"/>
                    <circle cx="36" cy="34" r=".8"/>
                    <circle cx="34" cy="34" r=".8"/>
                    <circle cx="32" cy="34" r=".8"/>
                    <circle cx="30" cy="34" r=".8"/>
                    <circle cx="28" cy="34" r=".8"/>
                    <circle cx="26" cy="34" r=".8"/>
                    <circle cx="24" cy="34" r=".8"/>
                    <circle cx="22" cy="34" r=".8"/>
                    <circle cx="20" cy="34" r=".8"/>
                    <circle cx="18" cy="34" r=".8"/>
                    <circle cx="16" cy="34" r=".8"/>
                    
                    <circle cx="40" cy="32" r=".8"/>
                    <circle cx="38" cy="32" r=".8"/>
                    <circle cx="36" cy="32" r=".8"/>
                    <circle cx="34" cy="32" r=".8"/>
                    <circle cx="32" cy="32" r=".8"/>
                    <circle cx="30" cy="32" r=".8"/>
                    <circle cx="28" cy="32" r=".8"/>
                    <circle cx="26" cy="32" r=".8"/>
                    <circle cx="24" cy="32" r=".8"/>
                    <circle cx="22" cy="32" r=".8"/>
                    <circle cx="20" cy="32" r=".8"/>
                    <circle cx="18" cy="32" r=".8"/>
                    <circle cx="16" cy="32" r=".8"/>
                    <circle cx="14" cy="32" r=".8"/>

                    <circle cx="42" cy="30" r=".8"/>
                    <circle cx="40" cy="30" r=".8"/>
                    <circle cx="38" cy="30" r=".8"/>
                    <circle cx="36" cy="30" r=".8"/>
                    <circle cx="34" cy="30" r=".8"/>
                    <circle cx="32" cy="30" r=".8"/>
                    <circle cx="30" cy="30" r=".8"/>
                    <circle cx="28" cy="30" r=".8"/>
                    <circle cx="26" cy="30" r=".8"/>
                    <circle cx="24" cy="30" r=".8"/>
                    <circle cx="22" cy="30" r=".8"/>
                    <circle cx="20" cy="30" r=".8"/>
                    <circle cx="18" cy="30" r=".8"/>
                    <circle cx="16" cy="30" r=".8"/>
                    <circle cx="14" cy="30" r=".8"/>

                    <circle cx="44" cy="28" r=".8"/>
                    <circle cx="42" cy="28" r=".8"/>
                    <circle cx="40" cy="28" r=".8"/>
                    <circle cx="38" cy="28" r=".8"/>
                    <circle cx="36" cy="28" r=".8"/>
                    <circle cx="34" cy="28" r=".8"/>
                    <circle cx="32" cy="28" r=".8"/>
                    <circle cx="30" cy="28" r=".8"/>
                    <circle cx="28" cy="28" r=".8"/>
                    <circle cx="26" cy="28" r=".8"/>
                    <circle cx="24" cy="28" r=".8"/>
                    <circle cx="22" cy="28" r=".8"/>
                    <circle cx="20" cy="28" r=".8"/>
                    <circle cx="18" cy="28" r=".8"/>
                    <circle cx="16" cy="28" r=".8"/>
                    <circle cx="14" cy="28" r=".8"/>

                    <circle cx="46" cy="26" r=".8"/>
                    <circle cx="44" cy="26" r=".8"/>
                    <circle cx="42" cy="26" r=".8"/>
                    <circle cx="40" cy="26" r=".8"/>
                    <circle cx="38" cy="26" r=".8"/>
                    <circle cx="36" cy="26" r=".8"/>
                    <circle cx="34" cy="26" r=".8"/>
                    <circle cx="32" cy="26" r=".8"/>
                    <circle cx="30" cy="26" r=".8"/>
                    <circle cx="28" cy="26" r=".8"/>
                    <circle cx="26" cy="26" r=".8"/>
                    <circle cx="24" cy="26" r=".8"/>
                    <circle cx="22" cy="26" r=".8"/>
                    <circle cx="20" cy="26" r=".8"/>
                    <circle cx="18" cy="26" r=".8"/>
                    <circle cx="16" cy="26" r=".8"/>

                    <circle cx="52" cy="24" r=".8"/>
                    <circle cx="50" cy="24" r=".8"/>
                    <circle cx="48" cy="24" r=".8"/>
                    <circle cx="46" cy="24" r=".8"/>
                    <circle cx="44" cy="24" r=".8"/>
                    <circle cx="42" cy="24" r=".8"/>
                    <circle cx="38" cy="24" r=".8"/>
                    <circle cx="36" cy="24" r=".8"/>
                    <circle cx="34" cy="24" r=".8"/>
                    <circle cx="32" cy="24" r=".8"/>
                    <circle cx="30" cy="24" r=".8"/>
                    <circle cx="28" cy="24" r=".8"/>
                    <circle cx="26" cy="24" r=".8"/>
                    <circle cx="24" cy="24" r=".8"/>
                    <circle cx="22" cy="24" r=".8"/>
                    <circle cx="20" cy="24" r=".8"/>
                    <circle cx="18" cy="24" r=".8"/>

                    <circle cx="52" cy="22" r=".8"/>
                    <circle cx="48" cy="22" r=".8"/>
                    <circle cx="46" cy="22" r=".8"/>
                    <circle cx="44" cy="22" r=".8"/>
                    <circle cx="42" cy="22" r=".8"/>
                    <circle cx="40" cy="22" r=".8"/>
                    <circle cx="38" cy="22" r=".8"/>
                    <circle cx="36" cy="22" r=".8"/>
                    <circle cx="34" cy="22" r=".8"/>
                    <circle cx="32" cy="22" r=".8"/>
                    <circle cx="30" cy="22" r=".8"/>
                    <circle cx="28" cy="22" r=".8"/>
                    <circle cx="26" cy="22" r=".8"/>
                    <circle cx="24" cy="22" r=".8"/>
                    <circle cx="22" cy="22" r=".8"/>
                    <circle cx="20" cy="22" r=".8"/>
                    <circle cx="18" cy="22" r=".8"/>

                    <circle cx="56" cy="20" r=".8"/>
                    <circle cx="54" cy="20" r=".8"/>
                    <circle cx="52" cy="20" r=".8"/>
                    <circle cx="50" cy="20" r=".8"/>
                    <circle cx="48" cy="20" r=".8"/>
                    <circle cx="46" cy="20" r=".8"/>
                    <circle cx="44" cy="20" r=".8"/>
                    <circle cx="42" cy="20" r=".8"/>
                    <circle cx="40" cy="20" r=".8"/>
                    <circle cx="38" cy="20" r=".8"/>
                    <circle cx="36" cy="20" r=".8"/>
                    <circle cx="34" cy="20" r=".8"/>
                    <circle cx="32" cy="20" r=".8"/>
                    <circle cx="30" cy="20" r=".8"/>
                    <circle cx="28" cy="20" r=".8"/>
                    <circle cx="26" cy="20" r=".8"/>
                    <circle cx="24" cy="20" r=".8"/>
                    <circle cx="22" cy="20" r=".8"/>
                    <circle cx="20" cy="20" r=".8"/>
                    <circle cx="18" cy="20" r=".8"/>

                    <circle cx="56" cy="18" r=".8"/>
                    <circle cx="54" cy="18" r=".8"/>
                    <circle cx="52" cy="18" r=".8"/>
                    <circle cx="50" cy="18" r=".8"/>
                    <circle cx="48" cy="18" r=".8"/>
                    <circle cx="46" cy="18" r=".8"/>
                    <circle cx="44" cy="18" r=".8"/>
                    <circle cx="42" cy="18" r=".8"/>
                    <circle cx="40" cy="18" r=".8"/>
                    <circle cx="38" cy="18" r=".8"/>
                    <circle cx="36" cy="18" r=".8"/>
                    <circle cx="34" cy="18" r=".8"/>
                    <circle cx="32" cy="18" r=".8"/>
                    <circle cx="30" cy="18" r=".8"/>
                    <circle cx="28" cy="18" r=".8"/>
                    <circle cx="26" cy="18" r=".8"/>
                    <circle cx="24" cy="18" r=".8"/>
                    <circle cx="22" cy="18" r=".8"/>
                    <circle cx="20" cy="18" r=".8"/>
                    <circle cx="18" cy="18" r=".8"/>

                    <circle cx="56" cy="16" r=".8"/>
                    <circle cx="54" cy="16" r=".8"/>
                    <circle cx="52" cy="16" r=".8"/>
                    <circle cx="50" cy="16" r=".8"/>
                    <circle cx="40" cy="16" r=".8"/>
                    <circle cx="38" cy="16" r=".8"/>
                    <circle cx="36" cy="16" r=".8"/>
                    <circle cx="34" cy="16" r=".8"/>
                    <circle cx="32" cy="16" r=".8"/>
                    <circle cx="30" cy="16" r=".8"/>
                    <circle cx="28" cy="16" r=".8"/>
                    <circle cx="26" cy="16" r=".8"/>
                    <circle cx="24" cy="16" r=".8"/>
                    <circle cx="22" cy="16" r=".8"/>
                    <circle cx="20" cy="16" r=".8"/>
                    <circle cx="18" cy="16" r=".8"/>
                    <circle cx="6" cy="16" r=".8"/>
                    <circle cx="4" cy="16" r=".8"/>

                    <circle cx="52" cy="14" r=".8"/>
                    <circle cx="50" cy="14" r=".8"/>
                    <circle cx="40" cy="14" r=".8"/>
                    <circle cx="38" cy="14" r=".8"/>
                    <circle cx="36" cy="14" r=".8"/>
                    <circle cx="34" cy="14" r=".8"/>
                    <circle cx="32" cy="14" r=".8"/>
                    <circle cx="30" cy="14" r=".8"/>
                    <circle cx="28" cy="14" r=".8"/>
                    <circle cx="26" cy="14" r=".8"/>
                    <circle cx="24" cy="14" r=".8"/>
                    <circle cx="22" cy="14" r=".8"/>
                    <circle cx="20" cy="14" r=".8"/>
                    <circle cx="18" cy="14" r=".8"/>
                    <circle cx="16" cy="14" r=".8"/>
                    <circle cx="14" cy="14" r=".8"/>
                    <circle cx="10" cy="14" r=".8"/>
                    <circle cx="8" cy="14" r=".8"/>
                    <circle cx="6" cy="14" r=".8"/>

                    <circle cx="56" cy="12" r=".8"/>
                    <circle cx="54" cy="12" r=".8"/>
                    <circle cx="48" cy="12" r=".8"/>
                    <circle cx="44" cy="12" r=".8"/>
                    <circle cx="42" cy="12" r=".8"/>
                    <circle cx="40" cy="12" r=".8"/>
                    <circle cx="38" cy="12" r=".8"/>
                    <circle cx="36" cy="12" r=".8"/>
                    <circle cx="34" cy="12" r=".8"/>
                    <circle cx="32" cy="12" r=".8"/>
                    <circle cx="30" cy="12" r=".8"/>
                    <circle cx="28" cy="12" r=".8"/>
                    <circle cx="26" cy="12" r=".8"/>
                    <circle cx="24" cy="12" r=".8"/>
                    <circle cx="22" cy="12" r=".8"/>
                    <circle cx="20" cy="12" r=".8"/>
                    <circle cx="18" cy="12" r=".8"/>
                    <circle cx="16" cy="12" r=".8"/>
                    <circle cx="14" cy="12" r=".8"/>
                    <circle cx="12" cy="12" r=".8"/>
                    <circle cx="10" cy="12" r=".8"/>
                    <circle cx="8" cy="12" r=".8"/>

                    <circle cx="58" cy="10" r=".8"/>
                    <circle cx="56" cy="10" r=".8"/>
                    <circle cx="50" cy="10" r=".8"/>
                    <circle cx="48" cy="10" r=".8"/>
                    <circle cx="46" cy="10" r=".8"/>
                    <circle cx="44" cy="10" r=".8"/>
                    <circle cx="42" cy="10" r=".8"/>
                    <circle cx="40" cy="10" r=".8"/>
                    <circle cx="38" cy="10" r=".8"/>
                    <circle cx="36" cy="10" r=".8"/>
                    <circle cx="34" cy="10" r=".8"/>
                    <circle cx="32" cy="10" r=".8"/>
                    <circle cx="30" cy="10" r=".8"/>
                    <circle cx="28" cy="10" r=".8"/>
                    <circle cx="26" cy="10" r=".8"/>
                    <circle cx="24" cy="10" r=".8"/>
                    <circle cx="22" cy="10" r=".8"/>
                    <circle cx="20" cy="10" r=".8"/>
                    <circle cx="18" cy="10" r=".8"/>
                    <circle cx="16" cy="10" r=".8"/>
                    <circle cx="14" cy="10" r=".8"/>
                    <circle cx="12" cy="10" r=".8"/>

                    <circle cx="58" cy="8" r=".8"/>
                    <circle cx="56" cy="8" r=".8"/>
                    <circle cx="54" cy="8" r=".8"/>
                    <circle cx="52" cy="8" r=".8"/>
                    <circle cx="46" cy="8" r=".8"/>
                    <circle cx="42" cy="8" r=".8"/>
                    <circle cx="40" cy="8" r=".8"/>
                    <circle cx="38" cy="8" r=".8"/>
                    <circle cx="36" cy="8" r=".8"/>
                    <circle cx="30" cy="8" r=".8"/>
                    <circle cx="22" cy="8" r=".8"/>
                    <circle cx="20" cy="8" r=".8"/>
                    <circle cx="18" cy="8" r=".8"/>
                    <circle cx="16" cy="8" r=".8"/>
                    <circle cx="14" cy="8" r=".8"/>

                    <circle cx="48" cy="6" r=".8"/>
                    <circle cx="46" cy="6" r=".8"/>
                    <circle cx="42" cy="6" r=".8"/>
                    <circle cx="38" cy="6" r=".8"/>
                    <circle cx="36" cy="6" r=".8"/>
                </motion.g>
                <motion.g
                    variants={rightVariants}
                    initial='hidden'
                    animate='visible'
                    fill="rgba(255,255,255,0.15)"
                >
                    <circle cx="190" cy="90" r=".8"/>

                    <circle cx="192" cy="88" r=".8"/>

                    <circle cx="196" cy="86" r=".8"/>

                    <circle cx="198" cy="84" r=".8"/>

                    <circle cx="196" cy="72" r=".8"/>

                    <circle cx="194" cy="64" r=".8"/>
                    <circle cx="188" cy="64" r=".8"/>
                    <circle cx="168" cy="64" r=".8"/>

                    <circle cx="192" cy="62" r=".8"/>
                    <circle cx="186" cy="62" r=".8"/>
                    <circle cx="184" cy="62" r=".8"/>
                    <circle cx="182" cy="62" r=".8"/>
                    <circle cx="162" cy="62" r=".8"/>
                    <circle cx="160" cy="62" r=".8"/>

                    <circle cx="190" cy="60" r=".8"/>
                    <circle cx="184" cy="60" r=".8"/>
                    <circle cx="182" cy="60" r=".8"/>
                    <circle cx="180" cy="60" r=".8"/>
                    <circle cx="178" cy="60" r=".8"/>
                    <circle cx="170" cy="60" r=".8"/>
                    <circle cx="168" cy="60" r=".8"/>
                    <circle cx="158" cy="60" r=".8"/>
                    
                    <circle cx="176" cy="58" r=".8"/>
                    <circle cx="170" cy="58" r=".8"/>
                    <circle cx="168" cy="58" r=".8"/>
                    <circle cx="166" cy="58" r=".8"/>
                    <circle cx="164" cy="58" r=".8"/>
                    <circle cx="162" cy="58" r=".8"/>
                    <circle cx="158" cy="58" r=".8"/>
                    <circle cx="156" cy="58" r=".8"/>

                    <circle cx="174" cy="56" r=".8"/>
                    <circle cx="172" cy="56" r=".8"/>
                    <circle cx="170" cy="56" r=".8"/>
                    <circle cx="166" cy="56" r=".8"/>
                    <circle cx="164" cy="56" r=".8"/>
                    <circle cx="162" cy="56" r=".8"/>
                    <circle cx="156" cy="56" r=".8"/>

                    <circle cx="166" cy="54" r=".8"/>
                    <circle cx="156" cy="54" r=".8"/>
                    <circle cx="154" cy="54" r=".8"/>

                    <circle cx="172" cy="52" r=".8"/>
                    <circle cx="156" cy="52" r=".8"/>
                    <circle cx="142" cy="52" r=".8"/>

                    <circle cx="172" cy="50" r=".8"/>
                    <circle cx="170" cy="50" r=".8"/>
                    <circle cx="158" cy="50" r=".8"/>
                    <circle cx="154" cy="50" r=".8"/>
                    <circle cx="140" cy="50" r=".8"/>

                    <circle cx="169" cy="48" r=".8"/>
                    <circle cx="160" cy="48" r=".8"/>
                    <circle cx="158" cy="48" r=".8"/>
                    <circle cx="156" cy="48" r=".8"/>
                    <circle cx="154" cy="48" r=".8"/>
                    <circle cx="140" cy="48" r=".8"/>

                    <circle cx="168" cy="46" r=".8"/>
                    <circle cx="160" cy="46" r=".8"/>
                    <circle cx="158" cy="46" r=".8"/>
                    <circle cx="156" cy="46" r=".8"/>
                    <circle cx="154" cy="46" r=".8"/>
                    <circle cx="140" cy="46" r=".8"/>
                    <circle cx="138" cy="46" r=".8"/>
                    <circle cx="122" cy="46" r=".8"/>
                    <circle cx="120" cy="46" r=".8"/>
                    <circle cx="118" cy="46" r=".8"/>

                    <circle cx="168" cy="44" r=".8"/>
                    <circle cx="158" cy="44" r=".8"/>
                    <circle cx="156" cy="44" r=".8"/>
                    <circle cx="154" cy="44" r=".8"/>
                    <circle cx="152" cy="44" r=".8"/>
                    <circle cx="142" cy="44" r=".8"/>
                    <circle cx="140" cy="44" r=".8"/>
                    <circle cx="138" cy="44" r=".8"/>
                    <circle cx="124" cy="44" r=".8"/>
                    <circle cx="122" cy="44" r=".8"/>
                    <circle cx="120" cy="44" r=".8"/>
                    <circle cx="118" cy="44" r=".8"/>

                    <circle cx="158" cy="42" r=".8"/>
                    <circle cx="156" cy="42" r=".8"/>
                    <circle cx="154" cy="42" r=".8"/>
                    <circle cx="152" cy="42" r=".8"/>
                    <circle cx="150" cy="42" r=".8"/>
                    <circle cx="144" cy="42" r=".8"/>
                    <circle cx="142" cy="42" r=".8"/>
                    <circle cx="140" cy="42" r=".8"/>
                    <circle cx="138" cy="42" r=".8"/>
                    <circle cx="126" cy="42" r=".8"/>
                    <circle cx="124" cy="42" r=".8"/>
                    <circle cx="122" cy="42" r=".8"/>
                    <circle cx="120" cy="42" r=".8"/>
                    <circle cx="118" cy="42" r=".8"/>
                    <circle cx="116" cy="42" r=".8"/>

                    <circle cx="168" cy="40" r=".8"/>
                    <circle cx="164" cy="40" r=".8"/>
                    <circle cx="160" cy="40" r=".8"/>
                    <circle cx="158" cy="40" r=".8"/>
                    <circle cx="156" cy="40" r=".8"/>
                    <circle cx="154" cy="40" r=".8"/>
                    <circle cx="152" cy="40" r=".8"/>
                    <circle cx="150" cy="40" r=".8"/>
                    <circle cx="148" cy="40" r=".8"/>
                    <circle cx="146" cy="40" r=".8"/>
                    <circle cx="144" cy="40" r=".8"/>
                    <circle cx="142" cy="40" r=".8"/>
                    <circle cx="140" cy="40" r=".8"/>
                    <circle cx="138" cy="40" r=".8"/>
                    <circle cx="136" cy="40" r=".8"/>
                    <circle cx="134" cy="40" r=".8"/>
                    <circle cx="126" cy="40" r=".8"/>
                    <circle cx="124" cy="40" r=".8"/>
                    <circle cx="122" cy="40" r=".8"/>
                    <circle cx="120" cy="40" r=".8"/>
                    <circle cx="118" cy="40" r=".8"/>
                    <circle cx="116" cy="40" r=".8"/>
                    <circle cx="114" cy="40" r=".8"/>

                    <circle cx="166" cy="38" r=".8"/>
                    <circle cx="164" cy="38" r=".8"/>
                    <circle cx="162" cy="38" r=".8"/>
                    <circle cx="160" cy="38" r=".8"/>
                    <circle cx="158" cy="38" r=".8"/>
                    <circle cx="156" cy="38" r=".8"/>
                    <circle cx="154" cy="38" r=".8"/>
                    <circle cx="152" cy="38" r=".8"/>
                    <circle cx="150" cy="38" r=".8"/>
                    <circle cx="148" cy="38" r=".8"/>
                    <circle cx="146" cy="38" r=".8"/>
                    <circle cx="144" cy="38" r=".8"/>
                    <circle cx="142" cy="38" r=".8"/>
                    <circle cx="140" cy="38" r=".8"/>
                    <circle cx="138" cy="38" r=".8"/>
                    <circle cx="136" cy="38" r=".8"/>
                    <circle cx="134" cy="38" r=".8"/>
                    <circle cx="132" cy="38" r=".8"/>
                    <circle cx="130" cy="38" r=".8"/>
                    <circle cx="128" cy="38" r=".8"/>
                    <circle cx="126" cy="38" r=".8"/>
                    <circle cx="120" cy="38" r=".8"/>
                    <circle cx="118" cy="38" r=".8"/>
                    <circle cx="116" cy="38" r=".8"/>
                    <circle cx="114" cy="38" r=".8"/>

                    <circle cx="166" cy="36" r=".8"/>
                    <circle cx="164" cy="36" r=".8"/>
                    <circle cx="162" cy="36" r=".8"/>
                    <circle cx="160" cy="36" r=".8"/>
                    <circle cx="158" cy="36" r=".8"/>
                    <circle cx="156" cy="36" r=".8"/>
                    <circle cx="154" cy="36" r=".8"/>
                    <circle cx="152" cy="36" r=".8"/>
                    <circle cx="150" cy="36" r=".8"/>
                    <circle cx="148" cy="36" r=".8"/>
                    <circle cx="146" cy="36" r=".8"/>
                    <circle cx="144" cy="36" r=".8"/>
                    <circle cx="142" cy="36" r=".8"/>
                    <circle cx="140" cy="36" r=".8"/>
                    <circle cx="138" cy="36" r=".8"/>
                    <circle cx="136" cy="36" r=".8"/>
                    <circle cx="134" cy="36" r=".8"/>
                    <circle cx="132" cy="36" r=".8"/>
                    <circle cx="130" cy="36" r=".8"/>
                    <circle cx="128" cy="36" r=".8"/>
                    <circle cx="126" cy="36" r=".8"/>
                    <circle cx="124" cy="36" r=".8"/>
                    <circle cx="122" cy="36" r=".8"/>
                    <circle cx="120" cy="36" r=".8"/>
                    <circle cx="118" cy="36" r=".8"/>
                    <circle cx="116" cy="36" r=".8"/>
                    <circle cx="114" cy="36" r=".8"/>
                    <circle cx="112" cy="36" r=".8"/>

                    <circle cx="172" cy="34" r=".8"/>
                    <circle cx="164" cy="34" r=".8"/>
                    <circle cx="162" cy="34" r=".8"/>
                    <circle cx="160" cy="34" r=".8"/>
                    <circle cx="158" cy="34" r=".8"/>
                    <circle cx="156" cy="34" r=".8"/>
                    <circle cx="154" cy="34" r=".8"/>
                    <circle cx="152" cy="34" r=".8"/>
                    <circle cx="150" cy="34" r=".8"/>
                    <circle cx="148" cy="34" r=".8"/>
                    <circle cx="146" cy="34" r=".8"/>
                    <circle cx="144" cy="34" r=".8"/>
                    <circle cx="142" cy="34" r=".8"/>
                    <circle cx="140" cy="34" r=".8"/>
                    <circle cx="138" cy="34" r=".8"/>
                    <circle cx="136" cy="34" r=".8"/>
                    <circle cx="134" cy="34" r=".8"/>
                    <circle cx="132" cy="34" r=".8"/>
                    <circle cx="130" cy="34" r=".8"/>
                    <circle cx="128" cy="34" r=".8"/>
                    <circle cx="126" cy="34" r=".8"/>
                    <circle cx="124" cy="34" r=".8"/>
                    <circle cx="122" cy="34" r=".8"/>
                    <circle cx="120" cy="34" r=".8"/>
                    <circle cx="118" cy="34" r=".8"/>
                    <circle cx="116" cy="34" r=".8"/>
                    <circle cx="114" cy="34" r=".8"/>
                    <circle cx="112" cy="34" r=".8"/>

                    <circle cx="176" cy="32" r=".8"/>
                    <circle cx="174" cy="32" r=".8"/>
                    <circle cx="172" cy="32" r=".8"/>
                    <circle cx="164" cy="32" r=".8"/>
                    <circle cx="162" cy="32" r=".8"/>
                    <circle cx="160" cy="32" r=".8"/>
                    <circle cx="158" cy="32" r=".8"/>
                    <circle cx="156" cy="32" r=".8"/>
                    <circle cx="154" cy="32" r=".8"/>
                    <circle cx="152" cy="32" r=".8"/>
                    <circle cx="150" cy="32" r=".8"/>
                    <circle cx="148" cy="32" r=".8"/>
                    <circle cx="146" cy="32" r=".8"/>
                    <circle cx="144" cy="32" r=".8"/>
                    <circle cx="142" cy="32" r=".8"/>
                    <circle cx="140" cy="32" r=".8"/>
                    <circle cx="138" cy="32" r=".8"/>
                    <circle cx="136" cy="32" r=".8"/>
                    <circle cx="134" cy="32" r=".8"/>
                    <circle cx="132" cy="32" r=".8"/>
                    <circle cx="130" cy="32" r=".8"/>
                    <circle cx="128" cy="32" r=".8"/>
                    <circle cx="126" cy="32" r=".8"/>
                    <circle cx="124" cy="32" r=".8"/>
                    <circle cx="122" cy="32" r=".8"/>
                    <circle cx="120" cy="32" r=".8"/>
                    <circle cx="118" cy="32" r=".8"/>
                    <circle cx="116" cy="32" r=".8"/>
                    <circle cx="114" cy="32" r=".8"/>
                    <circle cx="112" cy="32" r=".8"/>
                    <circle cx="110" cy="32" r=".8"/>
                    <circle cx="106" cy="32" r=".8"/>

                    <circle cx="176" cy="30" r=".8"/>
                    <circle cx="168" cy="30" r=".8"/>
                    <circle cx="164" cy="30" r=".8"/>
                    <circle cx="162" cy="30" r=".8"/>
                    <circle cx="160" cy="30" r=".8"/>
                    <circle cx="158" cy="30" r=".8"/>
                    <circle cx="156" cy="30" r=".8"/>
                    <circle cx="154" cy="30" r=".8"/>
                    <circle cx="152" cy="30" r=".8"/>
                    <circle cx="150" cy="30" r=".8"/>
                    <circle cx="148" cy="30" r=".8"/>
                    <circle cx="146" cy="30" r=".8"/>
                    <circle cx="144" cy="30" r=".8"/>
                    <circle cx="142" cy="30" r=".8"/>
                    <circle cx="140" cy="30" r=".8"/>
                    <circle cx="138" cy="30" r=".8"/>
                    <circle cx="136" cy="30" r=".8"/>
                    <circle cx="134" cy="30" r=".8"/>
                    <circle cx="132" cy="30" r=".8"/>
                    <circle cx="130" cy="30" r=".8"/>
                    <circle cx="128" cy="30" r=".8"/>
                    <circle cx="126" cy="30" r=".8"/>
                    <circle cx="124" cy="30" r=".8"/>
                    <circle cx="120" cy="30" r=".8"/>
                    <circle cx="118" cy="30" r=".8"/>
                    <circle cx="116" cy="30" r=".8"/>
                    <circle cx="114" cy="30" r=".8"/>
                    <circle cx="112" cy="30" r=".8"/>
                    <circle cx="110" cy="30" r=".8"/>
                    <circle cx="108" cy="30" r=".8"/>
                    <circle cx="104" cy="30" r=".8"/>
                    <circle cx="98" cy="30" r=".8"/>
                    <circle cx="88" cy="30" r=".8"/>
                    <circle cx="86" cy="30" r=".8"/>
                    <circle cx="84" cy="30" r=".8"/>

                    <circle cx="176" cy="28" r=".8"/>
                    <circle cx="168" cy="28" r=".8"/>
                    <circle cx="166" cy="28" r=".8"/>
                    <circle cx="164" cy="28" r=".8"/>
                    <circle cx="162" cy="28" r=".8"/>
                    <circle cx="160" cy="28" r=".8"/>
                    <circle cx="158" cy="28" r=".8"/>
                    <circle cx="156" cy="28" r=".8"/>
                    <circle cx="154" cy="28" r=".8"/>
                    <circle cx="152" cy="28" r=".8"/>
                    <circle cx="150" cy="28" r=".8"/>
                    <circle cx="148" cy="28" r=".8"/>
                    <circle cx="146" cy="28" r=".8"/>
                    <circle cx="144" cy="28" r=".8"/>
                    <circle cx="142" cy="28" r=".8"/>
                    <circle cx="140" cy="28" r=".8"/>
                    <circle cx="138" cy="28" r=".8"/>
                    <circle cx="136" cy="28" r=".8"/>
                    <circle cx="134" cy="28" r=".8"/>
                    <circle cx="132" cy="28" r=".8"/>
                    <circle cx="130" cy="28" r=".8"/>
                    <circle cx="128" cy="28" r=".8"/>
                    <circle cx="126" cy="28" r=".8"/>
                    <circle cx="124" cy="28" r=".8"/>
                    <circle cx="122" cy="28" r=".8"/>
                    <circle cx="118" cy="28" r=".8"/>
                    <circle cx="116" cy="28" r=".8"/>
                    <circle cx="114" cy="28" r=".8"/>
                    <circle cx="112" cy="28" r=".8"/>
                    <circle cx="110" cy="28" r=".8"/>
                    <circle cx="108" cy="28" r=".8"/>
                    <circle cx="106" cy="28" r=".8"/>
                    <circle cx="104" cy="28" r=".8"/>
                    <circle cx="102" cy="28" r=".8"/>
                    <circle cx="100" cy="28" r=".8"/>
                    <circle cx="88" cy="28" r=".8"/>
                    <circle cx="86" cy="28" r=".8"/>
                    <circle cx="84" cy="28" r=".8"/>

                    <circle cx="176" cy="26" r=".8"/>
                    <circle cx="174" cy="26" r=".8"/>
                    <circle cx="170" cy="26" r=".8"/>
                    <circle cx="168" cy="26" r=".8"/>
                    <circle cx="166" cy="26" r=".8"/>
                    <circle cx="164" cy="26" r=".8"/>
                    <circle cx="162" cy="26" r=".8"/>
                    <circle cx="160" cy="26" r=".8"/>
                    <circle cx="158" cy="26" r=".8"/>
                    <circle cx="156" cy="26" r=".8"/>
                    <circle cx="154" cy="26" r=".8"/>
                    <circle cx="152" cy="26" r=".8"/>
                    <circle cx="150" cy="26" r=".8"/>
                    <circle cx="148" cy="26" r=".8"/>
                    <circle cx="146" cy="26" r=".8"/>
                    <circle cx="144" cy="26" r=".8"/>
                    <circle cx="142" cy="26" r=".8"/>
                    <circle cx="140" cy="26" r=".8"/>
                    <circle cx="138" cy="26" r=".8"/>
                    <circle cx="136" cy="26" r=".8"/>
                    <circle cx="134" cy="26" r=".8"/>
                    <circle cx="132" cy="26" r=".8"/>
                    <circle cx="130" cy="26" r=".8"/>
                    <circle cx="128" cy="26" r=".8"/>
                    <circle cx="126" cy="26" r=".8"/>
                    <circle cx="124" cy="26" r=".8"/>
                    <circle cx="122" cy="26" r=".8"/>
                    <circle cx="118" cy="26" r=".8"/>
                    <circle cx="116" cy="26" r=".8"/>
                    <circle cx="114" cy="26" r=".8"/>
                    <circle cx="106" cy="26" r=".8"/>
                    <circle cx="104" cy="26" r=".8"/>
                    <circle cx="102" cy="26" r=".8"/>
                    <circle cx="100" cy="26" r=".8"/>
                    <circle cx="98" cy="26" r=".8"/>
                    <circle cx="96" cy="26" r=".8"/>
                    <circle cx="90" cy="26" r=".8"/>
                    <circle cx="88" cy="26" r=".8"/>
                    <circle cx="86" cy="26" r=".8"/>

                    <circle cx="170" cy="24" r=".8"/>
                    <circle cx="168" cy="24" r=".8"/>
                    <circle cx="166" cy="24" r=".8"/>
                    <circle cx="164" cy="24" r=".8"/>
                    <circle cx="162" cy="24" r=".8"/>
                    <circle cx="160" cy="24" r=".8"/>
                    <circle cx="158" cy="24" r=".8"/>
                    <circle cx="156" cy="24" r=".8"/>
                    <circle cx="154" cy="24" r=".8"/>
                    <circle cx="152" cy="24" r=".8"/>
                    <circle cx="150" cy="24" r=".8"/>
                    <circle cx="148" cy="24" r=".8"/>
                    <circle cx="146" cy="24" r=".8"/>
                    <circle cx="144" cy="24" r=".8"/>
                    <circle cx="142" cy="24" r=".8"/>
                    <circle cx="140" cy="24" r=".8"/>
                    <circle cx="138" cy="24" r=".8"/>
                    <circle cx="136" cy="24" r=".8"/>
                    <circle cx="134" cy="24" r=".8"/>
                    <circle cx="132" cy="24" r=".8"/>
                    <circle cx="130" cy="24" r=".8"/>
                    <circle cx="128" cy="24" r=".8"/>
                    <circle cx="126" cy="24" r=".8"/>
                    <circle cx="124" cy="24" r=".8"/>
                    <circle cx="122" cy="24" r=".8"/>
                    <circle cx="118" cy="24" r=".8"/>
                    <circle cx="116" cy="24" r=".8"/>
                    <circle cx="114" cy="24" r=".8"/>
                    <circle cx="110" cy="24" r=".8"/>
                    <circle cx="106" cy="24" r=".8"/>
                    <circle cx="104" cy="24" r=".8"/>
                    <circle cx="102" cy="24" r=".8"/>
                    <circle cx="100" cy="24" r=".8"/>
                    <circle cx="98" cy="24" r=".8"/>
                    <circle cx="96" cy="24" r=".8"/>
                    <circle cx="94" cy="24" r=".8"/>
                    <circle cx="92" cy="24" r=".8"/>
                    <circle cx="90" cy="24" r=".8"/>

                    <circle cx="170" cy="22" r=".8"/>
                    <circle cx="168" cy="22" r=".8"/>
                    <circle cx="166" cy="22" r=".8"/>
                    <circle cx="164" cy="22" r=".8"/>
                    <circle cx="162" cy="22" r=".8"/>
                    <circle cx="160" cy="22" r=".8"/>
                    <circle cx="158" cy="22" r=".8"/>
                    <circle cx="156" cy="22" r=".8"/>
                    <circle cx="154" cy="22" r=".8"/>
                    <circle cx="152" cy="22" r=".8"/>
                    <circle cx="150" cy="22" r=".8"/>
                    <circle cx="148" cy="22" r=".8"/>
                    <circle cx="146" cy="22" r=".8"/>
                    <circle cx="144" cy="22" r=".8"/>
                    <circle cx="142" cy="22" r=".8"/>
                    <circle cx="140" cy="22" r=".8"/>
                    <circle cx="138" cy="22" r=".8"/>
                    <circle cx="136" cy="22" r=".8"/>
                    <circle cx="134" cy="22" r=".8"/>
                    <circle cx="132" cy="22" r=".8"/>
                    <circle cx="130" cy="22" r=".8"/>
                    <circle cx="128" cy="22" r=".8"/>
                    <circle cx="126" cy="22" r=".8"/>
                    <circle cx="124" cy="22" r=".8"/>
                    <circle cx="122" cy="22" r=".8"/>
                    <circle cx="120" cy="22" r=".8"/>
                    <circle cx="118" cy="22" r=".8"/>
                    <circle cx="116" cy="22" r=".8"/>
                    <circle cx="114" cy="22" r=".8"/>
                    <circle cx="112" cy="22" r=".8"/>
                    <circle cx="110" cy="22" r=".8"/>
                    <circle cx="108" cy="22" r=".8"/>
                    <circle cx="106" cy="22" r=".8"/>
                    <circle cx="104" cy="22" r=".8"/>
                    <circle cx="102" cy="22" r=".8"/>
                    <circle cx="100" cy="22" r=".8"/>
                    <circle cx="98" cy="22" r=".8"/>
                    <circle cx="96" cy="22" r=".8"/>
                    <circle cx="94" cy="22" r=".8"/>
                    <circle cx="90" cy="22" r=".8"/>
                    <circle cx="92" cy="22" r=".8"/>

                    <circle cx="168" cy="20" r=".8"/>
                    <circle cx="166" cy="20" r=".8"/>
                    <circle cx="164" cy="20" r=".8"/>
                    <circle cx="162" cy="20" r=".8"/>
                    <circle cx="160" cy="20" r=".8"/>
                    <circle cx="158" cy="20" r=".8"/>
                    <circle cx="156" cy="20" r=".8"/>
                    <circle cx="154" cy="20" r=".8"/>
                    <circle cx="152" cy="20" r=".8"/>
                    <circle cx="150" cy="20" r=".8"/>
                    <circle cx="148" cy="20" r=".8"/>
                    <circle cx="146" cy="20" r=".8"/>
                    <circle cx="144" cy="20" r=".8"/>
                    <circle cx="142" cy="20" r=".8"/>
                    <circle cx="140" cy="20" r=".8"/>
                    <circle cx="138" cy="20" r=".8"/>
                    <circle cx="136" cy="20" r=".8"/>
                    <circle cx="134" cy="20" r=".8"/>
                    <circle cx="132" cy="20" r=".8"/>
                    <circle cx="130" cy="20" r=".8"/>
                    <circle cx="128" cy="20" r=".8"/>
                    <circle cx="126" cy="20" r=".8"/>
                    <circle cx="124" cy="20" r=".8"/>
                    <circle cx="122" cy="20" r=".8"/>
                    <circle cx="120" cy="20" r=".8"/>
                    <circle cx="118" cy="20" r=".8"/>
                    <circle cx="116" cy="20" r=".8"/>
                    <circle cx="114" cy="20" r=".8"/>
                    <circle cx="112" cy="20" r=".8"/>
                    <circle cx="110" cy="20" r=".8"/>
                    <circle cx="108" cy="20" r=".8"/>
                    <circle cx="106" cy="20" r=".8"/>
                    <circle cx="104" cy="20" r=".8"/>
                    <circle cx="102" cy="20" r=".8"/>
                    <circle cx="100" cy="20" r=".8"/>
                    <circle cx="98" cy="20" r=".8"/>
                    <circle cx="96" cy="20" r=".8"/>
                    <circle cx="94" cy="20" r=".8"/>
                    <circle cx="92" cy="20" r=".8"/>

                    <circle cx="178" cy="18" r=".8"/>
                    <circle cx="164" cy="18" r=".8"/>
                    <circle cx="162" cy="18" r=".8"/>
                    <circle cx="160" cy="18" r=".8"/>
                    <circle cx="158" cy="18" r=".8"/>
                    <circle cx="156" cy="18" r=".8"/>
                    <circle cx="154" cy="18" r=".8"/>
                    <circle cx="152" cy="18" r=".8"/>
                    <circle cx="150" cy="18" r=".8"/>
                    <circle cx="148" cy="18" r=".8"/>
                    <circle cx="146" cy="18" r=".8"/>
                    <circle cx="144" cy="18" r=".8"/>
                    <circle cx="142" cy="18" r=".8"/>
                    <circle cx="140" cy="18" r=".8"/>
                    <circle cx="138" cy="18" r=".8"/>
                    <circle cx="136" cy="18" r=".8"/>
                    <circle cx="134" cy="18" r=".8"/>
                    <circle cx="132" cy="18" r=".8"/>
                    <circle cx="130" cy="18" r=".8"/>
                    <circle cx="128" cy="18" r=".8"/>
                    <circle cx="126" cy="18" r=".8"/>
                    <circle cx="124" cy="18" r=".8"/>
                    <circle cx="122" cy="18" r=".8"/>
                    <circle cx="120" cy="18" r=".8"/>
                    <circle cx="118" cy="18" r=".8"/>
                    <circle cx="116" cy="18" r=".8"/>
                    <circle cx="114" cy="18" r=".8"/>
                    <circle cx="112" cy="18" r=".8"/>
                    <circle cx="110" cy="18" r=".8"/>
                    <circle cx="108" cy="18" r=".8"/>
                    <circle cx="106" cy="18" r=".8"/>
                    <circle cx="104" cy="18" r=".8"/>
                    <circle cx="102" cy="18" r=".8"/>
                    <circle cx="100" cy="18" r=".8"/>
                    <circle cx="96" cy="18" r=".8"/>

                    <circle cx="178" cy="16" r=".8"/>
                    <circle cx="176" cy="16" r=".8"/>
                    <circle cx="164" cy="16" r=".8"/>
                    <circle cx="162" cy="16" r=".8"/>
                    <circle cx="160" cy="16" r=".8"/>
                    <circle cx="158" cy="16" r=".8"/>
                    <circle cx="156" cy="16" r=".8"/>
                    <circle cx="154" cy="16" r=".8"/>
                    <circle cx="152" cy="16" r=".8"/>
                    <circle cx="150" cy="16" r=".8"/>
                    <circle cx="148" cy="16" r=".8"/>
                    <circle cx="146" cy="16" r=".8"/>
                    <circle cx="144" cy="16" r=".8"/>
                    <circle cx="142" cy="16" r=".8"/>
                    <circle cx="140" cy="16" r=".8"/>
                    <circle cx="138" cy="16" r=".8"/>
                    <circle cx="136" cy="16" r=".8"/>
                    <circle cx="134" cy="16" r=".8"/>
                    <circle cx="132" cy="16" r=".8"/>
                    <circle cx="130" cy="16" r=".8"/>
                    <circle cx="128" cy="16" r=".8"/>
                    <circle cx="126" cy="16" r=".8"/>
                    <circle cx="124" cy="16" r=".8"/>
                    <circle cx="122" cy="16" r=".8"/>
                    <circle cx="120" cy="16" r=".8"/>
                    <circle cx="118" cy="16" r=".8"/>
                    <circle cx="116" cy="16" r=".8"/>
                    <circle cx="114" cy="16" r=".8"/>
                    <circle cx="112" cy="16" r=".8"/>
                    <circle cx="110" cy="16" r=".8"/>
                    <circle cx="108" cy="16" r=".8"/>
                    <circle cx="106" cy="16" r=".8"/>
                    <circle cx="104" cy="16" r=".8"/>
                    <circle cx="102" cy="16" r=".8"/>
                    <circle cx="98" cy="16" r=".8"/>

                    <circle cx="180" cy="14" r=".8"/>
                    <circle cx="178" cy="14" r=".8"/>
                    <circle cx="176" cy="14" r=".8"/>
                    <circle cx="172" cy="14" r=".8"/>
                    <circle cx="170" cy="14" r=".8"/>
                    <circle cx="168" cy="14" r=".8"/>
                    <circle cx="166" cy="14" r=".8"/>
                    <circle cx="164" cy="14" r=".8"/>
                    <circle cx="162" cy="14" r=".8"/>
                    <circle cx="160" cy="14" r=".8"/>
                    <circle cx="158" cy="14" r=".8"/>
                    <circle cx="156" cy="14" r=".8"/>
                    <circle cx="154" cy="14" r=".8"/>
                    <circle cx="152" cy="14" r=".8"/>
                    <circle cx="150" cy="14" r=".8"/>
                    <circle cx="148" cy="14" r=".8"/>
                    <circle cx="146" cy="14" r=".8"/>
                    <circle cx="144" cy="14" r=".8"/>
                    <circle cx="142" cy="14" r=".8"/>
                    <circle cx="140" cy="14" r=".8"/>
                    <circle cx="138" cy="14" r=".8"/>
                    <circle cx="136" cy="14" r=".8"/>
                    <circle cx="134" cy="14" r=".8"/>
                    <circle cx="132" cy="14" r=".8"/>
                    <circle cx="130" cy="14" r=".8"/>
                    <circle cx="128" cy="14" r=".8"/>
                    <circle cx="126" cy="14" r=".8"/>
                    <circle cx="124" cy="14" r=".8"/>
                    <circle cx="122" cy="14" r=".8"/>
                    <circle cx="120" cy="14" r=".8"/>
                    <circle cx="118" cy="14" r=".8"/>
                    <circle cx="116" cy="14" r=".8"/>
                    <circle cx="114" cy="14" r=".8"/>
                    <circle cx="112" cy="14" r=".8"/>
                    <circle cx="110" cy="14" r=".8"/>
                    <circle cx="108" cy="14" r=".8"/>
                    <circle cx="106" cy="14" r=".8"/>
                    <circle cx="104" cy="14" r=".8"/>
                    <circle cx="102" cy="14" r=".8"/>
                    <circle cx="100" cy="14" r=".8"/>
                    <circle cx="98" cy="14" r=".8"/>
                    <circle cx="96" cy="14" r=".8"/>

                    <circle cx="180" cy="12" r=".8"/>
                    <circle cx="178" cy="12" r=".8"/>
                    <circle cx="176" cy="12" r=".8"/>
                    <circle cx="174" cy="12" r=".8"/>
                    <circle cx="172" cy="12" r=".8"/>
                    <circle cx="170" cy="12" r=".8"/>
                    <circle cx="168" cy="12" r=".8"/>
                    <circle cx="166" cy="12" r=".8"/>
                    <circle cx="164" cy="12" r=".8"/>
                    <circle cx="162" cy="12" r=".8"/>
                    <circle cx="160" cy="12" r=".8"/>
                    <circle cx="158" cy="12" r=".8"/>
                    <circle cx="156" cy="12" r=".8"/>
                    <circle cx="154" cy="12" r=".8"/>
                    <circle cx="152" cy="12" r=".8"/>
                    <circle cx="150" cy="12" r=".8"/>
                    <circle cx="148" cy="12" r=".8"/>
                    <circle cx="146" cy="12" r=".8"/>
                    <circle cx="144" cy="12" r=".8"/>
                    <circle cx="142" cy="12" r=".8"/>
                    <circle cx="140" cy="12" r=".8"/>
                    <circle cx="138" cy="12" r=".8"/>
                    <circle cx="136" cy="12" r=".8"/>
                    <circle cx="134" cy="12" r=".8"/>
                    <circle cx="132" cy="12" r=".8"/>
                    <circle cx="130" cy="12" r=".8"/>
                    <circle cx="128" cy="12" r=".8"/>
                    <circle cx="126" cy="12" r=".8"/>
                    <circle cx="124" cy="12" r=".8"/>
                    <circle cx="122" cy="12" r=".8"/>
                    <circle cx="120" cy="12" r=".8"/>
                    <circle cx="118" cy="12" r=".8"/>
                    <circle cx="116" cy="12" r=".8"/>
                    <circle cx="114" cy="12" r=".8"/>
                    <circle cx="112" cy="12" r=".8"/>
                    <circle cx="110" cy="12" r=".8"/>
                    <circle cx="108" cy="12" r=".8"/>
                    <circle cx="106" cy="12" r=".8"/>
                    <circle cx="104" cy="12" r=".8"/>
                    <circle cx="102" cy="12" r=".8"/>
                    <circle cx="100" cy="12" r=".8"/>
                    <circle cx="98" cy="12" r=".8"/>
                    <circle cx="96" cy="12" r=".8"/>

                    <circle cx="182" cy="10" r=".8"/>
                    <circle cx="180" cy="10" r=".8"/>
                    <circle cx="178" cy="10" r=".8"/>
                    <circle cx="176" cy="10" r=".8"/>
                    <circle cx="174" cy="10" r=".8"/>
                    <circle cx="172" cy="10" r=".8"/>
                    <circle cx="170" cy="10" r=".8"/>
                    <circle cx="168" cy="10" r=".8"/>
                    <circle cx="166" cy="10" r=".8"/>
                    <circle cx="164" cy="10" r=".8"/>
                    <circle cx="162" cy="10" r=".8"/>
                    <circle cx="160" cy="10" r=".8"/>
                    <circle cx="158" cy="10" r=".8"/>
                    <circle cx="156" cy="10" r=".8"/>
                    <circle cx="154" cy="10" r=".8"/>
                    <circle cx="152" cy="10" r=".8"/>
                    <circle cx="150" cy="10" r=".8"/>
                    <circle cx="148" cy="10" r=".8"/>
                    <circle cx="146" cy="10" r=".8"/>
                    <circle cx="144" cy="10" r=".8"/>
                    <circle cx="142" cy="10" r=".8"/>
                    <circle cx="140" cy="10" r=".8"/>
                    <circle cx="138" cy="10" r=".8"/>
                    <circle cx="136" cy="10" r=".8"/>
                    <circle cx="134" cy="10" r=".8"/>
                    <circle cx="132" cy="10" r=".8"/>
                    <circle cx="130" cy="10" r=".8"/>
                    <circle cx="128" cy="10" r=".8"/>
                    <circle cx="126" cy="10" r=".8"/>
                    <circle cx="124" cy="10" r=".8"/>
                    <circle cx="122" cy="10" r=".8"/>
                    <circle cx="120" cy="10" r=".8"/>
                    <circle cx="118" cy="10" r=".8"/>
                    <circle cx="116" cy="10" r=".8"/>
                    <circle cx="114" cy="10" r=".8"/>
                    <circle cx="110" cy="10" r=".8"/>
                    <circle cx="108" cy="10" r=".8"/>
                    <circle cx="106" cy="10" r=".8"/>
                    <circle cx="104" cy="10" r=".8"/>
                    <circle cx="102" cy="10" r=".8"/>
                    <circle cx="100" cy="10" r=".8"/>
                    <circle cx="98" cy="10" r=".8"/>

                    <circle cx="166" cy="8" r=".8"/>
                    <circle cx="164" cy="8" r=".8"/>
                    <circle cx="162" cy="8" r=".8"/>
                    <circle cx="160" cy="8" r=".8"/>
                    <circle cx="158" cy="8" r=".8"/>
                    <circle cx="156" cy="8" r=".8"/>
                    <circle cx="154" cy="8" r=".8"/>
                    <circle cx="152" cy="8" r=".8"/>
                    <circle cx="150" cy="8" r=".8"/>
                    <circle cx="148" cy="8" r=".8"/>
                    <circle cx="146" cy="8" r=".8"/>
                    <circle cx="144" cy="8" r=".8"/>
                    <circle cx="142" cy="8" r=".8"/>
                    <circle cx="140" cy="8" r=".8"/>
                    <circle cx="138" cy="8" r=".8"/>
                    <circle cx="136" cy="8" r=".8"/>
                    <circle cx="134" cy="8" r=".8"/>
                    <circle cx="132" cy="8" r=".8"/>
                    <circle cx="130" cy="8" r=".8"/>
                    <circle cx="128" cy="8" r=".8"/>
                    <circle cx="126" cy="8" r=".8"/>
                    <circle cx="124" cy="8" r=".8"/>
                    <circle cx="106" cy="8" r=".8"/>
                    <circle cx="104" cy="8" r=".8"/>
                    <circle cx="102" cy="8" r=".8"/>

                    <circle cx="148" cy="6" r=".8"/>
                    <circle cx="144" cy="6" r=".8"/>
                    <circle cx="142" cy="6" r=".8"/>
                    <circle cx="140" cy="6" r=".8"/>
                    <circle cx="138" cy="6" r=".8"/>
                    <circle cx="136" cy="6" r=".8"/>
                    <circle cx="134" cy="6" r=".8"/>
                    <circle cx="132" cy="6" r=".8"/>
                    <circle cx="130" cy="6" r=".8"/>

                    <circle cx="136" cy="4" r=".8"/>

                </motion.g>
                <motion.g
                    variants={bottomVariants}
                    initial='hidden'
                    animate='visible'
                    fill="rgba(255,255,255,0.15)"
                >
                    <circle cx="178" cy="88" r=".8"/>

                    <circle cx="182" cy="84" r=".8"/>
                    <circle cx="180" cy="84" r=".8"/>
                    <circle cx="178" cy="84" r=".8"/>

                    <circle cx="182" cy="82" r=".8"/>
                    <circle cx="180" cy="82" r=".8"/>
                    <circle cx="178" cy="82" r=".8"/>
                    <circle cx="176" cy="82" r=".8"/>
                    <circle cx="164" cy="82" r=".8"/>
                    <circle cx="162" cy="82" r=".8"/>

                    <circle cx="186" cy="80" r=".8"/>
                    <circle cx="184" cy="80" r=".8"/>
                    <circle cx="182" cy="80" r=".8"/>
                    <circle cx="180" cy="80" r=".8"/>
                    <circle cx="178" cy="80" r=".8"/>
                    <circle cx="176" cy="80" r=".8"/>
                    <circle cx="174" cy="80" r=".8"/>
                    <circle cx="170" cy="80" r=".8"/>
                    <circle cx="168" cy="80" r=".8"/>
                    <circle cx="166" cy="80" r=".8"/>
                    <circle cx="164" cy="80" r=".8"/>
                    <circle cx="106" cy="80" r=".8"/>
                    <circle cx="104" cy="80" r=".8"/>
                    <circle cx="102" cy="80" r=".8"/>

                    <circle cx="186" cy="78" r=".8"/>
                    <circle cx="184" cy="78" r=".8"/>
                    <circle cx="182" cy="78" r=".8"/>
                    <circle cx="180" cy="78" r=".8"/>
                    <circle cx="108" cy="78" r=".8"/>
                    <circle cx="106" cy="78" r=".8"/>
                    <circle cx="104" cy="78" r=".8"/>
                    <circle cx="102" cy="78" r=".8"/>
                    <circle cx="100" cy="78" r=".8"/>
                    <circle cx="178" cy="78" r=".8"/>
                    <circle cx="176" cy="78" r=".8"/>
                    <circle cx="174" cy="78" r=".8"/>
                    <circle cx="172" cy="78" r=".8"/>
                    <circle cx="170" cy="78" r=".8"/>
                    <circle cx="168" cy="78" r=".8"/>
                    <circle cx="166" cy="78" r=".8"/>
                    <circle cx="164" cy="78" r=".8"/>

                    <circle cx="186" cy="76" r=".8"/>
                    <circle cx="184" cy="76" r=".8"/>
                    <circle cx="182" cy="76" r=".8"/>
                    <circle cx="180" cy="76" r=".8"/>
                    <circle cx="178" cy="76" r=".8"/>
                    <circle cx="176" cy="76" r=".8"/>
                    <circle cx="174" cy="76" r=".8"/>
                    <circle cx="172" cy="76" r=".8"/>
                    <circle cx="170" cy="76" r=".8"/>
                    <circle cx="168" cy="76" r=".8"/>
                    <circle cx="166" cy="76" r=".8"/>
                    <circle cx="164" cy="76" r=".8"/>
                    <circle cx="110" cy="76" r=".8"/>
                    <circle cx="108" cy="76" r=".8"/>
                    <circle cx="106" cy="76" r=".8"/>
                    <circle cx="104" cy="76" r=".8"/>
                    <circle cx="102" cy="76" r=".8"/>
                    <circle cx="100" cy="76" r=".8"/>   

                    <circle cx="186" cy="74" r=".8"/>
                    <circle cx="184" cy="74" r=".8"/>
                    <circle cx="182" cy="74" r=".8"/>
                    <circle cx="180" cy="74" r=".8"/>
                    <circle cx="178" cy="74" r=".8"/>
                    <circle cx="176" cy="74" r=".8"/>
                    <circle cx="174" cy="74" r=".8"/>
                    <circle cx="172" cy="74" r=".8"/>
                    <circle cx="170" cy="74" r=".8"/>
                    <circle cx="168" cy="74" r=".8"/>
                    <circle cx="166" cy="74" r=".8"/>
                    <circle cx="164" cy="74" r=".8"/>
                    <circle cx="120" cy="74" r=".8"/>
                    <circle cx="118" cy="74" r=".8"/>
                    <circle cx="112" cy="74" r=".8"/>
                    <circle cx="110" cy="74" r=".8"/>
                    <circle cx="108" cy="74" r=".8"/>
                    <circle cx="106" cy="74" r=".8"/>
                    <circle cx="104" cy="74" r=".8"/>
                    <circle cx="102" cy="74" r=".8"/>
                    <circle cx="100" cy="74" r=".8"/>

                    <circle cx="184" cy="72" r=".8"/>
                    <circle cx="182" cy="72" r=".8"/>
                    <circle cx="180" cy="72" r=".8"/>
                    <circle cx="178" cy="72" r=".8"/>
                    <circle cx="176" cy="72" r=".8"/>
                    <circle cx="174" cy="72" r=".8"/>
                    <circle cx="172" cy="72" r=".8"/>
                    <circle cx="170" cy="72" r=".8"/>
                    <circle cx="168" cy="72" r=".8"/>
                    <circle cx="166" cy="72" r=".8"/>
                    <circle cx="120" cy="72" r=".8"/>
                    <circle cx="118" cy="72" r=".8"/>
                    <circle cx="110" cy="72" r=".8"/>
                    <circle cx="108" cy="72" r=".8"/>
                    <circle cx="106" cy="72" r=".8"/>
                    <circle cx="104" cy="72" r=".8"/>
                    <circle cx="102" cy="72" r=".8"/>
                    <circle cx="100" cy="72" r=".8"/>
                    <circle cx="98" cy="72" r=".8"/>

                    <circle cx="184" cy="70" r=".8"/>
                    <circle cx="182" cy="70" r=".8"/>
                    <circle cx="180" cy="70" r=".8"/>
                    <circle cx="178" cy="70" r=".8"/>
                    <circle cx="176" cy="70" r=".8"/>
                    <circle cx="174" cy="70" r=".8"/>
                    <circle cx="172" cy="70" r=".8"/>
                    <circle cx="170" cy="70" r=".8"/>
                    <circle cx="120" cy="70" r=".8"/>
                    <circle cx="118" cy="70" r=".8"/>
                    <circle cx="112" cy="70" r=".8"/>
                    <circle cx="110" cy="70" r=".8"/>
                    <circle cx="108" cy="70" r=".8"/>
                    <circle cx="106" cy="70" r=".8"/>
                    <circle cx="104" cy="70" r=".8"/>
                    <circle cx="102" cy="70" r=".8"/>
                    <circle cx="100" cy="70" r=".8"/>
                    <circle cx="98" cy="70" r=".8"/>

                    <circle cx="184" cy="68" r=".8"/>
                    <circle cx="182" cy="68" r=".8"/>
                    <circle cx="178" cy="68" r=".8"/>
                    <circle cx="176" cy="68" r=".8"/>
                    <circle cx="174" cy="68" r=".8"/>
                    <circle cx="172" cy="68" r=".8"/>
                    <circle cx="122" cy="68" r=".8"/>
                    <circle cx="120" cy="68" r=".8"/>
                    <circle cx="114" cy="68" r=".8"/>
                    <circle cx="112" cy="68" r=".8"/>
                    <circle cx="110" cy="68" r=".8"/>
                    <circle cx="108" cy="68" r=".8"/>
                    <circle cx="106" cy="68" r=".8"/>
                    <circle cx="104" cy="68" r=".8"/>
                    <circle cx="102" cy="68" r=".8"/>
                    <circle cx="100" cy="68" r=".8"/>
                    <circle cx="98" cy="68" r=".8"/>

                    <circle cx="178" cy="66" r=".8"/>
                    <circle cx="176" cy="66" r=".8"/>
                    <circle cx="114" cy="66" r=".8"/>
                    <circle cx="112" cy="66" r=".8"/>
                    <circle cx="110" cy="66" r=".8"/>
                    <circle cx="108" cy="66" r=".8"/>
                    <circle cx="106" cy="66" r=".8"/>
                    <circle cx="104" cy="66" r=".8"/>
                    <circle cx="102" cy="66" r=".8"/>
                    <circle cx="100" cy="66" r=".8"/>
                    <circle cx="98" cy="66" r=".8"/>

                    <circle cx="126" cy="64" r=".8"/>
                    <circle cx="114" cy="64" r=".8"/>
                    <circle cx="112" cy="64" r=".8"/>
                    <circle cx="110" cy="64" r=".8"/>
                    <circle cx="108" cy="64" r=".8"/>
                    <circle cx="106" cy="64" r=".8"/>
                    <circle cx="104" cy="64" r=".8"/>
                    <circle cx="102" cy="64" r=".8"/>
                    <circle cx="100" cy="64" r=".8"/>
                    <circle cx="98" cy="64" r=".8"/>

                    <circle cx="114" cy="62" r=".8"/>
                    <circle cx="112" cy="62" r=".8"/>
                    <circle cx="110" cy="62" r=".8"/>
                    <circle cx="108" cy="62" r=".8"/>
                    <circle cx="106" cy="62" r=".8"/>
                    <circle cx="104" cy="62" r=".8"/>
                    <circle cx="102" cy="62" r=".8"/>
                    <circle cx="100" cy="62" r=".8"/>
                    <circle cx="98" cy="62" r=".8"/>

                    <circle cx="114" cy="60" r=".8"/>
                    <circle cx="112" cy="60" r=".8"/>
                    <circle cx="110" cy="60" r=".8"/>
                    <circle cx="108" cy="60" r=".8"/>
                    <circle cx="106" cy="60" r=".8"/>
                    <circle cx="104" cy="60" r=".8"/>
                    <circle cx="102" cy="60" r=".8"/>
                    <circle cx="100" cy="60" r=".8"/>
                    <circle cx="98" cy="60" r=".8"/>

                    <circle cx="116" cy="58" r=".8"/>
                    <circle cx="114" cy="58" r=".8"/>
                    <circle cx="112" cy="58" r=".8"/>
                    <circle cx="110" cy="58" r=".8"/>
                    <circle cx="108" cy="58" r=".8"/>
                    <circle cx="106" cy="58" r=".8"/>
                    <circle cx="104" cy="58" r=".8"/>
                    <circle cx="102" cy="58" r=".8"/>
                    <circle cx="100" cy="58" r=".8"/>
                    <circle cx="98" cy="58" r=".8"/>
                    <circle cx="96" cy="58" r=".8"/>

                    <circle cx="118" cy="56" r=".8"/>
                    <circle cx="116" cy="56" r=".8"/>
                    <circle cx="114" cy="56" r=".8"/>
                    <circle cx="112" cy="56" r=".8"/>
                    <circle cx="110" cy="56" r=".8"/>
                    <circle cx="108" cy="56" r=".8"/>
                    <circle cx="106" cy="56" r=".8"/>
                    <circle cx="104" cy="56" r=".8"/>
                    <circle cx="102" cy="56" r=".8"/>
                    <circle cx="100" cy="56" r=".8"/>
                    <circle cx="98" cy="56" r=".8"/>
                    <circle cx="96" cy="56" r=".8"/>

                    <circle cx="120" cy="54" r=".8"/>
                    <circle cx="118" cy="54" r=".8"/>
                    <circle cx="116" cy="54" r=".8"/>
                    <circle cx="114" cy="54" r=".8"/>
                    <circle cx="112" cy="54" r=".8"/>
                    <circle cx="110" cy="54" r=".8"/>
                    <circle cx="108" cy="54" r=".8"/>
                    <circle cx="106" cy="54" r=".8"/>
                    <circle cx="104" cy="54" r=".8"/>
                    <circle cx="102" cy="54" r=".8"/>
                    <circle cx="100" cy="54" r=".8"/>
                    <circle cx="98" cy="54" r=".8"/>
                    <circle cx="96" cy="54" r=".8"/>

                    <circle cx="120" cy="52" r=".8"/>
                    <circle cx="118" cy="52" r=".8"/>
                    <circle cx="116" cy="52" r=".8"/>
                    <circle cx="114" cy="52" r=".8"/>
                    <circle cx="112" cy="52" r=".8"/>
                    <circle cx="110" cy="52" r=".8"/>
                    <circle cx="108" cy="52" r=".8"/>
                    <circle cx="106" cy="52" r=".8"/>
                    <circle cx="104" cy="52" r=".8"/>
                    <circle cx="102" cy="52" r=".8"/>
                    <circle cx="100" cy="52" r=".8"/>
                    <circle cx="98" cy="52" r=".8"/>
                    <circle cx="96" cy="52" r=".8"/>
                    <circle cx="94" cy="52" r=".8"/>
                    <circle cx="92" cy="52" r=".8"/>
                    <circle cx="90" cy="52" r=".8"/>
                    <circle cx="88" cy="52" r=".8"/>
                    <circle cx="86" cy="52" r=".8"/>
                    <circle cx="84" cy="52" r=".8"/>
                    <circle cx="82" cy="52" r=".8"/>

                    <circle cx="122" cy="50" r=".8"/>
                    <circle cx="120" cy="50" r=".8"/>
                    <circle cx="118" cy="50" r=".8"/>
                    <circle cx="116" cy="50" r=".8"/>
                    <circle cx="114" cy="50" r=".8"/>
                    <circle cx="112" cy="50" r=".8"/>
                    <circle cx="110" cy="50" r=".8"/>
                    <circle cx="108" cy="50" r=".8"/>
                    <circle cx="106" cy="50" r=".8"/>
                    <circle cx="104" cy="50" r=".8"/>
                    <circle cx="102" cy="50" r=".8"/>
                    <circle cx="100" cy="50" r=".8"/>
                    <circle cx="98" cy="50" r=".8"/>
                    <circle cx="96" cy="50" r=".8"/>
                    <circle cx="94" cy="50" r=".8"/>
                    <circle cx="92" cy="50" r=".8"/>
                    <circle cx="90" cy="50" r=".8"/>
                    <circle cx="88" cy="50" r=".8"/>
                    <circle cx="86" cy="50" r=".8"/>
                    <circle cx="84" cy="50" r=".8"/>
                    <circle cx="82" cy="50" r=".8"/>
                    <circle cx="80" cy="50" r=".8"/>

                    <circle cx="116" cy="48" r=".8"/>
                    <circle cx="114" cy="48" r=".8"/>
                    <circle cx="112" cy="48" r=".8"/>
                    <circle cx="110" cy="48" r=".8"/>
                    <circle cx="108" cy="48" r=".8"/>
                    <circle cx="106" cy="48" r=".8"/>
                    <circle cx="104" cy="48" r=".8"/>
                    <circle cx="102" cy="48" r=".8"/>
                    <circle cx="100" cy="48" r=".8"/>
                    <circle cx="98" cy="48" r=".8"/>
                    <circle cx="96" cy="48" r=".8"/>
                    <circle cx="94" cy="48" r=".8"/>
                    <circle cx="92" cy="48" r=".8"/>
                    <circle cx="90" cy="48" r=".8"/>
                    <circle cx="88" cy="48" r=".8"/>
                    <circle cx="86" cy="48" r=".8"/>
                    <circle cx="84" cy="48" r=".8"/>
                    <circle cx="82" cy="48" r=".8"/>
                    <circle cx="80" cy="48" r=".8"/>

                    <circle cx="114" cy="46" r=".8"/>
                    <circle cx="112" cy="46" r=".8"/>
                    <circle cx="110" cy="46" r=".8"/>
                    <circle cx="108" cy="46" r=".8"/>
                    <circle cx="106" cy="46" r=".8"/>
                    <circle cx="104" cy="46" r=".8"/>
                    <circle cx="102" cy="46" r=".8"/>
                    <circle cx="100" cy="46" r=".8"/>
                    <circle cx="98" cy="46" r=".8"/>
                    <circle cx="96" cy="46" r=".8"/>
                    <circle cx="94" cy="46" r=".8"/>
                    <circle cx="92" cy="46" r=".8"/>
                    <circle cx="90" cy="46" r=".8"/>
                    <circle cx="88" cy="46" r=".8"/>
                    <circle cx="86" cy="46" r=".8"/>
                    <circle cx="84" cy="46" r=".8"/>
                    <circle cx="82" cy="46" r=".8"/>
                    <circle cx="80" cy="46" r=".8"/>
                    <circle cx="78" cy="46" r=".8"/>

                    <circle cx="114" cy="44" r=".8"/>
                    <circle cx="112" cy="44" r=".8"/>
                    <circle cx="110" cy="44" r=".8"/>
                    <circle cx="108" cy="44" r=".8"/>
                    <circle cx="106" cy="44" r=".8"/>
                    <circle cx="104" cy="44" r=".8"/>
                    <circle cx="102" cy="44" r=".8"/>
                    <circle cx="100" cy="44" r=".8"/>
                    <circle cx="98" cy="44" r=".8"/>
                    <circle cx="96" cy="44" r=".8"/>
                    <circle cx="94" cy="44" r=".8"/>
                    <circle cx="92" cy="44" r=".8"/>
                    <circle cx="90" cy="44" r=".8"/>
                    <circle cx="88" cy="44" r=".8"/>
                    <circle cx="86" cy="44" r=".8"/>
                    <circle cx="84" cy="44" r=".8"/>
                    <circle cx="82" cy="44" r=".8"/>
                    <circle cx="80" cy="44" r=".8"/>

                    <circle cx="112" cy="42" r=".8"/>
                    <circle cx="110" cy="42" r=".8"/>
                    <circle cx="108" cy="42" r=".8"/>
                    <circle cx="106" cy="42" r=".8"/>
                    <circle cx="104" cy="42" r=".8"/>
                    <circle cx="102" cy="42" r=".8"/>
                    <circle cx="100" cy="42" r=".8"/>
                    <circle cx="98" cy="42" r=".8"/>
                    <circle cx="96" cy="42" r=".8"/>
                    <circle cx="94" cy="42" r=".8"/>
                    <circle cx="92" cy="42" r=".8"/>
                    <circle cx="90" cy="42" r=".8"/>
                    <circle cx="88" cy="42" r=".8"/>
                    <circle cx="86" cy="42" r=".8"/>
                    <circle cx="84" cy="42" r=".8"/>
                    <circle cx="82" cy="42" r=".8"/>
                    <circle cx="80" cy="42" r=".8"/>
                    <circle cx="78" cy="42" r=".8"/>

                    <circle cx="112" cy="40" r=".8"/>
                    <circle cx="110" cy="40" r=".8"/>
                    <circle cx="108" cy="40" r=".8"/>
                    <circle cx="106" cy="40" r=".8"/>
                    <circle cx="104" cy="40" r=".8"/>
                    <circle cx="102" cy="40" r=".8"/>
                    <circle cx="100" cy="40" r=".8"/>
                    <circle cx="98" cy="40" r=".8"/>
                    <circle cx="96" cy="40" r=".8"/>
                    <circle cx="94" cy="40" r=".8"/>
                    <circle cx="92" cy="40" r=".8"/>
                    <circle cx="90" cy="40" r=".8"/>
                    <circle cx="88" cy="40" r=".8"/>
                    <circle cx="86" cy="40" r=".8"/>
                    <circle cx="84" cy="40" r=".8"/>
                    <circle cx="82" cy="40" r=".8"/>
                    <circle cx="80" cy="40" r=".8"/>

                    <circle cx="110" cy="38" r=".8"/>
                    <circle cx="108" cy="38" r=".8"/>
                    <circle cx="106" cy="38" r=".8"/>
                    <circle cx="104" cy="38" r=".8"/>
                    <circle cx="102" cy="38" r=".8"/>
                    <circle cx="100" cy="38" r=".8"/>
                    <circle cx="98" cy="38" r=".8"/>
                    <circle cx="96" cy="38" r=".8"/>
                    <circle cx="94" cy="38" r=".8"/>
                    <circle cx="92" cy="38" r=".8"/>
                    <circle cx="90" cy="38" r=".8"/>
                    <circle cx="88" cy="38" r=".8"/>
                    <circle cx="86" cy="38" r=".8"/>
                    <circle cx="84" cy="38" r=".8"/>
                    <circle cx="82" cy="38" r=".8"/>
                    <circle cx="80" cy="38" r=".8"/>

                    <circle cx="110" cy="36" r=".8"/>
                    <circle cx="108" cy="36" r=".8"/>
                    <circle cx="106" cy="36" r=".8"/>
                    <circle cx="104" cy="36" r=".8"/>
                    <circle cx="102" cy="36" r=".8"/>
                    <circle cx="100" cy="36" r=".8"/>
                    <circle cx="98" cy="36" r=".8"/>
                    <circle cx="96" cy="36" r=".8"/>
                    <circle cx="94" cy="36" r=".8"/>
                    <circle cx="92" cy="36" r=".8"/>
                    <circle cx="90" cy="36" r=".8"/>
                    <circle cx="88" cy="36" r=".8"/>
                    <circle cx="86" cy="36" r=".8"/>
                    <circle cx="84" cy="36" r=".8"/>

                    <circle cx="104" cy="34" r=".8"/>
                    <circle cx="102" cy="34" r=".8"/>
                    <circle cx="98" cy="34" r=".8"/>
                    <circle cx="96" cy="34" r=".8"/>
                    <circle cx="94" cy="34" r=".8"/>
                    <circle cx="92" cy="34" r=".8"/>
                    <circle cx="90" cy="34" r=".8"/>
                    <circle cx="88" cy="34" r=".8"/>
                    <circle cx="86" cy="34" r=".8"/>
                    <circle cx="84" cy="34" r=".8"/>

                    <circle cx="96" cy="32" r=".8"/>
                    <circle cx="94" cy="32" r=".8"/>
                    <circle cx="92" cy="32" r=".8"/>
                    <circle cx="90" cy="32" r=".8"/>
                    <circle cx="88" cy="32" r=".8"/>
                    <circle cx="86" cy="32" r=".8"/>

                </motion.g>
                <g stroke="#52B3F5" strokeWidth="0.4" fill="#192029">
                    <circle cx="50" cy="22" r="1.2"/>
                    <circle cx="88" cy="20" r="1.2"/>
                    <circle cx="162" cy="40" r="1.2"/>
                </g>

            </svg>
        </div>
    );
}
 
export default AboutPageGraphic;
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faEdit } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from 'react';

const AdminSelectorItem = ({ type, item, selected, setSelected, setEditSelectionItem }) => {
    const [ isSelected, setIsSelected ] = useState(false);

    const handleSelect = () => {
        // Check if already in selection 
        let existing = selected;
        if (!selected.includes(item.id) && !selected.includes(item.name[2].toLowerCase())) {
            // Add to selected
            existing.push(item.id);
        } else {
            // Remove from selected
            for( var i = 0; i < existing.length; i++){                 
                if ( existing[i] === item.id || existing[i] === item.name[2].toLowerCase() ) { 
                    existing.splice(i, 1); 
                    i--; 
                }
            }
        }
        setSelected([...existing]);
    }

    const handleEditItem = (e) => {
        e.stopPropagation();
        // Fail if not services
        if (type !== 'services') {
            return false;
        }
        setEditSelectionItem(item);
    }

    // Selected
    useEffect(() => {
        if (selected.includes(item.id) || selected.includes(item.name[2].toLowerCase())) {
            setIsSelected(true);
        } else {
            setIsSelected(false);
        }
    }, [selected])

    return (
        <>
            {item && (
                <span className="group basis-full break560:basis-1/2 flex gap-3 py-3 px-0 items-center capitalize cursor-pointer" onClick={handleSelect}>
                    <FontAwesomeIcon icon={faCircleCheck} className={`!text-green-600 ${isSelected ? 'opacity-100' : 'opacity-0'}`}  />
                    { (type === 'services' || type === 'nations') ? item.name[2] : '' }
                    {type === 'services'&& (
                        <FontAwesomeIcon className='px-2 ml-auto mr-3 opacity-0 group-hover:opacity-30 hover:!opacity-100' icon={faEdit} onClick={handleEditItem} />
                    )}
                </span>
            )}
        </>
    );
}
 
export default AdminSelectorItem;
import { collection, doc, addDoc, deleteDoc, Timestamp } from 'firebase/firestore'
import { db } from '../firebase-config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faCheck } from '@fortawesome/free-solid-svg-icons';
import { useState, useRef } from 'react';
import { useAuth } from '../contexts/AuthContext';

const AdminNoteForm = ({ note, setSelectedNote }) => {
    const { currentUser } = useAuth();

    const [ loading, setLoading ] = useState(false);
    const [ success, setSuccess ] = useState(false);

    const [ titleErr, setTitleErr ] = useState(false);
    const [ bodyErr, setBodyErr ] = useState(false);

    const titleRef = useRef();
    const bodyRef = useRef();

    const handlePropagation = (e) => {
        e.stopPropagation();
    }

    const handleNoteSubmit = async (e) => {
        e.preventDefault();
        // Check if loading already 
        if (loading) {
            return false;
        }
        // Reset errors
        setTitleErr(false);
        setBodyErr(false);

        let newData, errors = false;
        // Validate
        if (titleRef.current.value === '') {
            errors = true;
            setTitleErr(true);
        }
        if (bodyRef.current.value === '') {
            errors = true;
            setBodyErr(true);
        }
        if (errors) {
            return false;
        }
        // Set data
        newData = {
            title: titleRef.current.value,
            body: bodyRef.current.value,
            timestamp: Timestamp.now(),
            userEmail: currentUser.email,
            userName: currentUser.displayName ?? ""
        }
        setLoading(true);
        // Submit to firestore
        try {
            await addDoc(collection(db, 'notes'), newData);
            setLoading(false);
            setSuccess(true);
            setTimeout(() => {
                setSuccess(false);
            }, 1500);
            await addDoc(collection(db, 'userInteraction'), {
                userName: currentUser.displayName ?? '',
                userEmail: currentUser.email,
                type: 'Adding note',
                document: 'notes',
                timestamp: Timestamp.now(),
                newData: newData,
                visible: true
            });
            setTimeout(() => {
                setSelectedNote(null);
            }, 700);
        } catch (err) {
            setLoading(false);
        }
    }

    const handleDelete = async (e) => {
        e.preventDefault();
        // Check if loading already 
        if (loading) {
            return false;
        }
        // Set data
        let oldData = note;
        setLoading(true);
        // Submit to firestore
        try {
            const docRef = doc(db, "notes", note.id);
            await deleteDoc(docRef);
            await addDoc(collection(db, 'userInteraction'), {
                userName: currentUser.displayName ? currentUser.displayName : '',
                userEmail: currentUser.email,
                type: 'Deleting note',
                document: 'notes',
                timestamp: Timestamp.now(),
                data: oldData,
            });
        } catch (err) {
            setLoading(false);
        }
    }

    return (
        <>
            {note.id !== 'new' && (
                <div className='basis-full p-3 rounded-lg g-shadow bg-[rgba(0,0,0,0.05)] cursor-default mb-6' onClick={handlePropagation}>
                    <div className='flex flex-col gap-3 pb-3'>
                        <div>
                            <h3><b>From: </b></h3>
                            <p>{(note.userName !== '' && note.userName !== null) ? note.userName : note.userEmail}</p>
                        </div>
                        <div>
                            <h3><b>Date: </b></h3>
                            <p>{new Date(note.timestamp.toMillis()).toLocaleString()}</p>
                        </div>
                        <div>
                            <h3><b>Message: </b></h3>
                            <p  dangerouslySetInnerHTML={{ __html: note.body.replace(/(?:\r\n|\r|\n)/g, '<br>') }}></p>
                        </div>
                        <section className='flex justify-end'>
                            <div className='h-[48px]'>
                                {!loading && !success && (<button className='g-shadow rounded-lg p-3 bg-red-400 text-white flex justify-center items-center' onClick={handleDelete}>Delete</button>)}
                                {loading && (<span className='basis-[80px] flex items-center justify-center'><FontAwesomeIcon className=' text-[20px] animate-spin' icon={faSpinner}/></span>)}
                                {success && (<span className='basis-[80px] flex items-center justify-center'><FontAwesomeIcon className=' text-green-600 text-[20px]' icon={faCheck}/></span>)}
                            </div>
                        </section>
                    </div>
                </div>

            )}
            {/* New Note */}
            {note.id === 'new' && (
                <form action='' className='admin-form basis-full p-3 rounded-lg g-shadow bg-[rgba(0,0,0,0.05)] cursor-default' autoComplete='off' onSubmit={handleNoteSubmit} onClick={(e) => e.stopPropagation()}> 
                    <section className='article-source'>
                        <h3>Note</h3>
                        <div>
                            <div>
                                <label htmlFor='title'>Title</label>
                                <input className={`${titleErr ? 'errors' : ''}`} name='title' id='title' type='text' ref={titleRef} />
                            </div>
                        </div>
                    </section>
                    <section className='article-source'>
                        <div>
                            <div>
                                <label htmlFor='body'>Message</label>
                                <textarea className={`${bodyErr ? 'errors' : ''}`} name='body' id='body' ref={bodyRef} rows='5'></textarea>
                            </div>
                        </div>
                    </section>
                    
                    
                    <section className='admin-submit'>
                        <div className='h-[48px]'>
                            {!loading && !success && (<button className='g-shadow rounded-lg p-3 bg-blue-400 !text-white flex justify-center items-center'>Add Note</button>)}
                            {loading && (<span className='basis-[80px] flex items-center justify-center'><FontAwesomeIcon className=' text-[20px] animate-spin' icon={faSpinner}/></span>)}
                            {success && (<span className='basis-[80px] flex items-center justify-center'><FontAwesomeIcon className=' !text-green-600 text-[20px]' icon={faCheck}/></span>)}
                        </div>
                    </section>
                </form>
            )}
        </>
    );
}
 
export default AdminNoteForm;
import { useState } from "react";
import { useAdmin } from '../contexts/AdminContext';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faLaptopFile, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { useEffect } from "react";

const AdminModalItem = ({ type, item, setChangesOpen, submitAllFail }) => {
    const { setDashPage, nations, selectedNation, setSelectedNation, submitNation, orgs, selectedOrg, setSelectedOrg, submitOrganisation, clearItemsChanges, submittingAll } = useAdmin();

    const [ open, setOpen ] = useState(false);
    const [ loading, setLoading ] = useState(false);
    const [ submitErr, setSubmitErr ] = useState(false);

    const handleOpen = () => {
        setOpen(!open);
    }

    const handlePropagation = (e) => {
        e.stopPropagation();
    }

    const handleReset = () => {
        clearItemsChanges(type, item, true);
    }

    const handleOpenItem = (e) => {
        e.stopPropagation();
        if (type === 0) {
            // Get item info from nations array
            const nation = nations.find(foundNation => (foundNation.id === item.id));
            if (!nation && item.id !== 'new') {
                return false;
            }
            // set dash page to nations
            setDashPage(0);
            setTimeout(()=> {
                setDashPage(2);
                setSelectedNation(item.id === 'new' ? {id: 'new'} : nation);
                setChangesOpen(false);
            }, 10);
        }
        if (type === 1) {
            // set dash page to orgs
            // Get item info from nations array
            const organisation = orgs.find(foundOrganisation => (foundOrganisation.id === item.id));
            if (!organisation && item.id !== 'new') {
                return false;
            }
            // set dash page to nations
            setDashPage(0);
            setTimeout(()=> {
                setDashPage(3);
                setSelectedOrg(item.id === 'new' ? {id: 'new'} : organisation);
                setChangesOpen(false);
            }, 10);
        }
    }

    const handleNationSubmit = async (e) => {
        e.preventDefault();
        if (loading || submittingAll) {
            return false;
        }
        // Set loading 
        setLoading(true);
        // Reset errors
        setSubmitErr(false);

        submitNation(item.id)
            .then(data => {
                // Close that nation if open
                if (selectedNation.id == item.id) {
                    setSelectedNation(null);
                }
                setLoading(false);
            })
            .catch(err => {
                // No changes to submit
                if (err.reason === 0) {
                    setSubmitErr("No changes detected");
                }
                // Validation errors
                if (err.reason === 1) {
                    let validationErrors = [];
                    !err.validated.ukrName && validationErrors.push("Ukrainian name");
                    !err.validated.frName && validationErrors.push("French name");
                    !err.validated.enName && validationErrors.push("English name");
                    !err.validated.visa && validationErrors.push("Visa-free stay");
                    !err.validated.continent && validationErrors.push("Continent");
                    setSubmitErr("Failed to validate: " + validationErrors.join(", "));
                }
                // Firestore errors
                if (err.reason === 2) {
                    setSubmitErr("Failed to upload to database");
                }
                setLoading(false);
            });
    }

    const handleOrgSubmit = async (e) => {
        e.preventDefault();
        if (loading || submittingAll) {
            return false;
        }
        // Set loading 
        setLoading(true);
        // Reset errors
        setSubmitErr(false);

        submitOrganisation(item.id)
            .then(data => {
                // Close that organisation if open
                if (selectedOrg.id === item.id) {
                    setSelectedOrg(null);
                }
                setLoading(false);
            })
            .catch(err => {
                // No changes to submit
                if (err.reason === 0) {
                    setSubmitErr("No changes detected");
                }
                // Validation errors
                if (err.reason === 1) {
                    let validationErrors = [];
                    !err.validated.title && validationErrors.push("Organisation name");
                    !err.validated.link && validationErrors.push("Website link");
                    !err.validated.tel && validationErrors.push("Telephone number");
                    !err.validated.email && validationErrors.push("Email address");
                    !err.validated.file && validationErrors.push("Image file");
                    setSubmitErr("Failed to validate: " + validationErrors.join(", "));
                }
                setLoading(false);
            });
    }

    useEffect(() => {
        // Check if errors from submit all
        if (submitAllFail) {
            let message = "Errors detected";
            if (submitAllFail.reason && submitAllFail.reason === 2) {
                message = "Validation failed";
            }
            if (type === 0 && submitAllFail.failedNations) {
                const foundNation = submitAllFail.failedNations.includes(item.id);
                if (foundNation) {
                    setSubmitErr(message);
                }
            }
            if (type === 1 && submitAllFail.failedOrganisations) {
                const foundOrganisiation = submitAllFail.failedOrganisations.includes(item.id);
                if (foundOrganisiation) {
                    setSubmitErr(message);
                }
            }
        }
    },[submitAllFail]);

    return (
        <div
            className={`rounded-lg g-shadow basis-full p-3 flex flex-wrap justify-around items-center cursor-pointer ${submitErr ? 'bg-rose-100' : 'bg-backgroundLight'}`}
            onClick={handleOpen}
        >
            <div className="flex flex-wrap flex-1">
                {type === 0 && (
                    <span className="basis-full opacity-75 uppercase text-[10px] leading-none">{ item.id === 'new' ? 'Add Nation' :'Edit Nation' }</span>
                )}
                {type === 1 && (
                    <span className="basis-full opacity-75 uppercase text-[10px] leading-none">{ item.id === 'new' ? 'Add Organisation' : 'Edit Organisation' }</span>
                )}
                {type === 0 && item.name && (
                    <span className="basis-full capitalize">{item.name[2] !== '' ? item.name[2] : '-' }</span>
                )}
                {type === 1 && (
                    <span className="basis-full capitalize">{item.title !== '' ? item.title : '-' }</span>
                )}
            </div>
            <FontAwesomeIcon className="p-3 rounded-lg opacity-75 hover:bg-backgroundGray hover:text-white hover:opacity-100" onClick={handleOpenItem} icon={faEye} />
            {open && !loading && !submittingAll && !submitErr && (
                <div className="mt-3 border-t-[1px] border-dashed border-[#999] basis-full pt-3 flex gap-3 items-center justify-end cursor-default" onClick={handlePropagation}>
                    <span
                        className="px-2 py-1 font-bold text-[12px] tracking-widest uppercase cursor-pointer"
                        onClick={handleReset}
                    >Reset</span>
                    <span 
                        className="px-2 py-1 rounded-lg bg-[#52B3F5] g-shadow !text-white  text-[12px] font-bold tracking-widest uppercase cursor-pointer"
                        onClick={type === 0 ? handleNationSubmit : handleOrgSubmit}
                    >Publish</span>
                </div> 
            )}
            {open && (loading || submittingAll) && !submitErr && (
                <div className="mt-3 border-t-[1px] border-dashed border-[#999] basis-full pt-3 flex gap-3 items-center justify-end cursor-default" onClick={handlePropagation}>
                    <span className='basis-[80px] flex items-center justify-center py-[3px]'><FontAwesomeIcon className=' text-[20px] animate-spin' icon={faSpinner}/></span>
                </div> 
            )}
            {open && submitErr && (
                <div className="mt-3 border-t-[1px] border-dashed border-[#999] basis-full pt-3 flex gap-3 items-center justify-end cursor-default" onClick={handlePropagation}>
                    <span className='flex items-center justify-center !text-rose-600 font-medium'>* {submitErr}</span>
                </div> 
            )}
        </div>
    );
}
 
export default AdminModalItem;
import { createContext, useState, useContext, useEffect } from "react";
import { collection, onSnapshot, query, orderBy, Timestamp, doc, addDoc, updateDoc } from 'firebase/firestore';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { db, storage } from '../firebase-config';
import { validateURL, validateTel, validateEmail } from '../utils/validation';
import { useAuth } from '../contexts/AuthContext';

export const AdminContext = createContext();

export function useAdmin() {
    return useContext(AdminContext)
}
const AdminContextProvider = (props) => {
    const { currentUser } = useAuth();
    const [ dashPage, setDashPage ] = useState(0);
    const [ selectedNation, setSelectedNation ] = useState(null);
    const [ selectedOrg, setSelectedOrg ] = useState(null);
    const [ changes, setChanges ] = useState({nations: [], organisations: []});
    const [ reloadForm, setReloadForm ] = useState(false);
    const [ submittingAll, setSubmittingAll ] = useState(false);

    // Fetch nations
    const [ nations, setNations ] = useState([]);
    const [ nationsLoading, setNationsLoading ] = useState(true);
    useEffect(() => {
        const nationColRef = collection(db, 'countryList');
        const nationQ = query(nationColRef);
        let returnNations = [];
        const nationUnsubscription = onSnapshot(nationQ, (snapshot) => {
            returnNations = [];
            // Fetch data
            snapshot.docs.map((doc) => {
                return returnNations.push({...doc.data(), id: doc.id});
            });
            // Sort data by english name
            returnNations.sort(function (x, y) {
                let a = x.name[2].toUpperCase(),
                b = y.name[2].toUpperCase();
                return a === b ? 0 : a > b ? 1 : -1;
            })
            setNations(returnNations);
            setNationsLoading(false);
        });
        return nationUnsubscription;
    }, []);

    // Submit nation
    const submitNation = async (id) => {
        console.log("Submit nation called for id:", id);
        let error = false;
        // Error reasons:
            // 0 = no changes for item found
            // 1 = validation error 
            // 2 = publishing error
        return new Promise (async (resolve, reject) => {
            // Use ID to check if item is in changes nations array
            const foundItem = {...changes}.nations.find(item => (item.id === id));
            if (!foundItem) {
                error = true;
                reject({reason: 0});
                return false;
            }
            // Validate fields
            let validated = {ukrName: true, frName: true, enName: true, visa: true, continent: true };
            if (foundItem.id === 'new') {
                if (foundItem.name[0] === '') {
                    validated.ukrName = false;
                }
                if (foundItem.name[1] === '') {
                    validated.frName = false;
                }
                if (foundItem.name[2] === '') {
                    validated.enName = false;
                }
            }
            if (foundItem.visa !== '' && foundItem.visa !== 'yes' && foundItem.visa !== '30' && foundItem.visa !== '90' && foundItem.visa !== '1y' && foundItem.visa !== 'eu' && foundItem.visa !== 'no') {
                validated.visa = false;
            }
            if (foundItem.continent !== '' && foundItem.continent !== 'na' && foundItem.continent !== 'sa' && foundItem.continent !== 'eu' && foundItem.continent !== 'af' && foundItem.continent !== 'as' && foundItem.continent !== 'oc') {
                validated.continent = false;
            }
            if (validated.ukrName === false || validated.frName === false || validated.enName === false || validated.visa === false || validated.continent === false) {
                error = true;
                reject({reason: 1, validated: validated});
                return false;
            }
            // Check if new item or edit existing item
            let oldData, newData;
            if (foundItem.id === 'new') { 
                oldData = null;
            } else {
                oldData = nations.find(item => (item.id === foundItem.id));
            }
            newData = {...foundItem};
            newData.timestamp = Timestamp.now();
            // Submit to firestore if no errors
            if (!error) {
                if (foundItem.id === 'new') {
                    // Adding doc
                    try {
                        delete newData.id;
                        await addDoc(collection(db, 'countryList'), newData);
                        addDoc(collection(db, 'userInteraction'), {
                            userName: currentUser.displayName ? currentUser.displayName : '',
                            userEmail: currentUser.email,
                            type: 'Added country',
                            document: 'countryList',
                            timestamp: Timestamp.now(),
                            newData: newData
                        });
                        clearItemsChanges(0, foundItem, false);
                        resolve("Success");
                    } catch (err) {
                        error = true;
                        reject({reason: 2, error: err});
                    }
                } else {
                    // Updating doc
                    try {
                        const docRef = doc(db, 'countryList', foundItem.id);
                        await updateDoc(docRef, newData);
                        addDoc(collection(db, 'userInteraction'), {
                            userName: currentUser.displayName ? currentUser.displayName : '',
                            userEmail: currentUser.email,
                            type: 'Updated country',
                            document: 'countryList',
                            documentID: foundItem.id,
                            timestamp: Timestamp.now(),
                            newData: newData,
                            oldData: oldData
                        });
                        clearItemsChanges(0, foundItem, false);
                        resolve("Success");    
                    } catch (err) {
                        error = true;
                        reject({reason: 2, error: err});
                    }
                }
            }
        });
    }

    // Fetch organisations
    const [ orgs, setOrgs ] = useState([]);
    const [ orgsLoading, setOrgsLoading ] = useState(true);
    useEffect(() => {
        const orgsColRef = collection(db, 'organisations');
        const orgsQ = query(orgsColRef, orderBy('title', 'asc'));    
        const organisationsUnsubcriber = onSnapshot(orgsQ, (snapshot) => {
            setOrgs(snapshot.docs.map((doc) => ({...doc.data(), id: doc.id})));
            setOrgsLoading(false);
        });
        return organisationsUnsubcriber;
    }, []);

    // Upload organisation logo 
    const uploadImage = async (file) => {
        const storageRef = ref(storage, '/organisations/' + Date.now() + file.name);
        const uploadTask = uploadBytesResumable(storageRef, file);
        await uploadTask;   
        const downloadURL = getDownloadURL(uploadTask.snapshot.ref);
        return downloadURL;
    }

    // Submit organisation
    const submitOrganisation = async (id) => {
        let error = false;
        let downloadURL = false;
        // Error reasons:
            // 0 = no changes for item found
            // 1 = validation error 
            // 2 = publishing image error
            // 3 = publishing data error
        return new Promise (async (resolve, reject) => {
            // Use ID to check if item is in changes nations array
            const foundItem = {...changes}.organisations.find(item => (item.id === id));
            if (!foundItem) {
                error = true;
                reject({reason: 0});
                return false;
            }
            // Validate fields
            let validated = {title: true, link: true, tel: true, email: true, file: true };
            if (foundItem.title === '') {
                validated.title = false;
            }
            if (foundItem.link === '' || !validateURL(foundItem.link)) {
                validated.link = false;
            }
            if (foundItem.tel !== '' && !validateTel(foundItem.tel)) {
                validated.tel = false;
            }
            if (foundItem.email !== '' && !validateEmail(foundItem.email)) {
                validated.email = false;
            }
            if (foundItem.id === 'new' && foundItem.file === null) {
                validated.file = false;
            }
            // Validate file !!
            if (validated.title === false || validated.link === false || validated.tel === false || validated.email === false || validated.file === false) {
                error = true;
                reject({reason: 1, validated: validated});
                return false;
            }
            // Check if new item or edit existing item
            let oldData, newData;
            if (foundItem.id === 'new') { 
                oldData = null;
            } else {
                oldData = orgs.find(item => (item.id === foundItem.id));
            }
            newData = {...foundItem};
            newData.timestamp = Timestamp.now();
            // Handle new image if set
            if (newData.file !== null && !error) {
                // File detected - upload to firebase and fetch new logoUrl
                try {
                    downloadURL = await uploadImage(newData.file);
                } catch (err) {
                    error = true;
                    reject({reason: 2, error: err});
                }
            }
            // Submit to firestore if no errors
            if (!error) {
                // Clean up data after handling image
                delete newData.file;
                newData.logoUrl = downloadURL ? downloadURL : newData.logoUrl;
                if (foundItem.id === 'new') {
                    // Adding doc
                    delete newData.id;
                    try {
                        await addDoc(collection(db, 'organisations'), newData);
                        addDoc(collection(db, 'userInteraction'), {
                            userName: currentUser.displayName ? currentUser.displayName : '',
                            userEmail: currentUser.email,
                            type: 'Added organisation',
                            document: 'organisations',
                            timestamp: Timestamp.now(),
                            newData: newData
                        });
                        clearItemsChanges(1, foundItem, false);
                        resolve("Success");
                    } catch (err) {
                        error = true;
                        reject({reason: 3, error: err});
                    }
                } else {
                    // Updating doc
                    try {
                        const docRef = doc(db, 'organisations', foundItem.id);
                        await updateDoc(docRef, newData);
                        addDoc(collection(db, 'userInteraction'), {
                            userName: currentUser.displayName ? currentUser.displayName : '',
                            userEmail: currentUser.email,
                            type: 'Updated organisation',
                            document: 'organisations',
                            documentID: foundItem.id,
                            timestamp: Timestamp.now(),
                            newData: newData,
                            oldData: oldData
                        });
                        clearItemsChanges(1, foundItem, false);
                        resolve("Success");    
                    } catch (err) {
                        error = true;
                        reject({reason: 3, error: err});
                    }
                }
            }
        });
    }
    
    // Fetch Services
    const [ services, setServices ] = useState([]);
    useEffect(() => {
        const servicesColRef = collection(db, 'services');
        const servicesQ = query(servicesColRef);    
        const servicesUnsubcriber = onSnapshot(servicesQ, (snapshot) => {
            setServices(snapshot.docs.map((doc) => ({...doc.data(), id: doc.id})));
        });
        return servicesUnsubcriber;
    }, []);

    // Fetch notes
    const [ notes, setNotes ] = useState([]);
    const [ notesLoading, setNotesLoading ] = useState(true);
    useEffect(() => {
        const notesColRef = collection(db, 'notes');
        const notesQ = query(notesColRef, orderBy('timestamp', 'desc'));    
        const notesUnsubcriber = onSnapshot(notesQ, (snapshot) => {
            setNotes(snapshot.docs.map((doc) => ({...doc.data(), id: doc.id})));
            setNotesLoading(false);
        });
        return notesUnsubcriber;
    }, []);

    // Fetch To Do List
    const [ toDo, setToDo ] = useState([]);
    const [ toDoLoading, setToDoLoading ] = useState(true);
    useEffect(() => {
        const toDoColRef = collection(db, 'toDo');
        const toDoQ = query(toDoColRef, orderBy('timestamp', 'desc'));    
        const toDoUnsubcriber = onSnapshot(toDoQ, (snapshot) => {
            setToDo(snapshot.docs.map((doc) => ({...doc.data(), id: doc.id})));
            setToDoLoading(false);
        });
        return toDoUnsubcriber;
    }, []);

    // Fetch Messages
    const [ messages, setMessages ] = useState([]);
    const [ messagesLoading, setMessagesLoading ] = useState(true);
    useEffect(() => {
        const messagesRef = collection(db, 'messages');
        const messagesQ = query(messagesRef, orderBy('timestamp', 'desc'));    
        const messagesUnsubcriber = onSnapshot(messagesQ, (snapshot) => {
            setMessages(snapshot.docs.map((doc) => ({...doc.data(), id: doc.id})));
            setMessagesLoading(false);
        });
        return messagesUnsubcriber;
    }, []);

    // Set Dash Page
    const chooseNewDashPage = (x) => {
        setDashPage(x);
        setSelectedNation(null);
        setSelectedOrg(null);
    }

    // Clear Items Changes
    const clearItemsChanges = ( type, item, allowReload ) => {
        let newChangedNations, newChangedOrganisations;
        setChanges(current => {
            // Clear item from changes array
            if (type === 0) {
                newChangedNations = current.nations.filter(nation => (nation.id !== item.id));
                newChangedOrganisations = current.organisations;
            } else if (type === 1) {
                newChangedNations = current.nations;
                newChangedOrganisations = current.organisations.filter(org => (org.id !== item.id));
            } else {
                newChangedNations = current.nations;
                newChangedOrganisations = current.organisations;
            }
            const newChanges = {nations: newChangedNations, organisations: newChangedOrganisations};
            return {...newChanges};
        });
        // Reload admin page if that item open - clearing changes in the process
        if (selectedNation && selectedNation.id === item.id && dashPage === 2 && allowReload) {
            setReloadForm(item.id);
        } else if ((selectedOrg && selectedOrg.id === item.id && dashPage === 3 && allowReload)) {
            setReloadForm(item.id);
        } else {
            setReloadForm(false);
        }
    }

    // Submit all changes
    const submitAllChanges = async () => {
        let errors = false;
        const detectedChanges = {...changes};
        // Error reasons:
            // 0 = already submitting
            // 1 = no changes to submit detected
            // 2 = some items failed - return array of failed items
        return new  Promise (async (resolve, reject) => {
            // Check if already submitting all
            if (submittingAll) {
                errors = true;
                reject({reason: 0});
                return false;
            }
            setSubmittingAll(true);
            // Check items to publish
            if((detectedChanges.nations && detectedChanges.nations.length > 0) || (detectedChanges.organisations && detectedChanges.organisations.length > 0)) {
                // Detected changes
            } else {
                // No changes to submit
                errors = true;
                reject({reason: 1});
            }
            if (!errors) {
                let failedNations = [];
                let failedOrganisations = [];
                if (detectedChanges.nations && detectedChanges.nations.length > 0) {
                    // Submit all nation changes
                    for (const nation of detectedChanges.nations) {
                        await submitNation(nation.id)
                            .catch( err => {
                                failedNations.push(nation.id);
                            });
                    }
                }
                if (detectedChanges.organisations && detectedChanges.organisations.length > 0) {
                    // Submit all organisation changes
                    for (const organisation of detectedChanges.organisations) {
                        await submitOrganisation(organisation.id)
                            .catch( err => {
                                failedOrganisations.push(organisation.id);
                            });
                    }

                }
                if (failedNations.length > 0 || failedOrganisations.length > 0) {
                    errors = true;
                    reject({reason: 2, failedNations: failedNations, failedOrganisations: failedOrganisations });
                }
            }
            if (!errors) {
                resolve({success: true});
            }
            setSubmittingAll(false);
        });
     }

    const value = {
        dashPage,
        setDashPage,
        chooseNewDashPage,
        nations,
        nationsLoading,
        selectedNation,
        setSelectedNation,
        submitNation,
        orgs,
        orgsLoading,
        selectedOrg,
        setSelectedOrg,
        submitOrganisation,
        services,
        notes,
        notesLoading,
        toDo,
        toDoLoading,
        messages,
        messagesLoading,
        changes,
        setChanges,
        clearItemsChanges,
        reloadForm,
        setReloadForm,
        submitAllChanges,
        submittingAll
    }

    return (
        <AdminContext.Provider value={value}>
            {props.children}
        </AdminContext.Provider>
        
    );
}
 
export default AdminContextProvider;
import { useAdmin } from '../contexts/AdminContext';
import AdminHeader from './AdminHeader';
import AdminDashCrumbs from './AdminDashCrumbs';
import AdminDashHome from './AdminDashHome';
import AdminUser from './AdminUser';
import AdminNations from './AdminNations';
import AdminOrganisations from './AdminOrganisations';
import AdminNotes from './AdminNotes';
import AdminDashHomeTools from './AdminDashHomeTools';
import AdminMessagesContainer from './AdminMessagesContainer';
import AdminMessages from './AdminMessages';

const AdminDashboard = () => {
    const {
        dashPage, setDashPage,
        nations, nationsLoading,
        orgs, orgsLoading,
        notes, notesLoading,
        messages, messagesLoading,
        toDo, toDoLoading
    } = useAdmin();

    return (
        <div className='no_select pb-6'>
            {/* Header */}
            <AdminHeader />
            {/* Breadcrumbs */}
            <AdminDashCrumbs />
            {/* Admin Content */}
            <div className='px-6'>
                {/* Dashboard Home */}
                {dashPage === 0 && <AdminDashHome nationCount={nations.length} notesCount={notes.length} orgsCount={orgs.length} nationsLoading={nationsLoading} orgsLoading={orgsLoading} notesLoading={notesLoading} />}
                {/* Manage User */}
                {dashPage === 1 && <AdminUser />}
                {/* Manage Countries */}
                {dashPage === 2 && <AdminNations />}
                {/* Manage Organisations */}
                {dashPage === 3 && <AdminOrganisations />}
                {/* Manage Notes */}
                {dashPage === 4 && <AdminNotes notes={notes} loading={notesLoading} />}
                {/* Messages */}
                {dashPage === 5 && <AdminMessages messages={messages} loading={messagesLoading} type={0} />}
                {dashPage === 6 && <AdminMessages messages={messages} loading={messagesLoading} type={1} />}
                {dashPage === 7 && <AdminMessages messages={messages} loading={messagesLoading} type={2} />}
            </div>
            {/* Tools */}
            {dashPage === 0 && (
                <>
                    <AdminMessagesContainer setDashPage={setDashPage} messages={messages} messagesLoading={messagesLoading} />
                    <AdminDashHomeTools toDo={toDo} toDoLoading={toDoLoading} />
                </>
            )}
        </div>
    );
}
 
export default AdminDashboard;
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import { db } from '../firebase-config';
import { collection, doc, addDoc, deleteDoc, updateDoc, Timestamp } from 'firebase/firestore'
import { useAuth } from '../contexts/AuthContext';


const AdminToDoItem = ({ item }) => {
    const { currentUser } = useAuth();

    const [complete, setComplete ] = useState(false);
    const [loading, setLoading ] = useState(false);

    const handlePropagation = (e) => {
        e.stopPropagation();
    }

    const handleCompleteToggle = async () => {
        // Check if loading already
        let data;
        if (loading) {
            return false;
        }
        // Set data
        data = {
            complete: !complete
        }
        setLoading(true);
        // Submit to firestore
        try {
            const docRef = doc(db, 'toDo', item.id)
            await updateDoc(docRef, data);
            setComplete(!complete);
            setLoading(false);
        } catch (err) {
            setLoading(false);
            setComplete(complete);
        }
    }

    const handleDelete = async () => {
        // Check if loading already 
        if (loading) {
            return false;
        }
        // Set data
        let oldData = item;
        setLoading(true);
        // Submit to firestore
        try {
            const docRef = doc(db, "toDo", item.id);
            await deleteDoc(docRef);
            await addDoc(collection(db, 'userInteraction'), {
                userName: currentUser.displayName ?? '',
                userEmail: currentUser.email,
                type: 'Deleting task',
                document: 'toDo',
                timestamp: Timestamp.now(),
                data: oldData,
            });
        } catch (err) {
            setLoading(false);
        }  
    }

    useEffect(() => {
        setComplete(item.complete);
    }, [])

    return (
        <div className={`${complete ? 'line-through' : ''} flex gap-3 items-start cursor-pointer mb-2`} onClick={handleCompleteToggle}>
            <div className="basis-[24px] border-2 border-rgba[0,0,0,0.9] h-[24px] relative">
                {complete && <FontAwesomeIcon className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-[24px] font-bold !text-green-700" icon={faCheck} />}
            </div>
            <span className="flex-1">{item.title}</span>
            <div onClick={handlePropagation}>
                <FontAwesomeIcon className=" !text-red-500" icon={faTrashCan} onClick={handleDelete} />
            </div>
        </div>
    );
}
 
export default AdminToDoItem;
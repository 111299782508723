import { useState } from 'react';
import AdminAddSelection from './AdminAddSelection';
import AdminSelectorItem from './AdminSelectorItem';

const AdminSelector = ({ collection, selectedCollection, setSelectedCollection, toggleOpenState, type }) => {
    const [ addSelectionOpen, setAddSelectionOpen ] = useState(false);
    const [ editSelectionItem, setEditSelectionItem] = useState(null);

    const handlePropogation = (e) => {
        e.stopPropagation();
    }
    const handleOpenAddSelection = () => {
        if (editSelectionItem) {
            setEditSelectionItem(null);
        } else {
            setAddSelectionOpen(!addSelectionOpen);
        }
    }

    const handleCloseModal = (e) => {
        e.stopPropagation();
        toggleOpenState();
    }

    return (
        <div className="blurred-modal-outer z-50" onClick={handleCloseModal}>
            <div className="modal h-[360px] items-start content-start" onClick={handlePropogation}>
                <h1 className="basis-full font-bold uppercase border-b-[1px] border-b-[rgba(0,0,0,0.7)] mb-3 tracking-widest">{type === 'services' ? 'Service ' : type === 'nations' ? 'Country ' : ''}Selector</h1>
                {type === 'services' && !addSelectionOpen && !editSelectionItem && (
                    <div className="basis-full mb-2">
                        <span className='g-shadow rounded-lg p-3 bg-blue-400 !text-white flex justify-center items-center cursor-pointer' onClick={handleOpenAddSelection}>Add Service</span>
                    </div>
                )}
                {!addSelectionOpen && !editSelectionItem && (
                    <div className='flex-1 flex flex-row flex-wrap basis-full items-start'>
                        {type === 'nations' && (
                            <AdminSelectorItem key='ukraine' type={type} item={{id:'ukraine', name: ['', '', 'Ukraine']}} selected={selectedCollection} setSelected={setSelectedCollection}  />
                        )}
                        {type === 'services' && collection.map(item => (
                            <AdminSelectorItem key={item.id} type={type} item={item} selected={selectedCollection} setSelected={setSelectedCollection} setEditSelectionItem={setEditSelectionItem} />
                        ))}
                        {type !== 'services' && collection.map(item => (
                            <AdminSelectorItem key={item.id} type={type} item={item} selected={selectedCollection} setSelected={setSelectedCollection} />
                        ))}
                    </div>   
                )}
                {editSelectionItem && (
                    <AdminAddSelection selectionCollection={collection} toggleClose={handleOpenAddSelection} editSelectionItem={editSelectionItem} setEditSelectionItem={setEditSelectionItem} />
                )}
                {addSelectionOpen && (
                    <AdminAddSelection selectionCollection={collection} toggleClose={handleOpenAddSelection} editSelectionItem={null} setEditSelectionItem={setEditSelectionItem} />
                )}                    
            </div>
        </div>
    );
}
 
export default AdminSelector;
import { useAdmin } from '../contexts/AdminContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';

const AdminDashCrumbs = () => {
    const { dashPage, chooseNewDashPage } = useAdmin();

    return (
        <div className='flex justify-start items-center p-6 gap-3'>
            {dashPage !== 0 && <FontAwesomeIcon className='cursor-pointer text-[20px]' icon={faHome} onClick={() => {chooseNewDashPage(0)}} />}
            {dashPage !== 0 && (<span className='text-[14px]'>|</span>)}
            {dashPage !== 0 && (
                <h1 className='text-[20px]'>{
                    dashPage === 1 ? 
                        'Manage Account' : 
                    dashPage === 2 ? 
                        'Manage Countries' : 
                    dashPage === 3 ? 
                        'Manage Organisations' :
                    dashPage === 4 ?
                        'Manage Notes' :
                    dashPage === 5 ? 
                        'General Enquiries' :
                    dashPage === 6 ?
                        'Volunteer Applications' :
                    dashPage === 7 ?
                        'Information Requests' : 
                    ''
                }</h1>
            )}
        </div>
    );
}
 
export default AdminDashCrumbs;
import { useContext } from "react";
import { AppContext } from "../contexts/AppContext";
import { motion } from "framer-motion";
import { Link, useLocation } from "react-router-dom";

const TopNav = () => {
  const { language } = useContext(AppContext);
  const location = useLocation();
  const navLinks = [
    { id: 0, title: ["про нас", "À propos", "About"], link: "/about" },
    // {
    //   id: 1,
    //   title: ["Get Involved", "Get Involved", "Get Involved"],
    //   link: "/getinvolved",
    // },
    { id: 2, title: ["країни", "Pays", "Countries"], link: "/countries" },
    {
      id: 3,
      title: ["організації", "Organisations", "Organisations"],
      link: "/organisations",
    },
    { id: 4, title: ["контакти", "Contact", "Contact"], link: "/contact" },
  ];

  return (
    <div className="top-nav-container">
      {navLinks.map((item) => (
        <div key={item.id}>
          <Link to={item.link}>
            <span
              className={`${
                location.pathname.includes(item.link)
                  ? "opacity-100"
                  : "opacity-75"
              }`}
            >
              {item.title[language]}
            </span>
            {location.pathname.includes(item.link) && (
              <motion.div layoutId="navline"></motion.div>
            )}
          </Link>
        </div>
      ))}
    </div>
  );
};

export default TopNav;

import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { motion } from "framer-motion";
import OrganisationIntro from './OrganisationIntro';
import OrganisationLogo from './OrganisationLogo';
import OrganisationServices from './OrganisationServices';
import OrganisationNations from './OrganisationNations';

const OrganisationPage = ({ nations, organisations, services }) => {
    const variants = {hidden: {x:1000},visible: {x:0},exit: {x:"-100vw"}};
    const { orgName } = useParams();
    const [ org, setOrg ] = useState(null);
    const [ noOrg, setNoOrg ] = useState(false);

    useEffect(() => {
        const setName = orgName.replaceAll('_', ' ');
        if (organisations.length < 1) {
            // No nations loaded yet - will rerun when nations found
            return;
        } else {
            // Nations loaded fetch data from nations
            const foundOrganisation = organisations.find(item => (item.title.toLowerCase() === setName));
            if (!foundOrganisation) {
                // No nation found - redirect to countries page
                setNoOrg(true);
            } else {
                // Nation found - set data
                setNoOrg(false);
                setOrg(foundOrganisation);
            }
        }
    }, [nations, orgName, organisations]);

    return (
        <motion.div
            variants={variants}
            initial="hidden"
            animate="visible"
            exit="exit"
            className="page-container"
        >
            <div>
                {noOrg && (
                    // No org found - show error message
                    <>No organisation found.</>
                )}
                {!noOrg && org && (
                    // Org found and data filled - show data
                    <>
                        <div className="organisation-header">
                            <h4>{ org ? org.title : "" }</h4>
                        </div>
                        <div className='organisation-content'>
                            <div className="organisation-main">
                                <OrganisationLogo organisation={org} />
                                <OrganisationIntro organisation={org} />
                            </div>
                            {org.services && org.services.length > 0 && (
                                <OrganisationServices organisation={org} services={services} />
                            )}
                            {org.nations && org.nations.length > 0 && (
                                <OrganisationNations organisation={org} nations={nations} />
                            )}
                        </div>
                    </>
                )}
            </div>
        </motion.div>
    );
}
 
export default OrganisationPage;
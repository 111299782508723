import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { AppContext } from '../../contexts/AppContext';

import flagData from '../../utils/flagData';

const CountryCard = ({ item }) => {
    const { language } = useContext(AppContext);

    return (
        <Link to={`/countries/${item.name[2].toLowerCase().replaceAll(' ', '_')}`} >
            <div className="country-card">
                {/* Flag */}
                <div style={{ backgroundImage: `url(/images/flags/${ flagData[item.name[2].toLowerCase()] }.png` }}></div>
                {/* Title */}
                <span>{item.name[language]}</span>
            </div>
        </Link>
    );
}
 
export default CountryCard;
import { faEarthEurope, faBuildingNgo, faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import AdminDashHomeCard from './AdminDashHomeCard';

const DashHome = ({ nationCount, notesCount, orgsCount, nationsLoading, orgsLoading, notesLoading }) => {
    return (
        <div className='flex gap-3 flex-wrap mb-6'>
            {/* Countries Card */}
            <AdminDashHomeCard bgColor={'bg-green-300'} icon={faEarthEurope} loading={nationsLoading} count={nationCount} title={'Countries'} targetDashPage={2} />
            {/* Organisations Card */}
            <AdminDashHomeCard bgColor={'bg-blue-300'} icon={faBuildingNgo} loading={orgsLoading} count={orgsCount} title={'Organisations'} targetDashPage={3} />
            {/* Notes Card */}
            <AdminDashHomeCard bgColor={'bg-yellow-300'} icon={faPenToSquare} loading={notesLoading} count={notesCount} title={'Notes'} targetDashPage={4} />
        </div>
    );
}
 
export default DashHome;
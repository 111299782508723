import { useState, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faSpinner, faCheck, faToggleOff, faToggleOn } from '@fortawesome/free-solid-svg-icons';
import AdminNationEmbassies from './AdminNationEmbassies';
import TipModal from './TipModal';
import { useAdmin } from '../contexts/AdminContext';
import { useEffect } from 'react';
import AdminNextSteps from './AdminNationNextSteps';

const AdminNation = () => {
    const { selectedNation, setSelectedNation, submitNation, changes, setChanges, reloadForm, setReloadForm } = useAdmin();

    const [ initialLoad, setInitialLoad ] = useState(true);
    const [ changesFound, setChangesFound ] = useState(null);
    const [ loading, setLoading ] = useState(false);
    const [ success, setSuccess ] = useState(false);
    const [ visible, setVisible ] = useState(selectedNation.id !== 'new' ? selectedNation.visible : false);
    const [ warning, setWarning ] = useState(selectedNation.id !== 'new' ? selectedNation.warning ?? false : false);
    const [ ukrNameErr, setUkrNameErr ] = useState(false);
    const [ frNameErr, setFrNameErr ] = useState(false);
    const [ enNameErr, setEnNameErr ] = useState(false);
    const [ visaErr, setVisaErr ] = useState(false);
    const [ continentErr, setContinentErr ] = useState(false);

    const ukrNameRef = useRef();
    const frNameRef = useRef();
    const enNameRef = useRef();
    const ukrNotesRef = useRef();
    const frNotesRef = useRef();
    const enNotesRef = useRef();
    const visaRef = useRef();
    const embRef = useRef();
    const contRef = useRef();
    const ukrTravelRef = useRef();
    const frTravelRef = useRef();
    const enTravelRef = useRef();
    const policeRef = useRef();
    const fireRef = useRef();
    const ambulanceRef = useRef();

    const handleCloseNation = () => {
        setSelectedNation(null);
    }

    const handleVisibleToggle = () => {
        setVisible(!visible);
    }

    const handleWarningToggle = () => {
        setWarning(!warning);
    }

    const handleNationSubmit = async (e) => {
        e.preventDefault();
        if (loading) {
            return false;
        }
        // Set loaing 
        setLoading(true);
        // Reset errors
        setUkrNameErr(false);
        setFrNameErr(false);
        setEnNameErr(false);
        setVisaErr(false);
        setContinentErr(false);

        submitNation(selectedNation.id)
            .then(data => {
                setLoading(false);
                setSuccess(true);
                setTimeout(() => {
                    setSelectedNation(null);
                }, 1500);
            })
            .catch(err => {
                // No changes to submit
                // if (err.reason === 0) {
                
                // }
                // Validation errors
                if (err.reason === 1) {
                    if (!err.validated.ukrName) {
                        setUkrNameErr(true);
                    }
                    if (!err.validated.frName) {
                        setFrNameErr(true);
                    }
                    if (!err.validated.enName) {
                        setEnNameErr(true);
                    }
                    if (!err.validated.visa) {
                        setVisaErr(true);
                    }
                    if (!err.validated.continent) {
                        setContinentErr(true);
                    }
                }
                // Firestore errors
                // if (err.reason === 2) {

                // }
                setLoading(false);
            });
    }

    // Handle changes
    const handleChanges = () => {
        // Check if adding or editing
        let newNations, setData, hasChanged = false;
        let existingChangedNations = [...changes.nations];
        let existingChangedOrganisations = [...changes.organisations];
        setData = {
            id: selectedNation.id,
            name: (selectedNation.id === 'new' ? [ukrNameRef.current.value.toLowerCase().trim(), frNameRef.current.value.toLowerCase().trim(), enNameRef.current.value.toLowerCase().trim()] : [selectedNation.name[0], selectedNation.name[1], selectedNation.name[2]]),
            visa: visaRef.current.value.toLowerCase().trim(),
            visanotes: [ukrNotesRef.current.value.trim(), frNotesRef.current.value.trim(), enNotesRef.current.value.trim()],
            travel: [ukrTravelRef.current.value.trim(), frTravelRef.current.value.trim(), enTravelRef.current.value.trim()],
            list: embRef.current.value.toLowerCase().trim(),
            continent: contRef.current.value.toLowerCase().trim(),
            police: policeRef.current.value.trim(),
            ambulance: ambulanceRef.current.value.trim(),
            fire: fireRef.current.value.trim(),
            warning: warning,
            visible: visible
        }
        // If not new - Check changes against fetched data 
        if (setData.id !== 'new') {
            hasChanged = 
                (selectedNation.visanotes[0].trim() !== setData.visanotes[0]) || (selectedNation.visanotes[1].trim() !== setData.visanotes[1]) || (selectedNation.visanotes[2].trim() !== setData.visanotes[2]) ||
                (selectedNation.travel ? ((selectedNation.travel[0].trim() !== setData.travel[0]) || (selectedNation.travel[1].trim() !== setData.travel[1]) || (selectedNation.travel[2].trim() !== setData.travel[2])) : ((setData.travel[0] !== '') || (setData.travel[1] !== '') || (setData.travel[2] !== ''))) ||
                (selectedNation.visa.toLowerCase().trim() !== setData.visa) || (selectedNation.list.toLowerCase().trim() !== setData.list) || (selectedNation.continent.toLowerCase().trim() !== setData.continent) ||
                ((selectedNation.police && selectedNation.police.trim() !== setData.police) || (!selectedNation.police && setData.police !== '')) ||
                ((selectedNation.ambulance && selectedNation.ambulance.trim() !== setData.ambulance) || (!selectedNation.ambulance && setData.ambulance !== '')) ||
                ((selectedNation.fire && selectedNation.fire.trim() !== setData.fire) || (!selectedNation.fire && setData.fire !== '')) ||
                ((selectedNation.warning ?? false) !== setData.warning) || (selectedNation.visible !== setData.visible);
        } else {
            hasChanged = 
                (setData.name[0] !== '') || (setData.name[1] !== '') || (setData.name[2] !== '') ||
                (setData.visanotes[0] !== '') || (setData.visanotes[1] !== '') || (setData.visanotes[2] !== '') ||
                (setData.travel[0] !== '') || (setData.travel[1] !== '') || (setData.travel[2] !== '') ||
                (setData.visa !== '') || (setData.list !== '') || (setData.continent !== '') ||
                (setData.police !== '') || (setData.ambulance !== '') || (setData.fire !== '');
        }
        // Check changes for existing item 
        const foundChangeItem = existingChangedNations.find(item => (item.id === setData.id));
        if (foundChangeItem && !hasChanged) {
            newNations = existingChangedNations.filter(item => (item.id !== setData.id));
        } else if (foundChangeItem && hasChanged) {
            newNations = [setData, ...existingChangedNations.filter(item => (item.id !== setData.id))];
        } else if (hasChanged) {
            newNations = [setData, ...existingChangedNations];
        } else {
            newNations = [...existingChangedNations];
        }
        // Set new data in 
        setChanges({nations: newNations, organisations: existingChangedOrganisations});
    }

    // Check and set if data found from changes
    useEffect(() => {
        const foundChangeItem = changes.nations.find(item => (item.id === selectedNation.id));
        if (foundChangeItem) {
            setChangesFound(foundChangeItem);
            setVisible(foundChangeItem.visible);
            setWarning(foundChangeItem.warning);
        }
        setInitialLoad(false);
    }, []);

    // Trigger changes if warning or visible changed
    useEffect(() => {
        if (!initialLoad) {
            handleChanges();
        }
    }, [warning, visible]);

    // Trigger form reset if reset from header
    useEffect(() => {
        if (reloadForm && reloadForm === selectedNation.id) {
            // Reset fields
            if (selectedNation.id === 'new') {
                ukrNameRef.current.value = '';
                frNameRef.current.value = '';
                enNameRef.current.value = '';
            }
            ukrNotesRef.current.value = selectedNation.id === 'new' ? '' : selectedNation.visanotes[0];
            frNotesRef.current.value = selectedNation.id === 'new' ? '' : selectedNation.visanotes[1];
            enNotesRef.current.value = selectedNation.id === 'new' ? '' : selectedNation.visanotes[2];
            visaRef.current.value = selectedNation.id === 'new' ? '' : selectedNation.visa;
            embRef.current.value = selectedNation.id === 'new' ? '' : selectedNation.list;
            contRef.current.value = selectedNation.id === 'new' ? '' : selectedNation.continent;
            ukrTravelRef.current.value = selectedNation.id === 'new' ? '' : selectedNation.travel[0];
            frTravelRef.current.value = selectedNation.id === 'new' ? '' : selectedNation.travel[1];
            enTravelRef.current.value = selectedNation.id === 'new' ? '' : selectedNation.travel[2];
            policeRef.current.value = selectedNation.id === 'new' ? '' : selectedNation.police ?? '';
            fireRef.current.value = selectedNation.id === 'new' ? '' : selectedNation.fire ?? '';
            ambulanceRef.current.value = selectedNation.id === 'new' ? '' : selectedNation.ambulance ?? '';
            setWarning(selectedNation.id === 'new' ? false : selectedNation.warning ?? false);
            setVisible(selectedNation.id === 'new' ? false : selectedNation.visible ?? false);
            // Reset errors
            setUkrNameErr(false);
            setFrNameErr(false);
            setEnNameErr(false);
            setVisaErr(false);
            setContinentErr(false);
            // Reset reload form state        
            setReloadForm(false);
        }
    }, [reloadForm]);

    return (
        <>
            {selectedNation && (
                <div className='px-6 py-6 bg-white g-shadow rounded-lg relative overflow-hidden'>
                    <div className='absolute top-0 right-0 h-[44px] w-[44px] flex justify-center items-center bg-[rgba(0,0,0,0.5)] rounded-bl-lg cursor-pointer hover:bg-[rgba(0,0,0,0.8)]' onClick={handleCloseNation}><FontAwesomeIcon className='!text-white' icon={faTimes} /></div>
                    {selectedNation.id === 'new' && <h3 className='text-[24px] text-left'>Add Country</h3>}
                    {selectedNation.id !== 'new' && <h3 className='text-[24px] text-left capitalize'>{selectedNation.name[2]}</h3>}
                    {!initialLoad && (
                        <form action='' className='admin-form' autoComplete='off' onKeyUp={handleChanges} onSubmit={handleNationSubmit}> 
                            {selectedNation.id === 'new' && (
                                <section>
                                    <h3>Name</h3>
                                    <div>
                                        <div>
                                            <label htmlFor='ukrName'>Ukrainian</label>
                                            <input className={`${ukrNameErr ? 'errors' : ''}`} name='ukrName' id='ukrName' type='text' ref={ukrNameRef} defaultValue={changesFound ? changesFound.name[0] : ''} />
                                        </div>
                                        <div>
                                            <label htmlFor='frName'>French</label>
                                            <input className={`${frNameErr ? 'errors' : ''}`} name='frName' id='frName' type='text' ref={frNameRef} defaultValue={changesFound ? changesFound.name[1] : ''} />
                                        </div>
                                        <div>
                                            <label htmlFor='enName'>English</label>
                                            <input className={`${enNameErr ? 'errors' : ''}`} name='enName' id='enName' type='text' ref={enNameRef} defaultValue={changesFound ? changesFound.name[2] : ''} />
                                        </div>
                                    </div>
                                </section>
                            )}
                            <section className='visa-notes'>
                                <h3>Entry Procedure</h3>
                                <div>
                                    <div className='full'>
                                        <label htmlFor='ukrVisa'>Ukrainian</label>
                                        <textarea name='ukrVisa' id='ukrVisa' ref={ukrNotesRef} rows='5' defaultValue={changesFound ? changesFound.visanotes[0] : selectedNation.id === 'new' ? '' : (selectedNation.id !== 'new' && selectedNation.visanotes && selectedNation.visanotes[0])}></textarea>
                                    </div>
                                    <div className='full'>
                                        <label htmlFor='frVisa'>French</label>
                                        <textarea name='frVisa' id='frVisa' ref={frNotesRef} rows='5' defaultValue={changesFound ? changesFound.visanotes[1] : selectedNation.id === 'new' ? '' : (selectedNation.id !== 'new' && selectedNation.visanotes && selectedNation.visanotes[1])}></textarea>
                                    </div>
                                    <div className='full'>
                                        <label htmlFor='enVisa'>English</label>
                                        <textarea name='enVisa' id='enVisa' ref={enNotesRef} rows='5' defaultValue={changesFound ? changesFound.visanotes[2] : selectedNation.id === 'new' ? '' : (selectedNation.id !== 'new' && selectedNation.visanotes && selectedNation.visanotes[2])}></textarea>
                                    </div>
                                </div>
                            </section>
                            <section className='other'>
                                <h3>Other</h3>
                                <div>
                                    <div>
                                        <div className='relative pr-[20px]'>
                                            <label htmlFor='visa'>Standard Visa-free Stay</label>
                                            <TipModal content={'Duration of allowed stay for Ukrainians without a visa<br /><br /><b>yes</b> : Visa restrictions have been removed<br><b>30</b> : 30 days<br><b>90</b> : 90 days<br><b>1y</b> : One year<br><b>eu</b> : For EU nations 90 days<br /><b>no</b> : A visa is required for entry'} />
                                        </div>
                                        <input className={`${visaErr ? 'errors' : ''}`} type='text' name='visa' id='visa' ref={visaRef} defaultValue={changesFound ? changesFound.visa : selectedNation.id === 'new' ? '' : (selectedNation.id !== 'new' && selectedNation.visa !== null && selectedNation.visa)} />
                                    </div>
                                    <div>
                                        <div className='relative pr-[20px]'>
                                            <label htmlFor='emb-list'>Embassy List URL</label>
                                            <TipModal content={'Website address should include the prefix and suffix.<br /><i style="opacity:0.7; font-size:14px;">e.g. www.example.com or http://www.example.com</i>'} />
                                        </div>
                                        <input type='text' name='emb-list' id='emb-list' ref={embRef} defaultValue={changesFound ? changesFound.list : selectedNation.id === 'new' ? '' : (selectedNation.id !== 'new' && selectedNation.list !== null && selectedNation.list)} />
                                    </div>
                                    <div>
                                        <div className='relative pr-[20px]'>
                                            <label htmlFor='continent'>Continent</label>
                                            <TipModal content={'Two letter abbreviation for the nation\'s continent<br /><br /><b>na</b> : North America<br><b>sa</b> : South America<br><b>eu</b> : Europe<br><b>af</b> : Africa<br><b>as</b> : Asia<br /><b>oc</b> : Oceania'} />
                                        </div>
                                        <input className={`${continentErr ? 'errors' : ''}`} type='text' name='continent' id='continent' ref={contRef} defaultValue={changesFound ? changesFound.continent : selectedNation.id === 'new' ? '' : (selectedNation.id !== 'new' && selectedNation.continent !== null && selectedNation.continent)} />
                                    </div>
                                </div>
                            </section>
                            <section className=''>
                                <h3>Transport</h3>
                                <div>
                                    <div className='full'>
                                        <label htmlFor='ukrTravel'>Ukrainian</label>
                                        <textarea name='ukrTravel' id='ukrTravel' ref={ukrTravelRef} rows='5' defaultValue={changesFound ? changesFound.travel[0] : selectedNation.id === 'new' ? '' : (selectedNation.id !== 'new' && selectedNation.travel && selectedNation.travel[0])}></textarea>
                                    </div>
                                    <div className='full'>
                                        <label htmlFor='frTravel'>French</label>
                                        <textarea name='frTravel' id='frTravel' ref={frTravelRef} rows='5' defaultValue={changesFound ? changesFound.travel[1] : selectedNation.id === 'new' ? '' : (selectedNation.id !== 'new' && selectedNation.travel && selectedNation.travel[1])}></textarea>
                                    </div>
                                    <div className='full'>
                                        <label htmlFor='enTravel'>English</label>
                                        <textarea name='enTravel' id='enTravel' ref={enTravelRef} rows='5' defaultValue={changesFound ? changesFound.travel[2] : selectedNation.id === 'new' ? '' : (selectedNation.id !== 'new' && selectedNation.travel && selectedNation.travel[2])}></textarea>
                                    </div>
                                </div>
                            </section>
                            <section className='travel-notes'>
                                <h3>Emergency Numbers</h3>
                                <div>
                                    <div className='full'>
                                        <label htmlFor='police'>Police</label>
                                        <input type='text' name='police' id='police' ref={policeRef} defaultValue={changesFound ? changesFound.police : selectedNation.id === 'new' ? '' : (selectedNation.id !== 'new' && selectedNation.police !== null && selectedNation.police)} />
                                    </div>
                                    <div className='full'>
                                        <label htmlFor='fire'>Fire</label>
                                        <input type='text' name='fire' id='fire' ref={fireRef} defaultValue={changesFound ? changesFound.fire : selectedNation.id === 'new' ? '' : (selectedNation.id !== 'new' && selectedNation.fire !== null && selectedNation.fire)} />
                                    </div>
                                    <div className='full'>
                                        <label htmlFor='ambulance'>Ambulance</label>
                                        <input type='text' name='ambulance' id='ambulance' ref={ambulanceRef} defaultValue={changesFound ? changesFound.ambulance : selectedNation.id === 'new' ? '' : (selectedNation.id !== 'new' && selectedNation.ambulance !== null && selectedNation.ambulance)} />
                                    </div>
                                </div>
                            </section>
                            <section className='admin-visible'>
                                <div className=' justify-end mb-3'>
                                    <span className='flex items-center justify-center gap-3 cursor-pointer' onClick={handleWarningToggle}><span>Warning: </span><FontAwesomeIcon className=' text-[20px]' icon={warning ? faToggleOn : faToggleOff}/></span>
                                </div>
                                <div className=' justify-end'>
                                    <span className='flex items-center justify-center gap-3 cursor-pointer' onClick={handleVisibleToggle}><span>Visible: </span><FontAwesomeIcon className=' text-[20px]' icon={visible ? faToggleOn : faToggleOff}/></span>
                                </div>
                            </section>
                            <section className='admin-submit'>
                                <div>
                                    {!loading && !success && (<button className='g-shadow rounded-lg p-3 bg-blue-400 !text-white flex justify-center items-center'>{selectedNation.id === 'new' ? 'Add Country' : 'Update'}</button>)}
                                    {loading && (<span className='basis-[80px] flex items-center justify-center'><FontAwesomeIcon className=' text-[20px] animate-spin' icon={faSpinner}/></span>)}
                                    {success && (<span className='basis-[80px] flex items-center justify-center'><FontAwesomeIcon className=' !text-green-600 text-[20px]' icon={faCheck}/></span>)}
                                </div>
                            </section>
                        </form>
                    )}
                    {selectedNation.id !== 'new' && (
                        <div className='admin-next-steps'>
                            <h3>Next Steps</h3>
                            <AdminNextSteps selectedNation={selectedNation} />
                        </div>
                    )}

                    {selectedNation.id !== 'new' && (
                        <div className='admin-embassies'>
                            <h3>Embassy List</h3>
                            <AdminNationEmbassies selectedNation={selectedNation} />
                        </div>
                    )}
                </div>
            )} 
        </>
    );
}
 
export default AdminNation;
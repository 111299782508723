import { collection, addDoc, doc, updateDoc, Timestamp } from 'firebase/firestore';
import { db } from '../firebase-config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare, faPlus, faSpinner, faCheck, faToggleOff, faToggleOn, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { useState, useRef } from 'react';
import { validateURL, validateTel } from '../utils/validation';
import TipModal from './TipModal';
import { useAuth } from '../contexts/AuthContext';
import { useEffect } from 'react';

const AdminNationEmbassy = ({embassy, selectedNation}) => {
    const { currentUser } = useAuth();

    const [ show, setShow ] = useState(false);
    const [ loading, setLoading ] = useState(false);
    const [ success, setSuccess ] = useState(false);
    const [ visible, setVisible ] = useState(false);
    const [ open, setOpen ] = useState(false);
    const [ ukrNameErr, setUkrNameErr ] = useState(false);
    const [ frNameErr, setFrNameErr ] = useState(false);
    const [ enNameErr, setEnNameErr ] = useState(false);
    const [ addErr, setAddErr ] = useState(false);
    const [ telErr, setTelErr ] = useState(false);
    const [ webErr, setWebErr ] = useState(false);

    const ukrNameRef = useRef();
    const frNameRef = useRef();
    const enNameRef = useRef();
    const addRef = useRef();
    const telRef = useRef();
    const webRef = useRef();

    const toggleOpenEmbassy = () => {
        if (show) {
            // Reset errors
            setUkrNameErr(false);
            setFrNameErr(false);
            setEnNameErr(false);
            setAddErr(false);
            setWebErr(false);
            setTelErr(false);
        }
        setShow(!show);
    }

    const handleVisibleToggle = () => {
        setVisible(!visible);
    }

    const handleEmbassyOpen = () => {
        setOpen(!open);
    }

    const handleEmbassySubmit = async (e) => {
        e.preventDefault();
        // Check if loading already 
        if (loading) {
            return false;
        }
        // Reset errors
        setUkrNameErr(false);
        setFrNameErr(false);
        setEnNameErr(false);
        setAddErr(false);
        setWebErr(false);
        setTelErr(false);
        let oldData, newData, errors = false;
        // Validate
        if (ukrNameRef.current.value === '') {
            errors = true;
            setUkrNameErr(true);
        }
        if (frNameRef.current.value === '') {
            errors = true;
            setFrNameErr(true);
        }
        if (enNameRef.current.value === '') {
            errors = true;
            setEnNameErr(true);
        }
        if (webRef.current.value !== '' && !validateURL(webRef.current.value)) {
            errors = true;
            setWebErr(true);
        }
        // Telephone
        if (telRef.current.value !== '' && !validateTel(telRef.current.value)) {
            errors = true;
            setTelErr(true);
        }
        if (errors) {
            return false;
        }
        // Set data
        if (!embassy) {
            oldData = null;
            newData = {
                name: [ukrNameRef.current.value.toLowerCase(), frNameRef.current.value.toLowerCase(), enNameRef.current.value.toLowerCase()],
                address: addRef.current.value,
                tel: telRef.current.value.toLowerCase(),
                website: webRef.current.value.toLowerCase(),
                open: open,
                visible: visible,
                timestamp: Timestamp.now()
            }
        } else {
            oldData = embassy;
            newData = {
                name: [ukrNameRef.current.value.toLowerCase(), frNameRef.current.value.toLowerCase(), enNameRef.current.value.toLowerCase()],
                address: addRef.current.value,
                tel: telRef.current.value.toLowerCase(),
                website: webRef.current.value.toLowerCase(),
                open: open,
                visible: visible,
                timestamp: Timestamp.now()
            };
        }
        setLoading(true);
        // Submit to firestore
        if (!embassy) {
            // Adding doc
            try {
                await addDoc(collection(db, 'countryList', selectedNation.id, 'embassies-en'), newData);
                setLoading(false);
                setSuccess(true);
                setTimeout(() => {
                    // Hide and reset new embassy form
                    setSuccess(false);
                    setShow(false);
                }, 1500);
                addDoc(collection(db, 'userInteraction'), {
                    userName: currentUser.displayName ? currentUser.displayName : '',
                    userEmail: currentUser.email,
                    type: 'Adding embassy',
                    document: 'Embassy list',
                    nation: selectedNation.name[2],
                    nationID: selectedNation.id,
                    timestamp: Timestamp.now(),
                    newData: newData
                });
            } catch (err) {
                setLoading(false);
            }
        } else {
            // Updating doc
            try {
                const docRef = doc(db, 'countryList', selectedNation.id, 'embassies-en', embassy.id);
                await updateDoc(docRef, newData);
                setLoading(false);
                setSuccess(true);
                setTimeout(() => {
                    setSuccess(false);
                }, 1500);
                addDoc(collection(db, 'userInteraction'), {
                    userName: currentUser.displayName ? currentUser.displayName : '',
                    userEmail: currentUser.email,
                    type: 'Updating embassy',
                    document: 'Embassy list',
                    nation: selectedNation.name[2],
                    nationID: selectedNation.id,
                    embassyID: embassy.id,
                    timestamp: Timestamp.now(),
                    oldData: oldData,
                    newData: newData
                });
            } catch (err) {
                setLoading(false);
            }
        }
    }

    useEffect(() => {
        setVisible(current => {
            return (embassy ? embassy.visible ?? false : false)
        })
        setOpen(current => {
            return (embassy ? embassy.open ?? false : false)
        })
    }, [embassy])

    return (
        <div className={`cursor-pointer p-3 flex flex-wrap justify-start items-center gap-6 border-b-[1px] border-rgba(0,0,0,0.1) last:border-b-0`} onClick={toggleOpenEmbassy}>
            {!embassy && (
                <>
                    <FontAwesomeIcon className='' icon={faPlus} />
                    <span className='flex-1'>Add Embassy</span>
                </>
            )}
            {embassy && (
                <>
                    <FontAwesomeIcon className='' icon={faPenToSquare} />
                    <span className='flex-1 capitalize'>{embassy.name[2]}</span>
                    {!embassy.visible && (
                        <FontAwesomeIcon icon={faEyeSlash} />
                    )}
                    {!embassy.open && (
                        <span className='h-[12px] w-[12px] rounded-full bg-red-500'></span>
                    )}
                    {embassy.open && (
                        <span className='h-[12px] w-[12px] rounded-full bg-green-500'></span>
                    )}                    
                </>
            )}
            {show && (
                <form action='' className='admin-form basis-full p-3 rounded-lg g-shadow bg-[rgba(0,0,0,0.05)] cursor-default' autoComplete='off' onSubmit={handleEmbassySubmit} onClick={(e) => e.stopPropagation()}> 
                    <section>
                        <h3>Name</h3>
                        <div>
                            <div>
                                <label htmlFor='ukrName'>Ukrainian</label>
                                <input className={`${ukrNameErr ? 'errors' : ''}`} name='ukrName' id='ukrName' type='text' ref={ukrNameRef} defaultValue={embassy ? embassy.name[0] : ''} />
                            </div>
                            <div>
                                <label htmlFor='frName'>French</label>
                                <input className={`${frNameErr ? 'errors' : ''}`} name='frName' id='frName' type='text' ref={frNameRef} defaultValue={embassy ? embassy.name[1] : ''} />
                            </div>
                            <div>
                                <label htmlFor='enName'>English</label>
                                <input className={`${enNameErr ? 'errors' : ''}`} name='enName' id='enName' type='text' ref={enNameRef} defaultValue={embassy ? embassy.name[2] : ''} />
                            </div>
                        </div>
                    </section>
                    <section className='embassy-contact'>
                        <h3>Contact</h3>
                        <div>
                            <div>
                                <label htmlFor='address'>Address</label>
                                <input className={`${addErr ? 'errors' : ''}`} name='address' id='address' type='text' ref={addRef} defaultValue={embassy ? embassy.address : ''} />
                            </div>
                            <div>
                                <label htmlFor='tel'>Telephone</label>
                                <input className={`${telErr ? 'errors' : ''}`} name='tel' id='tel' type='tel' ref={telRef} defaultValue={embassy ? embassy.tel : ''} />
                            </div>
                            <div>
                                <div className='relative pr-[20px]'>
                                    <label htmlFor='link'>Website</label>
                                    <TipModal content={'Website address should include the prefix and suffix.<br /><i style="opacity:0.7; font-size:14px;">e.g. www.example.com or http://www.example.com</i>'} />
                                </div>
                                <input className={`${webErr ? 'errors' : ''}`} name='link' id='link' type='text' ref={webRef} defaultValue={embassy ? embassy.website : ''} />
                            </div>
                        </div>
                    </section>
                    <section className='admin-visible'>
                            <div className='justify-end gap-6'>
                                <span className='flex items-center justify-center gap-3 cursor-pointer' onClick={handleEmbassyOpen}><span>Open: </span><FontAwesomeIcon className=' text-[20px]' icon={open ? faToggleOn : faToggleOff}/></span>
                                <span className='flex items-center justify-center gap-3 cursor-pointer' onClick={handleVisibleToggle}><span>Visible: </span><FontAwesomeIcon className=' text-[20px]' icon={visible ? faToggleOn : faToggleOff}/></span>
                            </div>
                        </section>
                    <section className='admin-submit'>
                            <div className='h-[48px]'>
                                {!loading && !success && (<button className='g-shadow rounded-lg p-3 bg-blue-400 !text-white flex justify-center items-center'>{embassy ? 'Update Embassy' : 'Add Embassy'}</button>)}
                                {loading && (<span className='basis-[80px] flex items-center justify-center'><FontAwesomeIcon className=' text-[20px] animate-spin' icon={faSpinner}/></span>)}
                                {success && (<span className='basis-[80px] flex items-center justify-center'><FontAwesomeIcon className=' !text-green-600 text-[20px]' icon={faCheck}/></span>)}
                            </div>
                    </section>
                </form>
            )}
        </div>
    );
}
 
export default AdminNationEmbassy;
import { useContext, useState } from 'react';
import { AppContext } from '../../contexts/AppContext';
import { motion } from "framer-motion";
import { faClipboardQuestion, faPeopleGroup, faInfo } from '@fortawesome/free-solid-svg-icons';
import ContactPageTypeItem from './ContactPageTypeItem';
import ContactForm from './ContactForm';

const ContactPage = () => {
    const variants = {hidden: {x:1000},visible: {x:0},exit: {x:"-100vw"}};
    const { language } = useContext(AppContext);
    const [ contactType, setContactType ] = useState(null);
    const generalText = [{top:"загальні", bottom: "питання"}, {top:"Renseignements", bottom: "généraux"}, {top:"General", bottom: "Enquiries"}];
    const joinText = [{top:"приєднатися", bottom: "до команди"}, {top:"Rejoins", bottom: "notre équipe"}, {top:"Join Our", bottom: "Team"}];
    const requestText = [{top:"запит", bottom: "інформації"}, {top:"informations", bottom: "requises"}, {top:"Request", bottom: "Information"}];

    const handleContactType = (x) => {
        if (x === contactType) {
            setContactType(null);
        } else {
            setContactType(x);
        }
    }

    return (
        <motion.div
            variants={variants}
            initial="hidden"
            animate="visible"
            exit="exit"
            className="page-container"
        >
            <div>
                {/* <h1 className="page-title">{ sectionTitle[language] }</h1> */}
                <div className="page-list">
                    <div className={`contact-outer ${contactType !== null ? ' pb-6' : ''}`}>
                        <ContactPageTypeItem contactType={contactType} handleContactType={handleContactType} icon={faClipboardQuestion} title={generalText[language]} type={0} />
                        <ContactPageTypeItem contactType={contactType} handleContactType={handleContactType} icon={faPeopleGroup} title={joinText[language]} type={1} />
                        <ContactPageTypeItem contactType={contactType} handleContactType={handleContactType} icon={faInfo} title={requestText[language]} type={2} />
                    </div>
                </div>
            </div>
            {contactType !== null && (
                    <ContactForm key={"contactForm"} contactType={contactType} setContactType={setContactType} title={[generalText, joinText, requestText]} />
            )}
        </motion.div>
    );
}

export default ContactPage;
import React from 'react';
import { createRoot } from 'react-dom/client';
import {BrowserRouter as Router} from 'react-router-dom';
import Routing from './Routing';
import AppContextProvider from './contexts/AppContext';
import AuthContextProvider from './contexts/AuthContext';

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  // <React.StrictMode>
    <Router>
      <AuthContextProvider>
        <AppContextProvider>
          <Routing />
        </AppContextProvider>
      </AuthContextProvider>
    </Router>
  // </React.StrictMode>,
);
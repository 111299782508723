import { faListCheck, faCalendarDays } from '@fortawesome/free-solid-svg-icons';
import AdminDashHomeTool from "./AdminDashHomeTool";

const AdminDashHomeTools = ({ toDo, toDoLoading }) => {
    return (
        <div className='px-6'>
            <div className='flex gap-3 flex-wrap items-start'>
                <AdminDashHomeTool icon={faListCheck} toolName={'Tasks'} toolType={0} toDo={toDo} toDoLoading={toDoLoading} />
                <AdminDashHomeTool icon={faCalendarDays} toolName={'Calendar'} toolType={1} />
            </div>
        </div>

    );
}
 
export default AdminDashHomeTools;
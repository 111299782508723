import { createContext, useState } from "react";

export const AppContext = createContext();

const AppContextProvider = (props) => {
    const [ language, setLanguage ] = useState(0);

    const chooseLanguage = (language) => {
        setLanguage(language);
    }

    return (
        <AppContext.Provider value={{ language, chooseLanguage }}>
            {props.children}
        </AppContext.Provider>
        
    );
}
 
export default AppContextProvider;
 

import { useContext } from "react";
import { AppContext } from "../../contexts/AppContext";

const NewsItem = ({ newsItem }) => {
  const { language } = useContext(AppContext);

  return (
    <a
      href={newsItem.url}
      className="flex flex-col break460:flex-row items-center p-6 bg-gray-800 hover:bg-white g-shadow rounded-lg grow shrink gap-6 group hover:scale-[102%] transition-all cursor-pointer"
      target="_blank"
      rel="noreferrer"
    >
      <div className="w-[100px] break460:basis-[100px] h-[100px] rounded-full bg-white transition-all group-hover:bg-[rgba(0,0,0,0.1)] overflow-hidden shadow-inner p-3">
        <img
          className="w-full h-full object-contain"
          src={`/images/news-services/${newsItem.image}`}
          alt="news source logo"
        />
      </div>
      <div className="flex-1 flex flex-col justify-start items-start gap-1">
        <h3 className="transition-all text-xl font-semibold uppercase group-hover:text-darkColor">
          {newsItem.title[language]}
        </h3>
      </div>
    </a>
  );
};

export default NewsItem;

import { useContext } from "react";
import { AppContext } from "../../contexts/AppContext";
import NewsItem from "./NewsItem";

const InformationText = () => {
  const { language } = useContext(AppContext);
  const title1 = ["Інформація - ", "L'information, ", "Stay"];
  const title2 = ["це сила", "c'est le pouvoir", "Informed"];

  const information = [
    `Це вторгнення сформує світ завтрашнього дня. Важливо отримувати інформацію з декількох об’єктивних джерел (газети, незалежні ЗМІ, міжнародні організації) з різних країн, щоб переконатися, що вам відомі всі події, які відбулися. Ми рекомендуємо Al Jazeera або такі медіа, як CNN і BBC. Обидва мають команди в Україні, які щоденно звітують і оновлюють стрічки новин.`,
    `Cette invasion façonnera le monde de demain. Il est important de s'informer auprès de plusieurs sources objectives (journaux, médias indépendants, organismes internationaux) de différents pays afin de vous assurer que vous connaissez tous les faits, tels qu'ils se sont produits. On vous conseille Al Jazeera,  ou des médias comme CNN et BBC, ayant toutes deux des équipes en Ukraine qui font des reportages quotidiens et ont des flux d'actualités mis à jour en direct.`,
    `This invasion will shape the world of tomorrow. It is important to get information from several objective sources (newspapers, independent media, international organizations) from different countries to ensure that you know all the facts, as they happened. We recommend Al Jazeera, or media like CNN and BBC, both of which have teams in Ukraine that report daily and have live updated news feeds.`,
  ];

  const links = [
    {
      title: ["CNN", "CNN", "CNN"],
      image: "cnn-logo.png",
      url: "https://edition.cnn.com/world/europe",
    },
    {
      title: ["BBC News", "BBC News", "BBC News"],
      image: "BBC_Logo.png",
      url: "https://www.bbc.com/news/world/europe",
    },
    {
      title: ["Aljazeera", "Aljazeera", "Aljazeera"],
      image: "Aljazeera_eng.png",
      url: "https://www.aljazeera.com/tag/ukraine-russia-crisis/",
    },
  ];
  return (
    <div className="width-container mt-[100px] flex flex-col">
      <div className="about-text-header">
        <h1>{title1[language]}</h1>
        <h1>{title2[language]}</h1>
        <span></span>
      </div>
      <div className="about-desc">
        <p>{information[language]}</p>
      </div>
      {/* News Source List */}
      <div className="width-container flex flex-wrap gap-6 justify-center pt-[30px]">
        {links.map((item, index) => (
          <NewsItem key={index} newsItem={item} />
        ))}
      </div>
    </div>
  );
};

export default InformationText;

import { useAdmin } from "../contexts/AdminContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faSpinner, faCheck, faToggleOff, faToggleOn } from "@fortawesome/free-solid-svg-icons";
import { useState, useEffect, useRef } from "react";
import TipModal from './TipModal';
import SelectionItem from './SelectionItem';
import AdminSelector from './AdminSelector';

const AdminOrganisation = () => {
    const { selectedOrg, setSelectedOrg, submitOrganisation, changes, setChanges, services, nations, reloadForm, setReloadForm  } = useAdmin();

    const [ initialLoad, setInitialLoad ] = useState(true);
    const [ loading, setLoading ] = useState(false);
    const [ success, setSuccess ] = useState(false);
    const [ visible, setVisible ] = useState(selectedOrg.id !== 'new' ? selectedOrg.visible : false);
    const [ online, setOnline ] = useState(selectedOrg.id !== 'new' ? selectedOrg.online ?? false : false);
    const [ international, setInternational ] = useState(selectedOrg.id !== 'new' ? selectedOrg.international ?? false : false);
    const [ changesFound, setChangesFound ] = useState(null);
    const [ selectedNations, setSelectedNations ] = useState([]);
    const [ selectedServices, setSelectedServices ] = useState([]);
    const [ nationSelectorOpen, setNationSelectorOpen ] = useState(false);
    const [ serviceSelectorOpen, setServiceSelectorOpen ] = useState(false);
    const [file, setFile] = useState(null);
    const [ sortedServices, setSortedServices ] = useState([]);

    const [ titleErr, setTitleErr ] = useState(false);
    const [ linkErr, setLinkErr ] = useState(false);
    const [ telErr, setTelErr ] = useState(false);
    const [ emailErr, setEmailErr ] = useState(false);
    const [ ukrBodyErr, setUkrBodyErr ] = useState(false);
    const [ frBodyErr, setFrBodyErr ] = useState(false);
    const [ enBodyErr, setEnBodyErr ] = useState(false);
    const [ logoErr, setLogoErr ] = useState(false);
    const [ logoUploadErr, setLogoUploadErr ] = useState(false);

    const titleRef = useRef();
    const linkRef = useRef();
    const telRef = useRef();
    const emailRef = useRef();
    const ukrBodyRef = useRef();
    const frBodyRef = useRef();
    const enBodyRef = useRef();

    const handleCloseOrg = () => {
        setSelectedOrg(null);
    }

    const handleVisibleToggle = () => {
        setVisible(!visible);
    }

    const handleOnlineToggle = () => {
        setOnline(!online);
    }

    const handleInternationalToggle = () => {
        setInternational(!international);
    }

    // Sort services AZ 
    useEffect(() => {
        let sortData = [];
        services.forEach((item) => {
            sortData.push(item);
        })
        // Sort data
        sortData.sort(function (x, y) {
            let a = x.name[2].toUpperCase(),
                b = y.name[2].toUpperCase();
            return a === b ? 0 : a > b ? 1 : -1;
        })
        setSortedServices([...sortData]);
    }, [services]);


    const handleChanges = () => {
        // Check if adding or editing
        let newOrgs, setData, hasChanged = false;
        let existingChangedOrgs = [...changes.organisations];
        let existingChangedNations = [...changes.nations];
        setData = {
            id: selectedOrg.id,
            title: titleRef.current.value.toLowerCase().trim(),
            link: linkRef.current.value.toLowerCase().trim(),
            tel: telRef.current.value.toLowerCase().trim().replaceAll(' ', ''),
            email: emailRef.current.value.toLowerCase().trim().replaceAll(' ', ''),
            body: [ukrBodyRef.current.value.trim(), frBodyRef.current.value.trim(), enBodyRef.current.value.trim()],
            nations: selectedNations.length > 0 ? selectedNations : [],
            services: selectedServices.length > 0 ? selectedServices : [],
            file: file,
            logoUrl: selectedOrg.logoUrl ?? '',
            visible: visible,
            online: online,
            international: international
        }
        // If not new - Check changes against fetched data 
        if (setData.id !== 'new') {
            hasChanged = 
                (selectedOrg.title.toLowerCase().trim() !== setData.title) || (selectedOrg.link.toLowerCase().trim() !== setData.link) || 
                (selectedOrg.tel.toLowerCase().trim().replaceAll(' ', '') !== setData.tel) || 
                (selectedOrg.email.toLowerCase().trim().replaceAll(' ', '') !== setData.email) || 
                (selectedOrg.body[0].trim() !== setData.body[0]) || (selectedOrg.body[1].trim() !== setData.body[1]) || (selectedOrg.body[2].trim() !== setData.body[2]) || 
                (selectedOrg.visible !== setData.visible) || ((selectedOrg.online ?? false) !== setData.online) || ((selectedOrg.international ?? false) !== setData.international) || 
                (JSON.stringify(selectedOrg.services) !== JSON.stringify(setData.services)) ||
                (JSON.stringify(selectedOrg.nations) !== JSON.stringify(setData.nations)) || 
                (setData.file !== null);
        } else {
            hasChanged = 
                (setData.title !== '') || (setData.link !== '') || (setData.tel !== '') || (setData.email !== '') || 
                (setData.body[0] !== '') || (setData.body[1] !== '') || (setData.body[2] !== '')
        }
        // Check changes for existing item 
        const foundChangeItem = existingChangedOrgs.find(item => (item.id === setData.id));
        if (foundChangeItem && !hasChanged) {
            newOrgs = existingChangedOrgs.filter(item => (item.id !== setData.id));
        } else if (foundChangeItem && hasChanged) {
            newOrgs = [setData, ...existingChangedOrgs.filter(item => (item.id !== setData.id))];
        } else if (hasChanged) {
            newOrgs = [setData, ...existingChangedOrgs];
        } else {
            newOrgs = [...existingChangedOrgs];
        }
        // Set new data in 
        setChanges({nations: existingChangedNations, organisations: newOrgs});
    }

    const handleNationSelector = () => {
        setNationSelectorOpen(!nationSelectorOpen);
    }

    const handleServiceSelector = () => {
        setServiceSelectorOpen(!serviceSelectorOpen);
    }

    const handleImageChange = (e) => {
        setLogoErr(false);
        setFile(e.target.files[0]);
        const imgPreview = document.getElementById('img-preview');
        imgPreview.src = URL.createObjectURL(e.target.files[0]);
    }

    const handleOrgSubmit = (e) => {
        e.preventDefault();
        if (loading) {
            return false;
        }
        // Set loaing 
        setLoading(true);
        // Reset errors
        setTitleErr(false);
        setLinkErr(false);
        setTelErr(false);
        setEmailErr(false);
        setLogoErr(false);
        submitOrganisation(selectedOrg.id)
            .then(data => {
                setLoading(false);
                setSuccess(true);
                setTimeout(() => {
                    setSelectedOrg(null);
                }, 1500);
            })
            .catch(err => {
                // No changes to submit
                // if (err.reason === 0) {

                // }
                // Validation errors
                if (err.reason === 1) {
                    if (!err.validated.title) {
                        setTitleErr(true);
                    }
                    if (!err.validated.link) {
                        setLinkErr(true);
                    }
                    if (!err.validated.tel) {
                        setTelErr(true);
                    }
                    if (!err.validated.email) {
                        setEmailErr(true);
                    }
                    if (!err.validated.file) {
                        setLogoErr(true);
                    }
                }
                setLoading(false);
            });
    }
    
    // Set selected nations
    useEffect(() => {
        if (selectedOrg.id !== 'new' && selectedOrg.nations) {
            // Organisation locations received
            if (selectedOrg.nations.length > 0) {
                // Has content - add to local selected nations
                const existingNations = selectedOrg.nations.map(element => {
                    return element;
                });
                existingNations.sort((a, b) => a.localeCompare(b));
                setSelectedNations([...existingNations]);
            }
        }
    }, []);

    // Set selected services
    useEffect(() => {
        if (selectedOrg.id !== 'new' && selectedOrg.services) {
            // Organisation services received
            if (selectedOrg.services.length > 0) {
                // Has content - add to local selected nations
                const existingServices = selectedOrg.services.map(element => {
                    return element;
                });
                setSelectedServices([...existingServices]);
            }
        }
    }, []);

    // Check and set if data found from changes
    useEffect(() => {
        const foundChangeItem = changes.organisations.find(item => (item.id === selectedOrg.id));
        if (foundChangeItem) {
            setChangesFound(foundChangeItem);
            setVisible(foundChangeItem.visible);
            setOnline(foundChangeItem.online);
            setInternational(foundChangeItem.international);
            setSelectedServices(current => (foundChangeItem.services.length > 0 ? [...foundChangeItem.services] : []));
            setSelectedNations(current => (foundChangeItem.nations.length > 0 ? [...foundChangeItem.nations] : []));
            setFile(foundChangeItem.file);    
        }
        setInitialLoad(false);
    }, []);

    // Trigger changes if warning or visible changed
    useEffect(() => {
        if (!initialLoad) {
            handleChanges();
        }
    }, [visible, online, international, selectedServices, selectedNations, file]);

     // Trigger form reset if reset from header
     useEffect(() => {
        if (reloadForm && reloadForm === selectedOrg.id) {
            // Reset fields
            titleRef.current.value = selectedOrg.id === 'new' ? '' : selectedOrg.title;
            linkRef.current.value = selectedOrg.id === 'new' ? '' : selectedOrg.link;
            telRef.current.value = selectedOrg.id === 'new' ? '' : selectedOrg.tel;
            emailRef.current.value = selectedOrg.id === 'new' ? '' : selectedOrg.email;
            ukrBodyRef.current.value = selectedOrg.id === 'new' ? '' : selectedOrg.body[0];
            frBodyRef.current.value = selectedOrg.id === 'new' ? '' : selectedOrg.body[1];
            enBodyRef.current.value = selectedOrg.id === 'new' ? '' : selectedOrg.body[2];
            setVisible(selectedOrg.id === 'new' ? false : selectedOrg.visible ?? false);
            setOnline(selectedOrg.id === 'new' ? false : selectedOrg.online ?? false);
            setInternational(selectedOrg.id === 'new' ? false : selectedOrg.international ?? false);
            setSelectedServices(current => (selectedOrg.id === 'new'? [] : selectedOrg.services.length > 0 ? [...selectedOrg.services] : []));
            setSelectedNations(current => (selectedOrg.id === 'new'? [] : selectedOrg.nations.length > 0 ? [...selectedOrg.nations] : []));
            setFile(null);
            // Reset errors
            setTitleErr(false);
            setLinkErr(false);
            setTelErr(false);
            setEmailErr(false);
            setUkrBodyErr(false);
            setFrBodyErr(false);
            setEnBodyErr(false);
            setLogoErr(false);
            setLogoUploadErr(false);
            // Reset reload form state
            setReloadForm(false);
        }
    }, [reloadForm]);

    return (
        <>
            {selectedOrg && (
                <div className='px-6 py-6 bg-white g-shadow rounded-lg relative overflow-hidden'>
                    <div
                        className='absolute top-0 right-0 h-[44px] w-[44px] flex justify-center items-center bg-[rgba(0,0,0,0.5)] rounded-bl-lg cursor-pointer hover:bg-[rgba(0,0,0,0.8)]'
                        onClick={handleCloseOrg}
                    >
                        <FontAwesomeIcon className='!text-white' icon={faTimes} />
                    </div>
                    {selectedOrg.id === 'new' && <h3 className='text-[24px] text-left'>Add Organisation</h3>}
                    {selectedOrg.id !== 'new' && <h3 className='text-[24px] text-left capitalize'>{selectedOrg.title}</h3>}
                    {!initialLoad && (
                        <>
                            <form action='' className='admin-form' autoComplete='off' onKeyUp={handleChanges} onSubmit={handleOrgSubmit}> 
                                <section>
                                    <h3>Contact</h3>
                                    <div>
                                        <div>
                                            <label htmlFor='title'>Title</label>
                                            <input className={`${titleErr ? 'errors' : ''}`} name='title' id='title' type='text' ref={titleRef} defaultValue={changesFound ? changesFound.title : selectedOrg.title ?? ''} />
                                        </div>
                                        <div>
                                            <div className='relative pr-[20px]'>
                                                <label htmlFor='link'>Website</label>
                                                <TipModal content={'Website address should include the prefix and suffix.<br /><i style="opacity:0.7; font-size:14px;">e.g. https://www.example.com or http://www.example.com</i>'} />
                                            </div>
                                            <input className={`${linkErr ? 'errors' : ''}`} name='link' id='link' type='text' ref={linkRef} defaultValue={changesFound ? changesFound.link : selectedOrg.link ?? ''} />
                                        </div>
                                        <div>
                                            <label htmlFor='tel'>Telephone</label>
                                            <input className={`${telErr ? 'errors' : ''}`} name='tel' id='tel' type='tel' ref={telRef} defaultValue={changesFound ? changesFound.tel : selectedOrg.tel ?? ''} />
                                        </div>
                                        <div>
                                            <label htmlFor='tel'>Email</label>
                                            <input className={`${emailErr ? 'errors' : ''}`} name='email' id='email' type='text' ref={emailRef} defaultValue={changesFound ? changesFound.email : selectedOrg.email ?? ''} />
                                        </div>
                                    </div>
                                </section>
                                <section>
                                    <h3>Description</h3>
                                    <div>
                                        <div>
                                            <label htmlFor='ukrBody'>Ukrainian</label>
                                            <textarea className={`${ukrBodyErr ? 'errors' : ''}`} name='ukrBody' id='ukrBody' ref={ukrBodyRef} rows='5' defaultValue={changesFound ? changesFound.body[0] : selectedOrg.id === 'new' ? '' : selectedOrg.body[0] ?? ''} ></textarea>
                                        </div>
                                        <div>
                                            <label htmlFor='frBody'>French</label>
                                            <textarea className={`${frBodyErr ? 'errors' : ''}`} name='frBody' id='frBody' ref={frBodyRef} rows='5' defaultValue={changesFound ? changesFound.body[1] : selectedOrg.id === 'new' ? '' : selectedOrg.body[1] ?? ''} ></textarea>
                                        </div>
                                        <div>
                                            <label htmlFor='enBody'>English</label>
                                            <textarea className={`${enBodyErr ? 'errors' : ''}`} name='enBody' id='enBody' ref={enBodyRef} rows='5' defaultValue={changesFound ? changesFound.body[2] : selectedOrg.id === 'new' ? '' : selectedOrg.body[2] ?? ''}></textarea>
                                        </div>
                                    </div>
                                </section>
                                <section>
                                    <h3>Services</h3>
                                    {selectedServices.length > 0 && (
                                        <div className="org-nation-list">
                                            {selectedServices.map(item => (
                                                <SelectionItem key={item} id={item} type='services' collection={services} admin={true} />
                                            ))}
                                        </div>
                                    )}
                                    <div className='flex'>
                                        <span className='g-shadow rounded-lg p-3 bg-blue-400 !text-white flex justify-center items-center cursor-pointer' onClick={handleServiceSelector}>Manage Services</span>
                                    </div>
                                </section>
                                <section className="">
                                    <h3>Location</h3>
                                    {selectedNations.length > 0 && (
                                        <div className="org-nation-list">
                                            {selectedNations.map(item => (
                                                <SelectionItem key={item} id={item} type='nations' collection={nations} admin={true} />
                                            ))}
                                        </div>
                                    )}
                                    <div className='flex mb-6'>
                                        <span className='g-shadow rounded-lg p-3 bg-blue-400 !text-white flex justify-center items-center cursor-pointer' onClick={handleNationSelector}>Manage Locations</span>
                                    </div>
                                    <div className="justify-end items-end">
                                            <span className='flex items-center justify-center gap-3 cursor-pointer' onClick={handleOnlineToggle}><span>Online: </span><FontAwesomeIcon className=' text-[20px]' icon={online ? faToggleOn : faToggleOff}/></span>
                                            <span className='flex items-center justify-center gap-3 cursor-pointer' onClick={handleInternationalToggle}><span>International: </span><FontAwesomeIcon className=' text-[20px]' icon={international ? faToggleOn : faToggleOff}/></span>
                                    </div>
                                </section>
                                <section>
                                    <h3>Logo</h3>
                                    <img id="img-preview" alt="organisation-logo-preview" className={`max-w-[200px] rounded-lg ${(file || selectedOrg.logoUrl) ? 'block mb-3' : 'hidden'}`} src={file !== null ? URL.createObjectURL(file) : selectedOrg.logoUrl ?? ""} />
                                    <div className='flex'>
                                        <label htmlFor="orgLogo" className='g-shadow rounded-lg p-3 bg-blue-400 !text-white flex justify-center items-center cursor-pointer'>Choose Image</label>
                                        <input type="file" id="orgLogo" accept="image/*" className='hidden' onChange={handleImageChange}  />
                                    </div>
                                    {logoErr && (
                                        <p className=" text-red-600 pt-2">* An image is required for each organisation.</p>
                                    )}
                                    {logoUploadErr && (
                                        <p className=" text-red-600 pt-2">* Image failed to upload.</p>
                                    )}
                                </section>
                                <section className='admin-visible'>
                                    <div className='justify-end gap-6'>
                                        <span className='flex items-center justify-center gap-3 cursor-pointer' onClick={handleVisibleToggle}><span>Visible: </span><FontAwesomeIcon className=' text-[20px]' icon={visible ? faToggleOn : faToggleOff}/></span>
                                    </div>
                                </section>
                                <section className='admin-submit'>
                                    <div className='h-[48px]'>
                                        {!loading && !success && (<button className='g-shadow rounded-lg p-3 bg-blue-400 !text-white flex justify-center items-center'>Publish</button>)}
                                        {loading && (<span className='basis-[80px] flex items-center justify-center'><FontAwesomeIcon className=' text-[20px] animate-spin' icon={faSpinner}/></span>)}
                                        {success && (<span className='basis-[80px] flex items-center justify-center'><FontAwesomeIcon className=' !text-green-600 text-[20px]' icon={faCheck}/></span>)}
                                    </div>
                                </section>

                            </form>
                            {nationSelectorOpen && (
                                <AdminSelector collection={nations} selectedCollection={selectedNations} setSelectedCollection={setSelectedNations} toggleOpenState={handleNationSelector} type="nations" />
                            )}
                            {serviceSelectorOpen && (
                                <AdminSelector collection={sortedServices} selectedCollection={selectedServices} setSelectedCollection={setSelectedServices} toggleOpenState={handleServiceSelector} type="services" />
                            )}
                        </>
                    )}
                </div>
            )} 
        </>
    );
}
 
export default AdminOrganisation;
import { useContext } from "react";
import { AppContext } from "../../contexts/AppContext";

const CountryVisa = ({ nation }) => {
  const { language } = useContext(AppContext);
  const title = ["візові вимоги", "Visa", "Visa Requirements"];
  const eu2 = [
    <p className="country-text allow_select">
      Громадяни України можуть перебувати в будь-якому місці ЄС на термін до 90
      днів без будь-якої візи. ЄС запровадив Директиву про тимчасовий захист
      (TPD), щоб надати українцям можливості імміграції по всьому ЄС. Після
      прибуття до країни ЄС громадяни України зможуть подати заявку на отримання
      статусу TPD, який дасть їм право вільно подорожувати, працювати, а також
      надаватиме їм доступ до соціальних пільг, таких як житло та медичне
      обслуговування. Європейська комісія розробила{" "}
      <a
        href="https://ec.europa.eu/info/strategy/priorities-2019-2024/stronger-europe-world/eu-solidarity-ukraine/eu-assistance-ukraine/information-people-fleeing-war-ukraine_uk"
        target="_blank"
        rel="noreferrer"
      >
        цей веб-сайт
      </a>
      , щоб надати всім, хто рятується від війни, останню інформацію.
    </p>,
    <p className="country-text allow_select">
      Les citoyens ukrainiens peuvent séjourner n'importe où dans l'UE pendant
      une période allant jusqu'à 90 jours sans visa. L'UE a mis en œuvre la
      directive sur la protection temporaire (TPD) pour offrir aux Ukrainiens
      des options d'immigration dans toute l'UE. Après leur arrivée dans un pays
      de l'UE, les citoyens ukrainiens pourront demander le statut TPD qui leur
      donnera le droit de voyager librement, de travailler et leur donnera
      également accès à des avantages sociaux tels que le logement et les soins
      de santé. La Commission européenne a développé{" "}
      <a
        href="https://ec.europa.eu/info/strategy/priorities-2019-2024/stronger-europe-world/eu-solidarity-ukraine/eu-assistance-ukraine/information-people-fleeing-war-ukraine"
        target="_blank"
        rel="noreferrer"
      >
        ce site web
      </a>{" "}
      pour fournir à toute personne fuyant la guerre les dernières informations.
    </p>,
    <p className="country-text allow_select">
      Ukrainian citizens are able to stay anywhere within the EU for a period up
      to 90 days without any visa. The EU has implement the Temporary Protection
      Directive (TPD), to provide Ukrainians with immigration options throughout
      the EU. After arriving in an EU country, Ukrainian citizens will be able
      to apply for TPD status which will give them rights to travel freely, work
      and also give them access to social benefits such as housing and
      healthcare. The European Commission have developed{" "}
      <a
        href="https://ec.europa.eu/info/strategy/priorities-2019-2024/stronger-europe-world/eu-solidarity-ukraine/eu-assistance-ukraine/information-people-fleeing-war-ukraine"
        target="_blank"
        rel="noreferrer"
      >
        this website
      </a>{" "}
      to provide anyone fleeing the war with the latest information.
    </p>,
  ];

  const oneyear = [
    "Громадяни України можуть перебувати в Грузії 1 рік без візи.",
    "Les citoyens ukrainiens peuvent séjourner en Géorgie pendant 1 an sans visa.",
    "Ukrainian citizens are able to stay in Georgia for 1 year without a visa.",
  ];
  const thirty = [
    "Громадяни України мають право перебувати до 90 днів без візи. Багато країн надають українцям більше прав і продовження їхньої безвізової політики, ми зробимо все можливе, щоб оновити цю інформацію, коли вона буде оголошена.",
    "Les citoyens ukrainiens ont le droit de rester jusqu'à 90 jours sans avoir besoin de visa. De nombreux pays accordent aux Ukrainiens plus de droits et d'extensions sur leurs politiques sans visa, nous ferons de notre mieux pour mettre à jour ces informations lorsqu'elles seront annoncées.",
    `Ukrainian citizens are entitled to stay up to 30 days without the need for any visa. Many nations are giving Ukrainians more rights and extensions on their visa-free policies, we will do our best to update this information when it is announced.`,
  ];
  const ninety = [
    "Громадяни України мають право перебувати до 90 днів без візи. Багато країн надають українцям більше прав і продовження їхньої безвізової політики, ми зробимо все можливе, щоб оновити цю інформацію, коли вона буде оголошена.",
    "Les citoyens ukrainiens ont le droit de rester jusqu'à 90 jours sans avoir besoin de visa. De nombreux pays accordent aux Ukrainiens plus de droits et d'extensions sur leurs politiques sans visa, nous ferons de notre mieux pour mettre à jour ces informations lorsqu'elles seront annoncées.",
    `Ukrainian citizens are entitled to stay up to 90 days without the need for any visa. Many nations are giving Ukrainians more rights and extensions on their visa-free policies, we will do our best to update this information when it is announced.`,
  ];
  const none = [
    "Усі вимоги до вступу були тимчасово припинені. Українцям дозволено в’їзд без паспорта та інших проїзних документів.",
    "Toutes les conditions d'entrée ont été temporairement suspendues. Les Ukrainiens sont autorisés à entrer sans passeport ni autres documents de voyage.",
    "All entry requirements have been temporarily suspended. Ukrainians are permitted to enter without a passport or other travel documents.",
  ];
  const required = [
    "У цей час для в’їзду потрібна віза. Багато країн надають українцям більше прав і знімають візові обмеження на в’їзд, ми зробимо все можливе, щоб оновити цю інформацію, коли вона буде оголошена.",
    "Un visa est requis pour l'entrée en ce moment. De nombreux pays accordent plus de droits aux Ukrainiens et suppriment les restrictions de visa pour l'entrée, nous ferons de notre mieux pour mettre à jour ces informations lorsqu'elles seront annoncées.",
    "A visa is required for entry at this time. Many nations are giving Ukrainians more rights and removing visa restrictions for entry, we will do our best to update this information when it is announced.",
  ];
  const entry = [
    `Процедура вступу для ${nation.name[language]}`,
    `Procédure d'entrée: ${nation.name[language]}`,
    `Entry procedure for ${nation.name[language]}`,
  ];

  return (
    <div className="">
      <h3 className="country-section-title">{title[language]}</h3>
      {nation.visa && nation.visa !== "" && (
        <div>
          {/* EU Nation */}
          {nation.visa === "eu" && <>{eu2[language]}</>}
          {nation.visa === "90" && (
            <p className="country-text allow_select">{ninety[language]}</p>
          )}
          {nation.visa === "1y" && (
            <p className="country-text allow_select">{oneyear[language]}</p>
          )}
          {nation.visa === "yes" && (
            <p className="country-text allow_select">{none[language]}</p>
          )}
          {nation.visa === "no" && (
            <p className="country-text allow_select">{required[language]}</p>
          )}
          {nation.visa === "30" && (
            <p className="country-text allow_select">{thirty[language]}</p>
          )}
        </div>
      )}
      {nation.visanotes && nation.visanotes[language] !== "" && (
        <div className="country-inner-box">
          <h4 className="country-inner-title">{entry[language]}</h4>
          <p
            className="country-text allow_select"
            dangerouslySetInnerHTML={{
              __html: nation.visanotes[language].replace(
                /(?:\r\n|\r|\n)/g,
                "<br>"
              ),
            }}
          ></p>
        </div>
      )}
    </div>
  );
};

export default CountryVisa;

import { useContext } from "react";
import { AppContext } from '../../contexts/AppContext';

const ContactFormSuccess = () => {
    const { language } = useContext(AppContext);
    const successTitle = ['Повідомлення отримано', 'Message reçu', 'Message received'];
    const successMessage = [
        'Дякуємо, що звернулися до нас. Наша команда відповість вам якомога швидше.',
        'Merci de nous contacter. Notre équipe vous répondra dans les plus brefs délais.',
        'Thank you for contacting us. Our team will reply to you as soon as possible.'
    ];

    return (
        <div className="contact-form-result">
            <h3>{ successTitle[language] }</h3>
            <p>{ successMessage[language] }</p>
        </div>
    );
}
 
export default ContactFormSuccess;
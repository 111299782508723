import { useState, useRef, useContext } from "react";
import { AppContext } from '../../contexts/AppContext';
import ContactFormField from "./ContactFormField";
import ContactFormLoading from "./ContactFormLoading";
import ContactFormSuccess from "./ContactFormSuccess";
import { validateEmail } from '../../utils/validation';
import { collection, addDoc, Timestamp } from 'firebase/firestore'
import { db } from '../../firebase-config';


const ContactFormGeneral = ({ setContactType }) => {
    const { language } = useContext(AppContext);

    const nameRef = useRef();
    const emailRef = useRef();
    const messageRef = useRef();

    const [ loading, setLoading ] = useState(false);
    const [ success, setSuccess ] = useState(false);
    const [ nameErr, setNameErr ] = useState(false);
    const [ emailErr, setEmailErr ] = useState(false);
    const [ messageErr, setMessageErr ] = useState(false);

    const message = [
        'Ми раді отримувати ваші пропозиції та коментарі. Не вагайтеся заповнити контактну форму нижче, і ми зв’яжемося з вами якомога швидше.',
        'Nous aimons entendre vos suggestions et commentaires. N\'hésitez pas à remplir le formulaire de contact ci-dessous et nous vous répondrons dans les plus brefs délais.',
        'We love to hear your suggestions and comments. Feel free to complete the contact form below and we will get back to you as soon as possible.'
    ];

    const emailTip = [
        'Укажіть дійсну електронну адресу, щоб ми могли відповісти на ваше повідомлення.',
        'Indiquez une adresse e-mail valide afin que nous puissions répondre à votre message.',
        'Provide a valid email address so we can reply to your message.'
    ];
    
    const nameTitle = ['Ваше ім\'я', 'votre nom', 'Your Name'];
    const emailTitle = ['Ваш емейл', 'Votre e-mail', 'Your Email'];
    const messageTitle = ['повідомлення', 'Message', 'Message'];
    const cancelTitle = ['Скасувати', 'Annuler', 'Cancel'];
    const submitTitle = ['відправити', 'envoyer', 'Submit'];

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        // Cancel if message sending
        if (loading) {
            return false
        }
        // Reset errors
        setNameErr(false);
        setEmailErr(false);
        setMessageErr(false);
        let errors = false;
        // Validate fields
        if (nameRef.current.value === '') {
            errors = true;
            setNameErr(true);
        }
        if (emailRef.current.value === '' || !validateEmail(emailRef.current.value)) {
            errors = true;
            setEmailErr(true);
        }
        if (messageRef.current.value === '') {
            errors = true;
            setMessageErr(true);
        }
        if (errors) {
            return false;
        }
        setLoading(true);
        // Loading and submit
        const data = {
            name: nameRef.current.value.trim(),
            email: emailRef.current.value.toLowerCase(),
            message: messageRef.current.value,
            type: 0,
            timestamp: Timestamp.now()
        }
        try {
            await addDoc(collection(db, 'messages'), data);
            setSuccess(true);
            setLoading(false);
         } catch (err) {
            setLoading(false);
            setSuccess(false);
         }
    }

    const handleFormCancel = (e) => {
        e.preventDefault();
        setContactType(null);
    }

    return (
        <div>
            {!loading && success && <ContactFormSuccess />}
            {loading && !success && <ContactFormLoading />}
            {!success && !loading && (
                <>
                    <p>{ message[language] }</p>
                    <form action="" onSubmit={handleFormSubmit} autoComplete='off' >
                        <div>
                            <ContactFormField title={nameTitle[language]} formId={'name'} formRef={nameRef} error={nameErr} isInput={true} hasTip={false} />
                            <ContactFormField title={emailTitle[language]} formId={'email'} formRef={emailRef} error={emailErr} isInput={true} hasTip={true} tipText={emailTip[language]} />
                            <ContactFormField title={messageTitle[language]} formId={'message'} formRef={messageRef} error={messageErr} isInput={false} hasTip={false} />
                            <div className="contact-submit-container">
                                <span onClick={handleFormCancel} >{ cancelTitle[language] }</span>
                                <button type="submit" >{ submitTitle[language] }</button>
                            </div>
                        </div>
                    </form>
                </>
            )}
        </div>
    );
}
 
export default ContactFormGeneral;
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../contexts/AppContext";
import { ref, getDownloadURL } from "firebase/storage";
import { storage } from "../../firebase-config";

const AboutTeamCard = ({ teamMember, index }) => {
  const { language } = useContext(AppContext);
  const [imgUrl, setimgUrl] = useState(false);
  useEffect(() => {
    if (teamMember.imgUrl) {
      const fsImgRef = ref(storage, "team/" + teamMember.imgUrl);
      getDownloadURL(fsImgRef).then((url) => {
        setimgUrl(url);
      });
    }
  }, []);

  return (
    <div
      className={`${
        index === 3 ? "relative left-0 break750:left-1/2 break1220:left-0" : ""
      } ${
        index === 4 ? "relative left-0 break500:left-1/2 break1220:left-0" : ""
      }`}
    >
      <div>
        <div>
          {imgUrl && <img alt={teamMember.name + " Image"} src={imgUrl} />}
        </div>
        <h3>{teamMember.name}</h3>
        <h4>{teamMember.position[language]}</h4>
      </div>
    </div>
  );
};

export default AboutTeamCard;

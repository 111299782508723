import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare, faPlus } from '@fortawesome/free-solid-svg-icons';
import { useEffect } from 'react';
import { useAdmin } from '../contexts/AdminContext';
import flagData from '../utils/flagData';
import AdminNation from './AdminNation';

const AdminNations = () => {
    const { nations, selectedNation, setSelectedNation } = useAdmin();

    const handleNationSelect = (nation) => {
        setSelectedNation(nation);
    }

    const scrollToTop = (type) => {
        window.scrollTo({
          top: 0,
          behavior: type
        });
    }

    useEffect(() => {
        scrollToTop('auto');
    }, [selectedNation]);

    return (
        <>
            {/* Nation or new nation selected */}
            {selectedNation && (
                <AdminNation />
            )}
            {/* No nation selected - show list */}
            {!selectedNation && (
                <div className='grid grid-cols-2 gap-6 break560:grid-cols-3 break700:grid-cols-4 break850:grid-cols-5 break1000:grid-cols-6'>
                    <div className='country-card-new cursor-pointer' onClick={() => {handleNationSelect({id: 'new'})}}>
                        <div className={`relative w-full pt-[66%] bg-cover bg-center rounded-lg h-0`}>
                            <FontAwesomeIcon className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-[58px] text-[rgba(0,0,0,0.5)]' icon={faPlus} />
                        </div>
                        <span className='block text-center'>Add Country</span>
                    </div>
                    
                    {nations.map(item => (
                        <div key={item.id} className={`country-card cursor-pointer ${!item.visible && 'opacity-30'}`} onClick={() => {handleNationSelect(item)}}>
                            <div className={`relative w-full pt-[66%] bg-cover bg-center rounded-lg h-0 g-shadow`} style={{
                                backgroundImage: `url(/images/flags/${ flagData[item.name[2].toLowerCase()] }.png`
                            }}>
                                <span className='info-icon hidden absolute hover:flex top-[10px] right-[10px] border-2 rounded-full h-[34px] w-[34px] items-center justify-center font-extrabold text-[18px] text-lightColor bg-[rgba(0,0,0,0.4)]'>
                                    <FontAwesomeIcon className='text-white text-[12px]' icon={faPenToSquare}/>
                                </span>
                            </div>
                            <span className='block text-center capitalize'>{item.name[2]}</span>
                        </div>
                    ))}
                </div>
            )}
        </>
    );
}
 
export default AdminNations;
import { Link } from "react-router-dom";

const CountryOrganisationCard = ({ organisation }) => {
    return (
        <Link to={`/organisations/${organisation.title.toLowerCase().replaceAll(' ', '_')}`} >
            <div className="country-organisation">
                {/* Organisation Icon */}
                <div>
                    {organisation.logoUrl && (
                        <img src={organisation.logoUrl} alt={organisation.title + ' Logo'} />
                    )}
                </div>
                {/* Organisation Title */}
                <span>{organisation.title}</span>
            </div>
        </Link>
    );
}
 
export default CountryOrganisationCard;
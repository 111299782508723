import { useState } from 'react';
import Loading from '../components/Loading';
import AdminToDoItem from './AdminToDoItem';
import AdminToDoNew from './AdminToDoNew';

const AdminToDoList = ({ toDo, loading }) => {
    const [hideCompleted, setHideCompleted] = useState(false);
    const [showNewTask, setShowNewTask] = useState(false);

    const handleHideCompleted = () => {
        setHideCompleted(!hideCompleted);
    }
    
    const handleShowNewTask = () => {
        setShowNewTask(!showNewTask);
    }
    return (
        <div className="w-full mt-3">
           {loading && (
                <div className='pb-3'>
                    <Loading bg='light' />
                </div>
            )}
            {!loading && (
                <div>
                    {toDo.filter(filterItem => (!filterItem.complete)).map(item => (
                        <AdminToDoItem key={item.id} item={item} />
                    ))}
                    {!hideCompleted && (
                        <>
                            {toDo.filter(filterItem => (filterItem.complete)).map(item => (
                                <AdminToDoItem key={item.id} item={item} />
                            ))}
                        </> 
                    )}
                </div>
            )}
            <div className='flex gap-3 mt-6 mb-3 flex-wrap'>
                {toDo.filter(filterItem => (filterItem.complete)).length > 0 && (
                    <h1 className='rounded-lg bg-white g-shadow text-center p-3 cursor-pointer bg-[rgba(0,0,0,0.05)] grow shrink basis-[200px]' onClick={handleHideCompleted}>{hideCompleted ? 'Show Completed Tasks' : 'Hide Completed Tasks'}</h1>
                )}
                <h1 className='rounded-lg g-shadow text-center p-3 cursor-pointer bg-blue-400 !text-white grow shrink basis-[200px]' onClick={handleShowNewTask}>{hideCompleted ? 'Add Task' : 'Add Task'}</h1>
            </div>
            {showNewTask && <AdminToDoNew showNewTask={showNewTask} setShowNewTask={setShowNewTask} />}
        </div>
    );
}
 
export default AdminToDoList;
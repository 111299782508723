import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faCheck } from '@fortawesome/free-solid-svg-icons';
import { useState, useRef } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { validateEmail } from '../utils/validation';

const AdminUser = () => {
    const { currentUser, updateName, updateUserEmail, updateUserPass } = useAuth();

    const [ nameLoading, setNameLoading ] = useState(false);
    const [ nameSuccess, setNameSuccess ] = useState(false);
    const [ emailLoading, setEmailLoading ] = useState(false);
    const [ emailSuccess, setEmailSuccess ] = useState(false);
    const [ passLoading, setPassLoading ] = useState(false);
    const [ passSuccess, setPassSuccess ] = useState(false);

    const nameRef = useRef();
    const emailRef = useRef();
    const pass1Ref = useRef();
    const pass2Ref = useRef();

    // Change Display Name
    const handleDisplayNameSubmit = async (e) => {
        e.preventDefault();
        if (nameLoading === true) {
            return false;
        }
        // Validate Name
        if (nameRef.current.value === '') {
            return false;
        }
        if (nameRef.current.value === currentUser.displayName) {
            return false;
        }
        // Process name change
        setNameLoading(true);
        try {
            await updateName(nameRef.current.value)
            .then(() => {
                setNameSuccess(true);
                setTimeout(() => {
                    setNameSuccess(false);
                }, 1500);
            }) 
        } catch (err) {

        }
        setNameLoading(false);
    }
    // Change email address
    const handleEmailSubmit = async (e) => {
        e.preventDefault();
        if (emailLoading === true) {
            return false;
        }
        // Validate Email
        if (emailRef.current.value === '') {
            return false;
        }
        if (emailRef.current.value === currentUser.email) {
            return false;
        }
        if (!validateEmail(emailRef.current.value)) {
            return false;
        }
        // Process name change
        setEmailLoading(true);
        try {
            await updateUserEmail(emailRef.current.value)
            .then(() => {
                setEmailSuccess(true);
                setTimeout(() => {
                    setEmailSuccess(false);
                }, 1500);
            });
        } catch (err) {
            console.log(err);
        }
        setEmailLoading(false);
    }
    // Change password
    const handlePassSubmit = async (e) => {
        e.preventDefault();
        if (passLoading === true) {
            return false;
        }
        // Validate Passwords
        if (pass1Ref.current.value.length < 6 || pass2Ref.current.value.length < 6) {
            return false;
        }
        if (pass1Ref.current.value !== pass2Ref.current.value) {
            return false;
        }
        // Process name change
        setPassLoading(true);
        try {
            await updateUserPass(pass1Ref.current.value);
            setPassLoading(false);
            setPassSuccess(true);
            setTimeout(() => {
                setPassSuccess(false);
            }, 1500);
        } catch (err) {
            setPassLoading(false);
            console.log(err);
        }
    }

    return (
        <div className='flex gap-3 flex-wrap'>
            <div className='g-shadow bg-white rounded-lg p-6 basis-full'>
                <h3 className='mb-3'>Full Name</h3>
                <form className='flex gap-3 flex-wrap justify-end' action='' autoComplete='off' onSubmit={handleDisplayNameSubmit}>
                    <input autoComplete='false' ref={nameRef} className='admin-input g-shadow' placeholder='Full Name' type='text' defaultValue={currentUser.displayName} />
                    {!nameLoading && !nameSuccess && (
                        <button className='basis-[80px]'>Update</button>
                    )}
                    {nameLoading && (<span className='basis-[80px] flex items-center justify-center'><FontAwesomeIcon className=' text-[20px] animate-spin' icon={faSpinner}/></span>)}
                    {nameSuccess && (<span className='basis-[80px] flex items-center justify-center'><FontAwesomeIcon className=' !text-green-600 text-[20px]' icon={faCheck}/></span>)}
                </form>
            </div>
            <div className='g-shadow bg-white rounded-lg p-6 basis-full'>
                <h3 className='mb-3'>Email Address</h3>
                <form className='flex gap-3 flex-wrap justify-end' action='' autoComplete='off' onSubmit={handleEmailSubmit}>
                    <input autoComplete='false' ref={emailRef} className='admin-input g-shadow' placeholder='Email Address' type='email' defaultValue={currentUser.email} />
                    {!emailLoading && !emailSuccess && (
                        <button className='basis-[80px]'>Update</button>
                    )}
                    {emailLoading && (<span className='basis-[80px] flex items-center justify-center'><FontAwesomeIcon className=' text-[20px] animate-spin' icon={faSpinner}/></span>)}
                    {emailSuccess && (<span className='basis-[80px] flex items-center justify-center'><FontAwesomeIcon className=' !text-green-600 text-[20px]' icon={faCheck}/></span>)}
                </form>
            </div>
            <div className='g-shadow bg-white rounded-lg p-6 basis-full'>
                <h3 className='mb-3'>Password</h3>
                <form className='flex gap-3 flex-wrap justify-end' action='' autoComplete='off' onSubmit={handlePassSubmit}>
                <input autoComplete='new-password' ref={pass1Ref} className='admin-input g-shadow' placeholder='New Password' type='password' />
                <input autoComplete='new-password' ref={pass2Ref} className='admin-input g-shadow' placeholder='Confirm Password' type='password' />
                    {!passLoading && !passSuccess && (<button className='basis-[80px]'>Update</button>)}
                    {passLoading && (<span className='basis-[80px] flex items-center justify-center'><FontAwesomeIcon className=' text-[20px] animate-spin' icon={faSpinner}/></span>)}
                    {passSuccess && (<span className='basis-[80px] flex items-center justify-center'><FontAwesomeIcon className=' !text-green-600 text-[20px]' icon={faCheck}/></span>)}
                </form>
            </div>
        </div>
    );
}
 
export default AdminUser;
import Language from "./Language";
import TopNav from "./TopNav";
import SandwichMenu from "./SandwichMenu";
import Logo from "./Logo";
import { motion, AnimatePresence } from "framer-motion";

const variants = {
    hidden: {
        y:0,
        height:0,
        marginBottom:0
    },
    visible: {
        y:0,
        height:'auto',
        marginBottom:15
    },
    exit:{
        y:-100,
        height:0,
        marginBottom:0
    }
};

const TopBar = ({ setNavOpen, searchActive }) => {

    return (
        <div className="header-container">
            <AnimatePresence>
                {!searchActive && (
                    <motion.div
                        variants={variants}
                        initial="hidden"
                        animate="visible"
                        exit="exit"
                    >
                        <Logo />
                        <div className="header-right">
                            <TopNav />
                            <Language />
                            <SandwichMenu setNavOpen={setNavOpen} />
                        </div>
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    );
}
 
export default TopBar;
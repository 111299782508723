import { useContext } from 'react';
import { AppContext } from '../../contexts/AppContext';

const SearchBar = ({ searchActive, searchValue, startSearch, resetSearch, handleEnter }) => {
    const { language } = useContext(AppContext);
    const searchPlaceholder = ['пошук...', 'recherche...', 'Search...'];

    return (
        <span className="search-bar-container">
            <input className="search-input" onChange={startSearch} onKeyDown={handleEnter} /> 
            {/* <span className="block w-full h-[2px] bg-lightColor opacity-75 search-border"></span>  */}
            <div className='search-bar-placeholder' >
                <svg focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 26"><path className="fill-lightColor" d="M15.5 14h-.79l-.28-.27A6.471 6.471 0 0 0 16 9.5 6.5 6.5 0 1 0 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"></path></svg>
                <span 
                    className={`${searchValue !== '' && searchValue !== null ? 'hidden' : 'block'}`}
                >{searchPlaceholder[language]}</span> 
            </div>
            <div className={`${searchActive ? 'block' : 'hidden'} search-bar-close`} onClick={resetSearch}>
                <span><span></span></span>
            </div>
        </span>
    );
}
 
export default SearchBar;